import "./StrongerCommunity.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";
import logo2 from "@/assets/logo2.png";
import logo1 from "@/assets/logo1.png";
import logo3 from "@/assets/logo3.png";
import logo4 from "@/assets/logo4.png";
import logo5 from "@/assets/logo5.png";
import logo6 from "@/assets/logo6.png";
import imageLayer from "@/assets/background-image.jpg";

const StrongerCommunity = ({ logosOnly }) => {
  const logos = [
    { name: "Saltos Athletics", image: logo2 },
    { name: "Perth/Lanark Blue Wings", image: logo1 },
    { name: "Perth Blue Wings", image: logo3 },
    {
      name: "Perth Blue Wings Junior B Team - Nathan Green",
      image: logo3,
    },
    { name: "Perth Fair", image: logo4 },
    { name: "Smiths Falls Senior A Rideaus Hockey Club", image: logo5 },
    {
      name: "Annual Employee Golf Day - Carleton Refrigeration Annual Golf Day",
      image: logo6,
    },
  ];

  return (
    <div className='stronger-community-wrapper'>
      <div className='top-header'>
        <p>Sponsorship & Community Involvement</p>
        <h1>Supporting Local Teams and Events for a Stronger Community</h1>
      </div>
      <div className='logo-wrapper-mobile'>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={10}
          autoplay={{
            delay: 0,
            disableOnInteraction: true,
          }}
          freeMode={true}
          loop={true}
          speed={3000}
          modules={[Autoplay, FreeMode]}
          className={`mySwiper`}>
          {logos.map((logo, index) => (
            <SwiperSlide key={index}>
              <div className='logo'>
                <img src={logo.image} alt='logo' />
                <p>{logo.name}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {!logosOnly && (
        <div className='image-part'>
          <img src={imageLayer} alt='team' />
          <div className='text-part'>
            <h3>Annual Employee Golf Day</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default StrongerCommunity;
