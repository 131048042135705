import React from "react";
import "./Home.scss";
import Banner from "../../components/Home/Banner";
import ExploreShop from "../../components/Home/ExploreShop";
import Expertise from "../../components/Home/Expertise";
import Renovating from "../../components/Home/Renovating";
import Pumps from "../../components/Home/Pumps";
import OurPartners from "../../components/Home/OurPartners";
import BestProducts from "../../components/Home/BestProducts";
import Discover from "../../components/Home/Discover";
import Services from "../../components/Home/Services";
import FeaturedProducts from "../../components/Shop/FeaturedProducts";
import {
  // BestDealsShop,
  // BestSellerShop,
  // cartProducts,
  CustomLoaderType,
  // FeaturedProductsShop,
} from "../../utils/constants";
import WhyValley from "../../components/Home/WhyValley";
import StrongerCommunity from "../../components/Home/StrongerCommunity";
import DedicatedToQuality from "../../components/Home/DedicatedToQuality";
import BestDeals from "../../components/Shop/BestDeals";
import ReachOut from "../../components/Home/ReachOut";
import SatisfiedClients from "../../components/Home/SatisfiedClients";
import WeeklyUpdate from "../../components/Home/WeeklyUpdate";
import Map from "../../components/Home/Map";
import { Helmet } from "react-helmet";
import { InView } from "react-intersection-observer";
import CustomLoader from "../../components/Shared/CustomLoader/CustomLoader";
import { useGetAllProductsQuery } from "../../utils/API/features/productsApi";

const Home = () => {
  const domain = "https://www.valleywatertreatment.ca/";

  const [isInView, setIsInView] = React.useState([]);
  function handleInView(SectionName, InView) {
    if (InView) {
      setIsInView((isInView) => [...isInView, SectionName]);
    }
  }

  // const {
  // 	data: { data: FeaturedProductsShop = [] } = {},
  // 	isLoading,
  // 	isFetching,
  // } = useGetAllProductsQuery({
  // 	sort: 'popularity',
  // });

  const {
    data: { data: FeaturedProductsShop = [] } = {},
    isLoading,
    isFetching,
  } = useGetAllProductsQuery({
    sort: "popularity",
  });

  // console.log(FeaturedProductsShop);

  return (
    <div className='home-wrapper'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Valley Plumbing Heating and Water Treatment - Since 1987</title>
        <meta
          name='description'
          content='Expert emergency to annual service services in plumbing, HVAC, and water treatment. Shop premium products in our online store. Financing is available. '
        />
        <link rel='canonical' href={`${domain}/`} />{" "}
      </Helmet>
      <Banner />
      <InView
        as='div'
        style={{ width: "-webkit-fill-available" }}
        onChange={(inView) => handleInView("Exploar", inView)}
        triggerOnce>
        <ExploreShop />
      </InView>
      {isInView.includes("Exploar") && (
        <>
          <Expertise />
          <Renovating />
        </>
      )}
      <InView
        as='div'
        style={{ width: "-webkit-fill-available" }}
        onChange={(inView) => handleInView("Map", inView)}
        triggerOnce>
        <Map />
      </InView>
      {isInView.includes("Map") && (
        <>
          <Pumps />
          <OurPartners />
          <BestProducts />
          <Discover />
        </>
      )}
      <InView
        as='div'
        style={{ width: "-webkit-fill-available" }}
        onChange={(inView) => handleInView("Services", inView)}
        triggerOnce>
        <Services />
      </InView>
      {isInView.includes("Services") && (
        <>
          {isLoading || isFetching ? (
            <CustomLoader />
          ) : (
            <FeaturedProducts
              products={FeaturedProductsShop}
              heading={"Featured Products"}
            />
          )}
          <WhyValley />
          <StrongerCommunity />
          <DedicatedToQuality />
          <SatisfiedClients />
          <BestDeals />
          <ReachOut />
          <WeeklyUpdate />
        </>
      )}
    </div>
  );
};

export default Home;
