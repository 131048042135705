import plumbingBanner from "@/assets/plumbingBanner.png";
import plumbingBannerMobile from "@/assets/plumingBannerMobile.png";
import plumbBox1 from "@/assets/plumMenu1.png";
import plumbBox2 from "@/assets/plumServ2.png";
import plumbBox3 from "@/assets/plumServ3.png";
import plumbBox4 from "@/assets/plumServ4.png";
import HeatingBanner from "@/assets/heatingMenuBanner.png";
import heatingBannerMobile from "@/assets/HeatingBannerMobile.png";
import HeatingBox1 from "@/assets/heatingMenuBox1.png";
import HeatingBox2 from "@/assets/heatingMenuBox2.png";
import WaterBanner from "@/assets/waterMenuBanner.png";
import waterBannerMobile from "@/assets/waterBannerMobile.png";
import WaterBox1 from "@/assets/waterMenuBox1.png";
import WaterBox2 from "@/assets/waterMenuBox2.png";
import WaterBox3 from "@/assets/waterMenuBox3.png";
import AreasBox1 from "@/assets/areaMenuBox1.png";
import AreasBox2 from "@/assets/areaMenuBox2.png";
import AreasBox3 from "@/assets/areaMenuBox3.png";
import AreasBox4 from "@/assets/sharbotMainImg.png";
import AreasBox22 from "@/assets/AreasBox22.svg";
export const ServiceMenuData = [
  {
    id: 1,
    name: "Plumbing",
    CTA: {
      img: plumbingBanner,
      imgMob: plumbingBannerMobile,
      title: "Plumbing Quote",
      body: "Get fast quotes for residential and commercial plumbing services.",
      link: "/services/plumping",
      bttnTxt: "View Plumbing Quotations",
    },
    innerServices: [
      {
        id: 1,
        name: "Plumbing",
        img: plumbBox1,
        link: "/plumping",
      },
      {
        id: 2,
        name: "Sump Pump",
        img: plumbBox2,
        link: "/sumppump",
      },
      // {
      //   id: 3,
      //   name: 'Water Heaters',
      //   img: plumbBox3,
      //   link: '/services/heating',
      // },
      {
        id: 4,
        name: "Fixtures",
        img: plumbBox4,
        link: "/fixtures",
      },
    ],
  },
  {
    id: 2,
    name: "Heating",
    CTA: {
      img: HeatingBanner,
      imgMob: heatingBannerMobile,
      title: "Heating Quote",
      body: "Explore expert heating and air conditioning services.",
      link: "/services/heating-quotations",
      bttnTxt: "View Heaing Quotations",
    },
    innerServices: [
      {
        id: 1,
        name: "Heating",
        img: HeatingBox1,
        link: "/services/heating",
      },
      {
        id: 2,
        name: "Air Conditioners",
        img: HeatingBox2,
        link: "/air-condition",
      },
    ],
  },
  {
    id: 3,
    name: "Water Treatment",
    CTA: {
      img: WaterBanner,
      imgMob: waterBannerMobile,
      title: "Water Treatment Quote",
      body: "Water treatment services for homes and businesses.",
      link: "/services/water-quotations",
      bttnTxt: "View Water Treatment Quotations",
    },
    innerServices: [
      {
        id: 1,
        name: "Water Treatments",
        img: WaterBox1,
        link: "/services/water-treatment",
      },
      {
        id: 2,
        name: "Water Purification",
        img: WaterBox2,
        link: "/services/water-purifcation",
      },
      {
        id: 3,
        name: "Water Softener",
        img: WaterBox3,
        link: "/services/water-softner",
      },
    ],
  },
  {
    id: 4,
    name: "Serving Areas",
    imgMob: plumbingBannerMobile,
    CTA: null,
    innerServices: [
      {
        id: 1,
        name: "Lanark County",
        img: AreasBox1,
        link: "/Lanark-County",
      },
      {
        id: 2,
        name: "Ottawa Valley",
        img: AreasBox22,
        link: "/ottowa",
      },
      {
        id: 3,
        name: "Frontenac County",
        img: AreasBox3,
        link: "/Frontenac-County",
      },
      {
        id: 4,
        name: "Leeds & Grenville",
        img: AreasBox4,
        link: "/Leeds&Grenville",
      },
    ],
  },
];
