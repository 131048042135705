import { Divider } from "@mui/material";
import "./PaidProducts.scss";
import PropTypes from "prop-types";
import { fallbackImage } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const CartPaidProducts = ({ cartProduct: { product, quantity, price } }) => {
  const navigate = useNavigate();

  const selectedVariant = product.productVariants.find(
    (v) => v._id === product.productVariantId
  );

  const variantAttributes = Object.entries(selectedVariant?.attribute ?? {});
  return (
    <div
      className='cart-product product-wrapper'
      onClick={() => navigate(`/product/${product._id}`)}>
      <img
        src={product.productMedia?.at(0)?.file ?? fallbackImage}
        alt='product'
      />
      <div className='product-cart-info right-side'>
        <h2>{product.name}</h2>
        <div className='color-price-wrapper options'>
          <div className='color-option'>
            <div className='opt' style={{ textTransform: "capitalize" }}>
              {variantAttributes.map(([name, value]) => (
                <>
                  <p className='p-p'>{name}:</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}>
                    {/* <img src={product.image} alt="product" /> */}
                    <p className='opt-p'>{value}</p>
                  </div>
                </>
              ))}
            </div>
            {/* <div className="opt">
							<p className="p-p">Option:</p>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '8px',
								}}
							>
								<img src={product.image} alt="product" />
								<p className="opt-p">{product.option}</p>
							</div>
						</div> */}
          </div>
          <div className='price-wrapper'>
            <p>
              ${product.maxPrice?.toFixed(2) ?? 0} x {quantity}
            </p>
            {product.isDiscounted &&
              product.finalPrice !== product.priceBeforeDiscount && (
                <p className='originalPrice'>
                  ${product.priceBeforeDiscount?.toFixed(2) ?? 0}
                </p>
              )}
          </div>
        </div>
      </div>
      <div className='quantity-total-price-wrapper'>
        <div className='quantity-wrapper'>
          <p>Quantity</p>
          <div className='quantity-box'>
            <p>{quantity}</p>
          </div>
        </div>
        <div className='discount-total-price-wrapper'>
          {product.isDiscounted &&
            product.finalPrice !== product.priceBeforeDiscount && (
              <div className='discount'>
                <p>Discount</p>
                <p>${product.discountAmount?.toFixed(2) ?? 0}</p>
              </div>
            )}
          <Divider sx={{ width: "100%" }} />
          <div className='total-price'>
            <p>Total Price:</p>
            <p className='price'>${price?.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

CartPaidProducts.propTypes = {
  cartProduct: PropTypes.shape({
    product: PropTypes.object,
    quantity: PropTypes.number,
    price: PropTypes.number,
  }),
};

export default CartPaidProducts;
