import React from 'react';
import './ShopCategories.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
	FreeMode,
	// Navigation,
	// Pagination,
} from 'swiper/modules';
// import SwiperNavButton from './SwiperNavButton';
// import SliderCart from './Home/SliderCart';
import { Container, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import {
	CustomLoaderType,
	fallbackImage,
} from '../utils/constants';
import CustomLoader from '../components/Shared/CustomLoader/CustomLoader';
import { useGetCategoriesQuery } from '../utils/API/features/categoryApi';

const ShopCategories = () => {
	const navigate = useNavigate();
	const matches = useMediaQuery('(min-width:600px');

	const {
		data: products = [],
		isLoading,
		isFetching,
	} = useGetCategoriesQuery();

	// made to validate if the swipper is inside the shop by subCategory because it has different look
	const { subcategory } = useParams();

	if (isLoading || isFetching)
		return <CustomLoader type={CustomLoaderType.SMALL} />;

	return (
		<>
			<div className="product-part">
				{products.map((product, outerIndex) => (
					<div
						key={`A-${outerIndex}`}
						className="inside-product-type"
						onClick={() => navigate(`/shop/${product._id}`)}
					>
						<p>{product.name}</p>
						<div className="product">
							{product.categorySubCategories.length > 0 ? (
								product.categorySubCategories.map(
									(innerProduct, innerIndex) => (
										<div
											className="inside-product"
											key={`B-${outerIndex}-${innerIndex}`}
											onClick={(e) => {
												e.stopPropagation();
												navigate(
													`/shop/${product._id}/${innerProduct._id}`
												);
											}}
										>
											<motion.img
												initial={{ opacity: 0, y: 50 }}
												whileInView={{ opacity: 1, y: 0 }}
												viewport={{
													once: true,
													amount: 0.8,
												}}
												transition={{
													ease: 'easeOut',
													duration: 0.3,
												}}
												src={
													innerProduct.image ??
													fallbackImage
												}
												alt={innerProduct.name}
											/>
											<motion.p
												initial={{ opacity: 0 }}
												whileInView={{ opacity: 1 }}
												viewport={{
													once: true,
													amount: 0.8,
												}}
												transition={{
													ease: 'easeOut',
													duration: 0.3,
													delay: 0.3,
												}}
											>
												{innerProduct.name}
											</motion.p>
										</div>
									)
								)
							) : (
								<div
									className="inside-product"
									key={`B-${outerIndex}`}
									onClick={() =>
										navigate(`/shop/${product._id}`)
									}
								>
									<motion.img
										initial={{ opacity: 0, y: 50 }}
										whileInView={{ opacity: 1, y: 0 }}
										viewport={{ once: true, amount: 0.8 }}
										transition={{
											ease: 'easeOut',
											duration: 0.3,
										}}
										src={product.image ?? fallbackImage}
										alt={product.name}
									/>
									<motion.p
										initial={{ opacity: 0 }}
										whileInView={{ opacity: 1 }}
										viewport={{ once: true, amount: 0.8 }}
										transition={{
											ease: 'easeOut',
											duration: 0.3,
											delay: 0.3,
										}}
									>
										{product.name}
									</motion.p>
								</div>
							)}
						</div>
					</div>
				))}

				<Container
					sx={{
						display: { xs: 'flex', md: 'none' },
						paddingLeft: matches
							? '0px'
							: '20px !important',
						paddingRight: '0px !important',
					}}
				>
					<div className="products-slider">
						<div className="mobile-wrapper-categories">
							{products.map((product, outerindex) => (
								<React.Fragment key={outerindex}>
									<Swiper
										slidesPerView={matches ? 3.5 : 2.1}
										spaceBetween={subcategory ? 0 : 16}
										modules={[FreeMode]}
										className={`mySwiper`}
										style={{
											paddingTop: '40px',
											width: '100%',
										}}
										freeMode={true}
										speed={800}
									>
										<div className="top-section">
											<div className="top-buttons">
												<div className="header">
													<h1>{product.name}</h1>
												</div>
											</div>
										</div>
										{product.categorySubCategories.length >
										0 ? (
											product.categorySubCategories.map(
												(prod, index) =>
													!subcategory ? (
														<SwiperSlide key={index}>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'flex-start',
																	flexDirection: 'column',
																	gap: '24px',
																	width: '100%',
																}}
															>
																<div
																	className={`inside-product mobile ${
																		!subcategory
																			? 'home'
																			: ''
																	}`}
																	onClick={() =>
																		navigate(
																			`/shop/${product._id}/${prod._id}`
																		)
																	}
																>
																	<img
																		src={
																			prod.image ??
																			fallbackImage
																		}
																		alt={prod.name}
																	/>
																	<p>{prod.name}</p>
																</div>
															</div>
														</SwiperSlide>
													) : (
														index % 2 !== 0 && (
															<SwiperSlide>
																<div
																	className="CategoriesColumn"
																	key={index}
																>
																	<div
																		style={{
																			display: 'flex',
																			alignItems:
																				'flex-start',
																			flexDirection:
																				'column',
																			gap: '24px',
																			width: '100%',
																		}}
																	>
																		<div
																			className="inside-product mobile"
																			onClick={() =>
																				navigate(
																					`/shop/${product._id}/${prod._id}`
																				)
																			}
																		>
																			<img
																				src={
																					prod.image ??
																					fallbackImage
																				}
																				alt={prod.name}
																			/>
																			<p>{prod.name}</p>
																		</div>
																	</div>
																	<div
																		style={{
																			display: 'flex',
																			alignItems:
																				'flex-start',
																			flexDirection:
																				'column',
																			gap: '24px',
																			width: '100%',
																		}}
																	>
																		{product
																			.categorySubCategories[
																			index + 1
																		] && (
																			<div
																				className="inside-product mobile"
																				onClick={() =>
																					navigate(
																						`/shop/${
																							product
																								.categorySubCategories[
																								index + 1
																							]._id
																						}`
																					)
																				}
																			>
																				<img
																					src={
																						product
																							.categorySubCategories[
																							index + 1
																						].image ??
																						fallbackImage
																					}
																					alt={
																						product
																							.categorySubCategories[
																							index + 1
																						].name
																					}
																				/>
																				<p>
																					{
																						product
																							.categorySubCategories[
																							index + 1
																						].name
																					}
																				</p>
																			</div>
																		)}
																	</div>
																</div>
															</SwiperSlide>
														)
													)
											)
										) : (
											<SwiperSlide>
												<div
													style={{
														display: 'flex',
														alignItems: 'flex-start',
														flexDirection: 'column',
														gap: '24px',
														width: '100%',
													}}
												>
													<div
														className={`inside-product mobile ${
															!subcategory ? 'home' : ''
														}`}
														onClick={() =>
															navigate(
																`/shop/${product._id}`
															)
														}
													>
														<img
															src={
																product.image ??
																fallbackImage
															}
															alt={product.name}
														/>
														<p>{product.name}</p>
													</div>
												</div>
											</SwiperSlide>
										)}
									</Swiper>
									{!subcategory && (
										<button
											className="white-button2"
											onClick={() =>
												navigate(`/shop/${product._id}`)
											}
										>
											All Categories
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="17"
												height="16"
												viewBox="0 0 17 16"
												fill="none"
											>
												<path
													d="M6.5 12L10.5 8L6.5 4"
													stroke="black"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
									)}
								</React.Fragment>
							))}
						</div>
					</div>
				</Container>
			</div>
		</>
	);
};

export default ShopCategories;
