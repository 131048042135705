import {
	createApi,
	fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		baseUrl:
			import.meta.env.VITE_SERVER_API ??
			'https://api.toolswift.ca/',
		headers: {
			'x-store-key':
				import.meta.env.VITE_STORE_KEY ??
				'666812e44a4286b1141da63f',
		},
	}),
	endpoints: (builder) => ({}),
});
