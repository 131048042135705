import { useEffect, useState } from 'react';
import './BestDeals.scss';
import { Container, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import SwiperNavButton from '../SwiperNavButton';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ProductOnSwiper from '../ProductOnSwiper';
import { Pagination } from 'swiper/modules';
import PaginationLines from '../Home/PaginationLines';
import {
	useGetAllCategoryProductsQuery,
	useGetAllProductsQuery,
	useGetAllSubCategoryProductsQuery,
} from '../../utils/API/features/productsApi';
import CustomLoader from '../Shared/CustomLoader/CustomLoader';
import PropTypes from 'prop-types';

const BestDeals = ({ categoryId, subCategoryId, name }) => {
	const [query, setQuery] = useState({
		limit: 20,
	});

	const {
		data: { data: allProducts = [] } = {},
		isLoading: allLoading,
		isFetching: allFetching,
	} = useGetAllProductsQuery(query, {
		skip: categoryId || subCategoryId,
	});
	const {
		data: { data: catProducts = [] } = {},
		isLoading: catLoading,
		isFetching: catFetching,
	} = useGetAllCategoryProductsQuery(
		{ searchQuery: query, categoryId },
		{ skip: subCategoryId || !categoryId }
	);
	const {
		data: { data: subProducts = [] } = {},
		isLoading: subLoading,
		isFetching: subFetching,
	} = useGetAllSubCategoryProductsQuery(
		{ searchQuery: query, subCategoryId },
		{ skip: !subCategoryId }
	);

	const products = subCategoryId
		? subProducts
		: categoryId
		? catProducts
		: allProducts;
	const isLoading = subCategoryId
		? subLoading
		: categoryId
		? catLoading
		: allLoading;
	const isFetching = subCategoryId
		? subFetching
		: categoryId
		? catFetching
		: allFetching;

	const matches = useMediaQuery('(max-width:1200px)');
	const isMobile = useMediaQuery('(max-width:800px)');
	const [swiper, setSwiper] = useState(0);
	const links = ['On Sale', 'Best Selling'];
	const [selectedLink, setSelectedLink] =
		useState('On Sale');
	useEffect(() => {
		if (selectedLink === 'On Sale') {
			setQuery({ ...query, sort: 'discount' });
		} else {
			setQuery({ ...query, sort: 'popularity' });
		}
	}, [selectedLink]);

	//   const lines = [1, 2, 3, 4, 5];

	const chunkArray = (array, chunkSize) => {
		const chunks = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunks.push(array.slice(i, i + chunkSize));
		}
		return chunks;
	};

	const productChunks = chunkArray(
		products,
		isMobile ? 2 : 3
	);

	return (
		<Container>
			<div className="best-deals-wrapper">
				<div className="top-part">
					<p className="blue">Shop</p>
					<h1>Best Deals {name ? `in ${name}` : ''}</h1>
				</div>
				<div className="products-slider">
					<Swiper
						slidesPerView={'auto'}
						spaceBetween={24}
						modules={[Navigation, FreeMode, Pagination]}
						navigation={true}
						className={`mySwiper`}
						freeMode={true}
						onSlideChange={(swiper) =>
							setSwiper(swiper.activeIndex)
						}
						speed={800}
					>
						<div className="top-section">
							<div className="top-buttons">
								<div className="links">
									{links.map((link, index) => (
										<p
											className={
												selectedLink === link
													? 'selected'
													: ''
											}
											onClick={() => setSelectedLink(link)}
											key={index}
										>
											{link}
										</p>
									))}
								</div>
							</div>
							{!matches && (
								<div className="nav-buttons">
									<SwiperNavButton />
								</div>
							)}
						</div>
						{isLoading || isFetching ? (
							<CustomLoader />
						) : (
							productChunks.map((chunk, chunkIndex) => (
								<SwiperSlide key={chunkIndex}>
									<div
										style={{
											display: 'flex',
											alignItems: 'flex-start',
											flexDirection: 'column',
											gap: '24px',
										}}
										className="bestDealProductColumn"
									>
										{chunk.map((prd, prdIndex) => (
											<ProductOnSwiper
												data={prd}
												key={prdIndex}
											/>
										))}
									</div>
								</SwiperSlide>
							))
						)}
						{/* {matches && (
              <div className='lines'>
                <PaginationLines lines={lines} selectedLine={swiper} />
              </div>
            )} */}
						{matches && (
							<div className="nav-buttons">
								<SwiperNavButton />
							</div>
						)}
					</Swiper>
				</div>
			</div>
		</Container>
	);
};

BestDeals.propTypes = {
	categoryId: PropTypes.string,
	subCategoryId: PropTypes.string,
	name: PropTypes.string,
};

export default BestDeals;
