import React from "react";
import "./Expertise.scss";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import image28 from "../../assets/image 28.webp";
import image281 from "../../assets/image 28 (1).webp";
import image282 from "../../assets/image 28 (2).png";
import Frameannual from "../../assets/Frameannual.webp";

const Expertise = () => {
  const navigate = useNavigate();

  const expertiseCategories = [
    {
      name: "Plumbing",
      img: image28,
      path: "/plumping",
    },
    {
      name: "Heating",
      img: image281,
      path: "/services/heating",
    },
    {
      name: "Water Treatment",
      img: image282,
      path: "/services/water-treatment",
    },
    {
      name: "Emergency",
      img: image28, // Assuming you want to reuse image 28.png for Emergency
      path: `href="tel:+6132675206"`, // Note: this path should likely be updated if it's supposed to navigate or trigger a phone call
    },
    {
      name: "Annual Service",
      img: Frameannual,
      path: "/schedule-annual-service",
    },
  ];

  const handleClick = (category) => {
    // Check if the category is the one that should initiate a phone call
    if (category.name === "Emergency") {
      // Initiate phone call
      window.location.href = "tel:+6132675206";
    } else {
      // Navigate to the specified path
      navigate(category.path);
    }
  };

  return (
    <div className='expertise-wrapper'>
      <Container
        sx={{
          maxWidth: "1352px !important",
          paddingX: { xs: "20px !important", lg: "0px !important" },
        }}>
        <div className='inside-wrapper'>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "14px",
              flexDirection: "column",
            }}>
            <h1>Expertise You Can Count On</h1>
            <p>We deliver service excellence beyond your expectations.</p>
          </div>
          <div className='expertise-categories'>
            {expertiseCategories.map((category, index) => (
              <div
                className='category'
                onClick={() => handleClick(category)}
                key={index}>
                <motion.img
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ ease: "easeOut", duration: 0.3 }}
                  src={category.img}
                  alt={category.name}
                />
                <motion.p
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ ease: "easeOut", duration: 0.3, delay: 0.3 }}>
                  {category.name}
                </motion.p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Expertise;
