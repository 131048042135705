import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedProducts: [],
  wishListProducts: [],
  totalPrice: 0,
  isPopperVisible: false,
  discountsApplied: [],
  totalShippingDiscount: 0,
};

function calculateTotalPrice(selectedProducts) {
  return selectedProducts.reduce((total, item) => total + item.price, 0);
}

const ShoppingCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    /**
     *
     * @param {import('./interfaces').state} state
     * @param {import('@reduxjs/toolkit').PayloadAction<{product:{}, quantity:number}>} action
     */
    addProductToCartAction: (state, action) => {
      const { product, quantity = 1 } = action.payload;
      const existingItemIndex = state.selectedProducts.findIndex(
        (item) =>
          item.product._id === product._id &&
          item.product.productVariantId === product.productVariantId
      );
      let updatedItems = [...state.selectedProducts];

      if (existingItemIndex >= 0) {
        updatedItems[existingItemIndex].quantity = quantity;
        updatedItems[existingItemIndex].price =
          updatedItems[existingItemIndex].quantity *
          parseFloat(product.maxPrice);
        state.totalPrice = calculateTotalPrice(updatedItems);
        state.isPopperVisible = true;
      } else {
        const newItem = {
          product,
          quantity,
          price: quantity * parseFloat(product.maxPrice),
        };
        updatedItems = [...updatedItems, newItem];
        const updatedTotalPrice = calculateTotalPrice(updatedItems);
        return {
          ...state,
          selectedProducts: updatedItems,
          totalPrice: updatedTotalPrice,
          isPopperVisible: true,
        };
      }
    },
    /**
     *
     * @param {import('./interfaces').state} state
     * @param {import('@reduxjs/toolkit').PayloadAction<{products:import('@/app/product/[productId]/page').ProductModel[]}>} action
     */
    batchAddProductsAction: (state, action) => {
      const { products, quantities = [] } = action.payload;

      for (let index = 0; index < products.length; index++) {
        const product = products[index];
        const quantity = quantities[index];
        const existingItemIndex = state.selectedProducts.findIndex(
          (item) =>
            item.product._id === product._id &&
            item.product.productVariantId === product.productVariantId
        );
        if (existingItemIndex >= 0) {
          if (state.selectedProducts[existingItemIndex].quantity !== quantity) {
            state.selectedProducts[existingItemIndex].quantity = quantity;
            state.selectedProducts[existingItemIndex].price =
              quantity * parseFloat(product.maxPrice);
          }
          continue;
        }
        const newItem = {
          product,
          quantity: quantity ?? 1,
          price: (quantity ?? 1) * parseFloat(product.maxPrice),
        };
        state.selectedProducts.push(newItem);
      }
      state.totalPrice = calculateTotalPrice(state.selectedProducts);
    },
    /**
     *
     * @param {import('./interfaces').state} state
     * @param {import('@reduxjs/toolkit').PayloadAction<{product:{}}>} action
     */
    removeProductFromCartAction: (state, action) => {
      const { product } = action.payload;
      const updatedItems = state.selectedProducts.filter(
        (item) =>
          !(
            item.product._id === product._id &&
            item.product.productVariantId === product.productVariantId
          )
      );
      const updatedTotalPrice = calculateTotalPrice(updatedItems);
      return {
        ...state,
        selectedProducts: updatedItems,
        totalPrice: updatedTotalPrice,
        isPopperVisible: false,
      }; // Hide popper when item is removed
    },
    /**
     *
     * @param {import('./interfaces').state} state
     * @param {import('@reduxjs/toolkit').PayloadAction<{product:{}, quantity:number}>} action
     */
    increaseProductQuantityAction: (state, action) => {
      const { product, quantity = 1 } = action.payload;
      const existingItemIndex = state.selectedProducts.findIndex(
        (item) =>
          item.product._id === product._id &&
          item.product.productVariantId === product.productVariantId
      );

      if (existingItemIndex >= 0) {
        // const updatedItems = [...state.selectedProducts];
        // updatedItems[existingItemIndex].quantity +=
        // 	quantity;
        // updatedItems[existingItemIndex].price =
        // 	updatedItems[existingItemIndex].quantity *
        // 	parseFloat(product.maxPrice);
        // const updatedTotalPrice =
        // 	calculateTotalPrice(updatedItems);
        // return {
        // 	...state,
        // 	selectedProducts: updatedItems,
        // 	totalPrice: updatedTotalPrice,
        // };
        const itemToUpdate = state.selectedProducts[existingItemIndex];
        itemToUpdate.quantity += quantity;
        itemToUpdate.price += quantity * parseFloat(product.maxPrice);

        state.totalPrice = calculateTotalPrice(state.selectedProducts);
        // const priceToUpdate = state.totalPrice;
      }

      // return state;
    },
    /**
     *
     * @param {import('./interfaces').state} state
     * @param {import('@reduxjs/toolkit').PayloadAction<{product:{}, quantity:number}>} action
     */
    decreaseProductQuantityAction: (state, action) => {
      const { product, quantity = 1 } = action.payload;
      const existingItemIndex = state.selectedProducts.findIndex(
        (item) =>
          item.product._id === product._id &&
          item.product.productVariantId === product.productVariantId
      );

      if (existingItemIndex >= 0) {
        const itemToUpdate = state.selectedProducts[existingItemIndex];
        if (itemToUpdate.quantity > quantity) {
          itemToUpdate.quantity -= quantity;
          itemToUpdate.price -= quantity * parseFloat(product.maxPrice);
        } else {
          state.selectedProducts.splice(existingItemIndex, 1);
        }
        state.totalPrice = calculateTotalPrice(state.selectedProducts);
      }
    },
    /**
     *
     * @param {import('./interfaces').state} state
     */
    clearCartAction: (state) => {
      return {
        ...state,
        selectedProducts: [],
        totalPrice: 0,
        isPopperVisible: false,
      }; // Hide popper when cart is cleared
    },
    /**
     *
     * @param {import('./interfaces').state} state
     * @param {import('@reduxjs/toolkit').PayloadAction<{product:{}}>} action
     */
    toggleProductInWishlistAction: (state, action) => {
      const { product } = action.payload;
      const isProductInWishlist = state.wishListProducts.some(
        (item) =>
          item._id === product._id &&
          item.productVariantId === product.productVariantId
      );

      const updatedWishlist = isProductInWishlist
        ? state.wishListProducts.filter(
            (item) =>
              !(
                item._id === product._id &&
                item.productVariantId === product.productVariantId
              )
          )
        : [...state.wishListProducts, product];

      return {
        ...state,
        wishListProducts: updatedWishlist,
      };
    },
    /**
     *
     * @param {import('./interfaces').state} state
     * @param {import('@reduxjs/toolkit').PayloadAction<{isVisible:boolean}>} action
     */
    setPopperVisibleAction: (state, action) => {
      return { ...state, isPopperVisible: action.payload }; // Set popper visibility
    },
    addDiscountAction: (state, action) => {
      const discount = action.payload;
      state.discountsApplied.push(discount);
    },
    removeDiscountAction: (state, action) => {
      state.discountsApplied = state.discountsApplied.filter(
        (discount) => discount._id !== action.payload
      );
    },
    clearDiscountsAction: (state) => {
      state.discountsApplied = [];
    },
    clearShippingDiscountsAction: (state) => {
      state.discountsApplied = state.discountsApplied.filter(
        (discount) => discount.discountType !== "SHIPPING"
      );
      state.totalShippingDiscount = 0;
    },
    addToShippingDiscountAction: (state, action) => {
      state.totalShippingDiscount += action.payload;
    },
    removeFromShippingDiscountAction: (state, action) => {
      state.totalShippingDiscount -= action.payload;
      if (state.totalShippingDiscount < 0) {
        state.totalShippingDiscount = 0;
      }
    },
  },
  selectors: {
    selectSelectedProducts: (ShoppingCart) => ShoppingCart.selectedProducts,
    selectTotalPrice: (ShoppingCart) => ShoppingCart.totalPrice,
    selectWishlistProducts: (ShoppingCart) => ShoppingCart.wishListProducts,
    selectIsPopperVisible: (ShoppingCart) => ShoppingCart.isPopperVisible,
    selectDiscountsApplied: (ShoppingCart) => ShoppingCart.discountsApplied,
    selectCartDiscounts: (ShoppingCart) =>
      ShoppingCart.discountsApplied.filter(
        (d) => d.discountType === "CART_BALANCE"
      ),
    selectShippingDiscounts: (ShoppingCart) =>
      ShoppingCart.discountsApplied.filter(
        (d) => d.discountType === "SHIPPING"
      ),

    selectTotalCartDiscount: (ShoppingCart) => {
      let totalDiscount = 0;
      for (const discount of ShoppingCart.discountsApplied) {
        if (discount.discountType !== "SHIPPING") {
          if (discount.decreasePriceBy === "PERCENTAGE") {
            totalDiscount += (ShoppingCart.totalPrice * discount.amount) / 100;
          } else {
            totalDiscount += discount.amount;
          }
        }
      }
      return totalDiscount;
    },
    selectTotalShippingDiscount: (ShoppingCart) =>
      ShoppingCart.totalShippingDiscount,
    selectTotalQuantity: (ShoppingCart) =>
      ShoppingCart.selectedProducts.reduce(
        (total, item) => total + item.quantity,
        0
      ),
  },
});

// Export the generated reducer function
export default ShoppingCartSlice.reducer;

export const {
  selectIsPopperVisible,
  selectTotalPrice,
  selectSelectedProducts,
  selectWishlistProducts,
  selectDiscountsApplied,
  selectCartDiscounts,
  selectShippingDiscounts,
  selectTotalShippingDiscount,
  selectTotalCartDiscount,
  selectTotalQuantity,
} = ShoppingCartSlice.selectors;

export const {
  addProductToCartAction,
  batchAddProductsAction,
  removeProductFromCartAction,
  increaseProductQuantityAction,
  decreaseProductQuantityAction,
  toggleProductInWishlistAction,
  clearCartAction,
  setPopperVisibleAction,
  addDiscountAction,
  removeDiscountAction,
  clearDiscountsAction,
  clearShippingDiscountsAction,
  addToShippingDiscountAction,
  removeFromShippingDiscountAction,
} = ShoppingCartSlice.actions;
