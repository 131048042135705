import { IconButton } from "@mui/material";
// import { CartContext } from "../../../Store/ShoppingCart";
import "./CartItem.scss";
import { BsPlusLg } from "react-icons/bs";
import { LuMinus } from "react-icons/lu";
import { FaStar } from "react-icons/fa6";
import { RiHeart2Fill } from "react-icons/ri";
import {
  decreaseProductQuantityAction,
  increaseProductQuantityAction,
  removeProductFromCartAction,
  selectWishlistProducts,
  toggleProductInWishlistAction,
} from "@/utils/features/cart";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { fallbackImage } from "../../../utils/constants";

function CartProduct({ CartProduct, checkout }) {
  // const {
  //   removeProductFromCart,
  //   increaseProductQuantity,
  //   decreaseProductQuantity,
  //   toggleProductInWishlist,
  //   wishListProducts
  // } = useContext(CartContext);

  const dispatch = useDispatch();

  const wishListProducts = useSelector(selectWishlistProducts);

  const toggleProductInWishlist = (product) =>
    dispatch(toggleProductInWishlistAction({ product }));

  const removeProductFromCart = (product) =>
    dispatch(removeProductFromCartAction({ product }));

  const increaseProductQuantity = (product) =>
    dispatch(increaseProductQuantityAction({ product }));

  const decreaseProductQuantity = (product) =>
    dispatch(decreaseProductQuantityAction({ product }));

  // console.log(CartProduct);

  const handleButtonClick = (e, callback, product) => {
    e.stopPropagation();
    callback(product);
  };

  const { allInventories } = CartProduct.product;

  const currentInventory = allInventories.find(
    (inv) => inv.variant === CartProduct.product.productVariantId
  );

  const maxQuantity = currentInventory.quantity;

  return (
    <div className='CartproductContainer'>
      <div className='topSection'>
        <div className='image'>
          <img
            src={
              CartProduct.product?.productMedia?.at(0)?.file ?? fallbackImage
            }
            alt=''
          />
        </div>
        <div className='productInfo'>
          <div id='Name'>
            <span className='name'>{CartProduct.product.name}</span>
            <span>
              <FaStar className='icon rate' />
              {CartProduct.product.rating}
            </span>
          </div>
          <div id='Price'>
            <span className='main'>
              ${Number(CartProduct.product.maxPrice).toFixed(2)} x{" "}
              {CartProduct.quantity}
            </span>
            {CartProduct.product.priceBeforeDiscount >
              CartProduct.product.maxPrice && (
              <span className='old'>
                ${Number(CartProduct.product.priceBeforeDiscount).toFixed(2)}
              </span>
            )}
          </div>
          {!checkout && (
            <div className='bottomSection'>
              <div className='actionButtons'>
                <div className='addButt'>
                  <IconButton
                    className={`iconHolder ${
                      CartProduct.quantity <= 1 ? "disabled" : ""
                    }`}
                    onClick={(e) =>
                      handleButtonClick(
                        e,
                        decreaseProductQuantity,
                        CartProduct.product
                      )
                    }>
                    <LuMinus className='icon' />
                  </IconButton>
                  <div id='quantity'>{CartProduct.quantity}</div>
                  <IconButton
                    className={`iconHolder ${
                      CartProduct.product.trackQuantity &&
                      CartProduct.quantity >= maxQuantity
                        ? "disabled"
                        : ""
                    }`}
                    onClick={(e) =>
                      handleButtonClick(
                        e,
                        increaseProductQuantity,
                        CartProduct.product
                      )
                    }>
                    <BsPlusLg className='icon' />
                  </IconButton>
                </div>
                <span className='count'>
                  ${Number(CartProduct.price).toFixed(2)}
                </span>
              </div>
              <div className='EditButtons'>
                <IconButton
                  className='icon'
                  onClick={(e) =>
                    handleButtonClick(
                      e,
                      toggleProductInWishlist,
                      CartProduct.product
                    )
                  }>
                  <RiHeart2Fill
                    className={`${
                      wishListProducts.some(
                        (prod) => prod._id === CartProduct.product._id
                      )
                        ? "fillRed"
                        : "grey"
                    }`}
                  />
                </IconButton>
                <IconButton
                  className='icon'
                  onClick={(e) =>
                    handleButtonClick(
                      e,
                      removeProductFromCart,
                      CartProduct.product
                    )
                  }>
                  <img src={"/image/shop/trash.svg"} width={24} height={24} />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CartProduct.propTypes = {
  CartProduct: PropTypes.object.isRequired,
  checkout: PropTypes.bool,
};

export default CartProduct;
