import React from 'react';
import {useSwiper} from "swiper/react";

const SwiperNavButton = ({whiteButtons = false}) => {
    const swiper = useSwiper();
    return (
        <>
            <button className={`${whiteButtons && 'client'}`} onClick={() => swiper.slidePrev()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13" fill="none">
                    <path
                        d="M6.9999 0.772265C7.1999 0.947266 7.2999 1.15977 7.2999 1.40977C7.2999 1.65977 7.1999 1.85977 6.9999 2.00977L3.4749 5.60977H18.6999C19.2499 5.65977 19.5499 5.95977 19.5999 6.50977C19.5499 7.05977 19.2499 7.35977 18.6999 7.40977H3.4749L7.0374 10.9723C7.3874 11.3973 7.3874 11.8223 7.0374 12.2473C6.6124 12.5973 6.1874 12.5973 5.7624 12.2473L0.662402 7.14727C0.312402 6.72227 0.312402 6.29727 0.662402 5.87227L5.7624 0.772265C6.1874 0.422265 6.5999 0.422265 6.9999 0.772265Z"
                        fill="#ADADAD"/>
                </svg>
            </button>
            <button className={`${whiteButtons && 'client'}`} onClick={() => swiper.slideNext()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13" fill="none">
                    <path
                        d="M13.0001 0.772265C12.8001 0.947266 12.7001 1.15977 12.7001 1.40977C12.7001 1.65977 12.8001 1.85977 13.0001 2.00977L16.5251 5.60977H1.3001C0.750097 5.65977 0.450096 5.95977 0.400097 6.50977C0.450096 7.05977 0.750097 7.35977 1.3001 7.40977H16.5251L12.9626 10.9723C12.6126 11.3973 12.6126 11.8223 12.9626 12.2473C13.3876 12.5973 13.8126 12.5973 14.2376 12.2473L19.3376 7.14727C19.6876 6.72227 19.6876 6.29727 19.3376 5.87227L14.2376 0.772265C13.8126 0.422265 13.4001 0.422265 13.0001 0.772265Z"
                        fill="#ADADAD"/>
                </svg>
            </button>
        </>
    );
};

export default SwiperNavButton;