const APPS_SCRIPT_URL =
  "https://script.google.com/macros/s/AKfycbzR5PK_JwNKQlJrkyPk1kVQMa0QIuj8HMbDgxjDvRj8jq2VY4AyU4hYEjEul2ssEaA/exec";
const PLACE_ID = "ChIJidAcsaBx0kwR_DYCjBC6F2A";

export const fetchReviews = async () => {
  try {
    const response = await fetch(`${APPS_SCRIPT_URL}?place_id=${PLACE_ID}`);
    const data = await response.json();

    if (data && data.result && data.result.reviews) {
      return data.result.reviews.map((review) => ({
        name: review.author_name,
        text: review.text,
        rating: review.rating,
      }));
    }

    return [];
  } catch (error) {
    console.error("Error fetching reviews:", error);
    return [];
  }
};
