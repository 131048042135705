import './OrderTotal.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import {
	addDiscountAction,
	removeDiscountAction,
	selectDiscountsApplied,
	selectTotalCartDiscount,
	selectTotalPrice,
	selectTotalQuantity,
} from '../utils/features/cart';
import { selectUserData } from '../utils/features/user';
import { useEffect, useState } from 'react';
import {
	useGetAllStoreDiscountsQuery,
	useValidateDiscountByCodeMutation,
} from '../utils/API/features/discountsApi';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { IoMdTrash } from 'react-icons/io';

const OrderTotal = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const totalPrice = useSelector(selectTotalPrice);

	const userData = useSelector(selectUserData);

	const totalCartDiscount = useSelector(
		selectTotalCartDiscount
	);

	const totalQuantity = useSelector(selectTotalQuantity);

	const discountsApplied = useSelector(
		selectDiscountsApplied
	);

	const [coupon, setCoupon] = useState('');

	const [validateCode] =
		useValidateDiscountByCodeMutation();

	const {
		data: { data: cartDiscounts } = {},
		isLoading: cartDiscountsLoading,
		isError: cartDiscountsError,
	} = useGetAllStoreDiscountsQuery({
		staticDiscountType: 'CART_BALANCE',
	});

	const addCartDiscount = (discount) =>
		dispatch(addDiscountAction(discount));
	const removeDiscount = (discountId) =>
		dispatch(removeDiscountAction(discountId));

	const handleRemoveDiscount = (id) => {
		removeDiscount(id);
	};

	const applyCartDiscount = async () => {
		if (coupon !== '') {
			if (discountsApplied.find((d) => d.code === coupon)) {
				toast('Discount already applied', {
					type: 'info',
				});
				return;
			}
			try {
				const discount = await validateCode({
					code: coupon,
					purchaseAmount: totalPrice,
					purchaseQuantity: totalQuantity,
				}).unwrap();
				addCartDiscount(discount);
				setCoupon('');
				toast('Discount Applied Successfully', {
					type: 'success',
				});
			} catch (error) {
				toast('Cannot Apply Discount Code', {
					type: 'error',
				});
			}
		}
	};

	// Applies Default Cart Discounts
	useEffect(() => {
		if (!cartDiscountsLoading && cartDiscounts) {
			for (const discount of cartDiscounts) {
				const isMinAmount =
					discount.minPurchaseRequired === 'MINIMUM_AMOUNT';
				const isMinQuantity =
					discount.minPurchaseRequired ===
					'MINIMUM_QUANTITY';
				const alreadyExists = discountsApplied.find(
					(d) => d._id === discount._id
				);
				if (
					isMinAmount &&
					totalPrice >= discount.minPurchaseAmount &&
					!alreadyExists
				) {
					addCartDiscount({ ...discount, isAuto: true });
				} else if (
					isMinAmount &&
					totalPrice < discount.minPurchaseAmount &&
					alreadyExists
				) {
					removeDiscount(discount._id);
				}
				if (
					isMinQuantity &&
					totalQuantity >= discount.minPurchaseQuantity &&
					!alreadyExists
				) {
					addCartDiscount({ ...discount, isAuto: true });
				} else if (
					discount.minPurchaseRequired ===
						'MINIMUM_QUANTITY' &&
					totalQuantity < discount.minPurchaseQuantity &&
					alreadyExists
				) {
					removeDiscount(discount._id);
				}
			}
		}
	}, [totalQuantity, totalPrice, cartDiscounts]);

	return (
		<div className="order-total-wrapper">
			<div className="summary-discount-wrapper">
				<h2>Checkout</h2>
				<div className="summary">
					<h2>Summary</h2>
					<div className="row">
						<p>Subtotal</p>
						<p>${totalPrice?.toFixed(2)}</p>
					</div>
					{/* TODO: Connect the Discounts here */}
					{totalCartDiscount > 0 && (
						<div className="row">
							<p>Coupons Discount</p>
							<p>-${totalCartDiscount}</p>
						</div>
					)}
					<Divider sx={{ width: '100%' }} />
					<div
						className="row"
						style={{ textAlign: 'start' }}
					>
						<p>
							Other Costs will be determined after filling
							required info
						</p>
					</div>
				</div>
				<div className="discount">
					<h2>Discount Code</h2>
					<div className="row">
						<input
							value={coupon}
							onChange={(e) => setCoupon(e.target.value)}
							placeholder="Enter the discount code"
						/>
						<button
							onClick={applyCartDiscount}
							className="blue-button"
						>
							Apply
						</button>
					</div>
					{discountsApplied.length > 0 &&
						discountsApplied
							.filter(
								(d) => d.discountType === 'CART_BALANCE'
							)
							.map((discount, index) => (
								<div
									className="discountItemDiv"
									key={index}
								>
									{!discount.isAuto && (
										<IconButton
											className="delete"
											onClick={() =>
												handleRemoveDiscount(discount._id)
											}
										>
											<IoMdTrash />
										</IconButton>
									)}
									<div className="name">
										{discount.discountName}
									</div>
									{discount.code &&
										discount.code !== '' && (
											<div className="code">
												{discount.code}
											</div>
										)}
									<div className="price">
										{discount.amount}{' '}
										{discount.decreasePriceBy ===
										'PERCENTAGE'
											? '%'
											: '$'}
									</div>
								</div>
							))}
				</div>
			</div>
			<div className="total-price">
				<div className="row">
					<h2>Total Price</h2>
					<h2>
						$
						{Math.max(
							totalPrice +
								userData.tax * totalPrice -
								totalCartDiscount,
							0
						).toFixed(2)}
					</h2>
				</div>
				<button
					style={
						true
							? {
									opacity: '0.5',
									pointerEvents: 'none',
							  }
							: {}
					}
					className="blue-button"
					onClick={() => {
						// navigate(
						// 	`${
						// 		location.pathname === '/payment'
						// 			? '/payment-success'
						// 			: '/payment'
						// 	}`
						// )
					}}
				>
					Checkout
				</button>
			</div>
		</div>
	);
};

export default OrderTotal;
