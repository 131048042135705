import { apiSlice } from '../api';

export const apiSliceWithDiscountsEndpoints =
	apiSlice.injectEndpoints({
		endpoints: (builder) => ({
			getAllStoreDiscounts: builder.query({
				query: ({ staticDiscountType }) => ({
					url: `/discounts?staticDiscountType=${staticDiscountType}`,
				}),
			}),

			validateDiscountByCode: builder.mutation({
				query: ({
					code,
					purchaseAmount,
					purchaseQuantity,
				}) => ({
					url: `/discounts/validate?code=${code}`,
					method: 'POST',
					body: {
						purchaseAmount,
						purchaseQuantity,
					},
				}),
			}),
		}),
	});

export const {
	useGetAllStoreDiscountsQuery,
	useValidateDiscountByCodeMutation,
} = apiSliceWithDiscountsEndpoints;
