import { Container } from '@mui/material';
import './ExploreShop.scss';
import ShopCategories from '../ShopCategories';
import { motion } from 'framer-motion';

const ExploreShop = () => {
	return (
		<Container
			sx={{
				maxWidth: '1352px !important',
				padding: '0px !important',
			}}
		>
			<div className="explore-our-shop-wrapper">
				<div className="top-part">
					<div>
						<motion.p
							initial={{ opacity: 0, y: -50 }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true, amount: 0.8 }}
							transition={{
								ease: 'easeOut',
								duration: 0.4,
							}}
							className="blue"
						>
							Categories
						</motion.p>
						<motion.h1
							initial={{ opacity: 0, y: -50 }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true, amount: 0.8 }}
							transition={{
								ease: 'easeOut',
								duration: 0.4,
								delay: 0.2,
							}}
						>
							Explore Our Shop{' '}
						</motion.h1>
					</div>
					<motion.p
						initial={{ opacity: 0, y: 30 }}
						whileInView={{ opacity: 1, y: 0 }}
						viewport={{ once: true, amount: 0.8 }}
						transition={{
							ease: 'easeOut',
							duration: 0.4,
							delay: 0.4,
						}}
					>
						A wide range of premium products for every
						plumbing & heating need!
					</motion.p>
				</div>
				<ShopCategories />
			</div>
		</Container>
	);
};

export default ExploreShop;
