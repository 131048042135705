import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	profileType: 'personal', // Add profileType to the state
	deliveryOption: 'pickup',
	isSameAsShipping: true,
	tax: 0,
	DeliveryCarrier: {},
	currentOrderId: '',
};

const CheckoutUserData = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUserDataAction: (state, action) => {
			Object.entries(action.payload ?? {}).forEach(
				([key, value]) => {
					state[key] = value;
				}
			);
		},

		clearUserDataAction: () => {
			return initialState;
		},
	},
	selectors: {
		selectUserData: (checkoutUserData) => checkoutUserData,
	},
});

export default CheckoutUserData.reducer;

export const { updateUserDataAction, clearUserDataAction } =
	CheckoutUserData.actions;

export const { selectUserData } =
	CheckoutUserData.selectors;
