import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fallbackImage } from '../../utils/constants';

const SubCategoryBox = ({ mainCatId, subCat }) => {
	return (
		<div className='subcat-box'>
			<Link
				to={`/shop/${mainCatId}/${subCat._id}`}
				className='top-div'
			>
				<img
					src={subCat.image ?? fallbackImage}
					alt='category-img'
				/>
				<span className='subCat-name'>
					{/* <Link to={`/shop/${mainCatId}/${subCat._id}`}> */}
					{subCat.name}
					{/* </Link> */}
				</span>
			</Link>
			<ul>
				{subCat?.childSubCategories?.map(
					(subsubCat, index) => (
						<li key={index}>
							<Link
								to={`/shop/${mainCatId}/${subCat._id}/${subsubCat?._id}`}
							>
								{subsubCat.name}
							</Link>
						</li>
					)
				)}
			</ul>
		</div>
	);
};

SubCategoryBox.propTypes = {
	mainCatId: PropTypes.string,
	subCat: PropTypes.object,
};

export default SubCategoryBox;
