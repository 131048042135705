import {
	configureStore,
	combineReducers,
} from '@reduxjs/toolkit';
import { apiSlice } from './API/api';
import {
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import authReducer from './features/authModal';
import cartReducer from './features/cart';
import userReducer from './features/user';
import checkoutReducer from './features/CheckoutProgress';
import { setupListeners } from '@reduxjs/toolkit/query';

const isServer = typeof window === 'undefined';
const noopStorage = {
	getItem: () => Promise.resolve(null),
	setItem: () => Promise.resolve(),
	removeItem: () => Promise.resolve(),
};

const persistConfig = {
	key: 'root',
	blacklist: ['categories', apiSlice.reducerPath], // Exclude non-persistable reducers
	version: 1,
	storage: isServer ? noopStorage : storage, // Use noopStorage on the server
};

const rootReducer = combineReducers({
	[apiSlice.reducerPath]: apiSlice.reducer,
	auth: authReducer,
	cart: cartReducer, // Persist this
	user: userReducer, // Persist this
	checkoutProgress: checkoutReducer,
});

const persistedReducer = persistReducer(
	persistConfig,
	rootReducer
);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}).concat(apiSlice.middleware),
});

export const persistor = persistStore(store); // Enable persist
setupListeners(store.dispatch);
