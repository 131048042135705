import React from "react";
import "./WeeklyUpdate.scss";
import { Container } from "@mui/material";

const WeeklyUpdate = () => {
  return (
    <Container
      sx={{
        padding: "20px !important",
        paddingTop: "80px !important",
        paddingBottom: "80px !important",
      }}>
      <div className="weekly-update-wrapper">
        <div className="content">
          <div className="header">
            <div className="newsletter">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 4.86342C0 4.18144 0.270918 3.52738 0.753154 3.04515C1.23539 2.56291 1.88944 2.29199 2.57143 2.29199H21.4286C22.1106 2.29199 22.7646 2.56291 23.2468 3.04515C23.7291 3.52738 24 4.18144 24 4.86342V5.44971L12.6566 13.0131C12.4586 13.1323 12.2311 13.1935 12 13.1897C11.7689 13.1935 11.5414 13.1323 11.3434 13.0131L0 5.44971V4.86342ZM0 8.02456V19.4349C0 20.1168 0.270918 20.7709 0.753154 21.2531C1.23539 21.7354 1.88944 22.0063 2.57143 22.0063H21.4286C22.1106 22.0063 22.7646 21.7354 23.2468 21.2531C23.7291 20.7709 24 20.1168 24 19.4349V8.02456L13.8394 14.7994L13.8309 14.8063C13.2839 15.1541 12.6482 15.3369 12 15.3326C11.3571 15.3326 10.7091 15.1577 10.1691 14.8063L10.1606 14.7994L0 8.02456Z"
                  fill="#008FD5"
                />
              </svg>
              Newsletter
            </div>
            <h3>Get Weekly Updates</h3>
          </div>
          <div className="input-button">
            <input className="normal-input" placeholder="Your email" />
            <button className="blue-button">Subscribe</button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WeeklyUpdate;
