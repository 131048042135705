import { apiSlice } from '../api';

export const categoryTag = 'categoryTag';
export const subCategoryTag = 'subCategoryTag';

const apiSliceWithExtendedTags = apiSlice.enhanceEndpoints({
	addTagTypes: [categoryTag, subCategoryTag],
});

export const apiSliceWithCategoryEndpoints =
	apiSliceWithExtendedTags.injectEndpoints({
		endpoints: (builder) => ({
			getCategories: builder.query({
				query: () => ({
					url: '/categories',
				}),
				providesTags: (result) => [
					categoryTag,
					...(result ?? []).map(({ id }) => ({
						type: categoryTag,
						id,
					})),
				],
			}),
			getCategoryById: builder.query({
				query: (id) => ({
					url: `/categories/${id}`,
				}),
				providesTags: (result, error, id) => [
					{ type: categoryTag, id },
				],
			}),
			getCategoryFilters: builder.query({
				query: (id) => ({
					url: `/categories/filters/${id}`,
				}),
				providesTags: (result, error, id) => [
					{ type: categoryTag, id },
				],
			}),
			getSubCategoryById: builder.query({
				query: (id) => ({
					url: `/sub-categories/${id}`,
				}),
				providesTags: (result, error, id) => [
					{ type: subCategoryTag, id },
				],
			}),
			getSubCategoryFilters: builder.query({
				query: (id) => ({
					url: `/sub-categories/filters/${id}`,
				}),
				providesTags: (result, error, id) => [
					{ type: subCategoryTag, id },
				],
			}),
		}),
	});

export const {
	useGetCategoriesQuery,
	useGetCategoryByIdQuery,
	useGetSubCategoryByIdQuery,
	useGetCategoryFiltersQuery,
	useGetSubCategoryFiltersQuery,
} = apiSliceWithCategoryEndpoints;
