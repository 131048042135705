import React from "react";
import { Container, Divider } from "@mui/material";
import "./Discover.scss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import substract3 from "../../assets/Subtract (3).png";

const Discover = () => {
  return (
    <Container
      sx={{
        maxWidth: "1352px !important",
        paddingX: { xs: "20px !important", lg: "0px !important" },
      }}>
      <div className="discover-wrapper">
        <img src={substract3} alt="plumbing" />
        <div className="header">
          <p>About Us</p>
          <h1>
            Discover the Valley Plumbing, Heating & Water Treatment Difference
          </h1>
        </div>
        <div className="header mobile">
          <p>About Us</p>
          <h1>
            Discover the Valley Plumbing, Heating & Water Treatment Difference
          </h1>
        </div>
        <div className="right-side-wrapper">
          <div className="cart-title">
            <motion.svg
              initial={{ opacity: 0, x: -50, y: -30 }}
              whileInView={{ opacity: 1, x: 0, y: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="30"
              viewBox="0 0 29 30"
              fill="none">
              <g clipPath="url(#clip0_86_2741)">
                <path d="M5.17822 1.68506V28.6136V1.68506Z" fill="#008FD5" />
                <path
                  d="M5.17822 1.68506V28.6136"
                  stroke="#008FD5"
                  strokeWidth="2.07143"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.17822 1.68506L23.8211 11.0065L5.17822 20.3279"
                  fill="#008FD5"
                />
                <path
                  d="M5.17822 1.68506L23.8211 11.0065L5.17822 20.3279"
                  stroke="#008FD5"
                  strokeWidth="2.07143"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_86_2741">
                  <rect
                    width="29"
                    height="29"
                    fill="white"
                    transform="translate(0 0.649414)"
                  />
                </clipPath>
              </defs>
            </motion.svg>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ ease: "easeOut", duration: 0.4, delay: 0.2 }}
              className="text">
              <h1>Founded in</h1>
              <p>
                Founded 1987 in Perth, Ontario, Valley Plumbing, Heating, and
                Water Treatment has become a trusted name in water treatment
                services.
              </p>
            </motion.div>
          </div>
          <Divider
            sx={{ width: "100%", backgroundColor: " rgba(0, 0, 0, 0.05)" }}
          />
          <div className="cart-title">
            <motion.svg
              initial={{ opacity: 0, x: -50, y: -30 }}
              whileInView={{ opacity: 1, x: 0, y: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="30"
              viewBox="0 0 29 30"
              fill="none">
              <g clipPath="url(#clip0_86_2751)">
                <path
                  d="M24.7603 12.0054C24.7603 17.6753 20.1638 22.2718 14.4939 22.2718C8.82394 22.2718 4.22754 17.6753 4.22754 12.0054C4.22754 6.33542 8.82394 1.73901 14.4939 1.73901C20.1638 1.73901 24.7603 6.33542 24.7603 12.0054Z"
                  fill="#008FD5"
                  stroke="#008FD5"
                  strokeWidth="2.07143"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.9141 6.43121L16.3283 9.27601C16.3599 9.34795 16.4101 9.41022 16.4736 9.45645C16.5372 9.50266 16.6119 9.53119 16.6901 9.5391L19.8308 10.016C19.9206 10.0277 20.0052 10.0647 20.0748 10.1227C20.1443 10.1807 20.1959 10.2573 20.2235 10.3435C20.2511 10.4298 20.2536 10.5221 20.2306 10.6097C20.2077 10.6973 20.1603 10.7765 20.0939 10.8382L17.7753 13.0417C17.7408 13.1076 17.7227 13.181 17.7227 13.2554C17.7227 13.3299 17.7408 13.4032 17.7753 13.4692L18.2193 16.5935C18.2383 16.6838 18.2306 16.7777 18.1973 16.8637C18.1639 16.9498 18.1063 17.0243 18.0315 17.0783C17.9566 17.1322 17.8677 17.1633 17.7756 17.1678C17.6834 17.1722 17.5919 17.1498 17.5122 17.1033L14.7168 15.6233C14.6444 15.5907 14.5659 15.5738 14.4866 15.5738C14.4072 15.5738 14.3287 15.5907 14.2564 15.6233L11.4609 17.1033C11.3812 17.1498 11.2897 17.1722 11.1976 17.1678C11.1054 17.1633 11.0165 17.1322 10.9417 17.0783C10.8668 17.0243 10.8092 16.9498 10.7759 16.8637C10.7425 16.7777 10.7349 16.6838 10.7538 16.5935L11.28 13.4692C11.3026 13.3967 11.307 13.3197 11.2926 13.2451C11.2783 13.1705 11.2457 13.1006 11.1978 13.0417L8.87921 10.8217C8.81771 10.7594 8.77454 10.6814 8.75443 10.5962C8.73434 10.511 8.73809 10.4219 8.76526 10.3387C8.79242 10.2555 8.84199 10.1813 8.90848 10.1244C8.975 10.0675 9.05588 10.03 9.1423 10.016L12.2831 9.55555C12.3613 9.54763 12.436 9.51911 12.4995 9.4729C12.5631 9.42666 12.6132 9.3644 12.6448 9.29246L14.059 6.44766C14.0969 6.36712 14.1565 6.29879 14.2311 6.25038C14.3058 6.20197 14.3925 6.17539 14.4815 6.17367C14.5704 6.17197 14.6581 6.19517 14.7345 6.2407C14.811 6.28621 14.8732 6.35221 14.9141 6.43121Z"
                  fill="white"
                />
                <path
                  d="M5.60112 17.0408L1.03564 24.9484L5.96876 23.6266L7.29058 28.5597L11.2518 21.6987"
                  stroke="#008FD5"
                  strokeWidth="2.07143"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.3987 17.0408L27.9642 24.9484L23.0311 23.6266L21.7093 28.5597L17.748 21.6987"
                  stroke="#008FD5"
                  strokeWidth="2.07143"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_86_2751">
                  <rect
                    width="29"
                    height="29"
                    fill="white"
                    transform="translate(0 0.649414)"
                  />
                </clipPath>
              </defs>
            </motion.svg>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ ease: "easeOut", duration: 0.4, delay: 0.2 }}
              className="text">
              <h1>Licensed and Insured</h1>
              <p>
                Licensed and insured, our strategic HWY 7 location enables us to
                serve both residential and commercial clients across areas from
                Ottawa to Kingston, Westport to Pembroke, and beyond.
              </p>
            </motion.div>
          </div>
          <p>
            With a legacy rooted in local communities, Valley isn't just about
            service — it's about forging lasting relationships.
          </p>
          <Link className="light-blue-button" to="/about">
            Learn More
          </Link>
        </div>
      </div>
      <Divider />
    </Container>
  );
};

export default Discover;
