import { apiSlice } from '../api';

export const storeTags = 'storeTags';

const apiSliceWithExtendedTags = apiSlice.enhanceEndpoints({
	addTagTypes: [storeTags],
});

export const apiSliceWithStoreEndpoints =
	apiSliceWithExtendedTags.injectEndpoints({
		endpoints: (builder) => ({
			getStoreAddresses: builder.query({
				query: () => ({
					url: `/addresses/stores/${
						import.meta.env.VITE_STORE_KEY
					}`,
				}),
				providesTags: [storeTags],
			}),
		}),
	});

export const { useGetStoreAddressesQuery } =
	apiSliceWithStoreEndpoints;
