import { Button, IconButton, MenuItem, Select } from "@mui/material";
import { FaHeart } from "react-icons/fa6";
import { fallbackImage } from "../../utils/constants";
import "./WishlistProduct.scss";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  addProductToCartAction,
  selectSelectedProducts,
  toggleProductInWishlistAction,
} from "../../utils/features/cart";
import { IoIosRemove } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function WishlistProduct({
  product,
  quantity = 0,
  setQuantity,
  moreThanOne,
}) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const initialVariant =
    product.productVariants.find((v) => v._id === product.productVariantId) ??
    {};

  const [selectedVariant, setSelectedVariant] = useState(initialVariant);

  const { allInventories } = product;

  const currentInventory = allInventories.find(
    (inv) => inv.variant === (selectedVariant?._id ?? product.productVariantId)
  );

  // console.log(allInventories, product, selectedVariant, currentInventory);

  const maxQuantity = currentInventory.quantity;

  const increaseQuantity = () => {
    if (!product.trackQuantity || quantity < maxQuantity) {
      setQuantity(quantity + 1);
    }
  };
  const decreaseQuantity = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  const cartProducts = useSelector(selectSelectedProducts);

  const productAttributes = product?.attributes ?? {};
  const allAttributes = Object.entries(productAttributes).map(
    ([name, value]) => ({ name, value })
  );

  const [selectedAttributes, setSelectedAttributes] = useState(
    initialVariant.attribute ?? {}
  );

  // console.log(
  // 	selectedVariant,
  // 	initialVariant,
  // 	allAttributes,
  // 	selectedAttributes
  // );

  const toggleProductInWishlist = () =>
    dispatch(
      toggleProductInWishlistAction({
        product: {
          ...product,
          productVariantId: selectedVariant._id,
        },
      })
    );

  const handleVariantChange = (name, value) => {
    const newAttributes = {
      ...selectedAttributes,
      [name]: value,
    };
    setSelectedAttributes(newAttributes);
    const newVariant = product.productVariants.find((v) => {
      return Object.keys(newAttributes ?? {}).every(
        (key) => newAttributes[key] === v.attribute[key]
      );
    });
    // console.log(newVariant);
    setSelectedVariant(newVariant);
    if (newVariant) {
      const inv = allInventories.find((inv) => inv.variant === newVariant._id);

      if (quantity > inv.quantity) {
        setQuantity(inv.quantity);
      }
    }
  };
  const handleAddToCart = () => {
    // console.log(product, quantity);

    dispatch(
      addProductToCartAction({
        product: {
          ...product,
          productVariantId: selectedVariant._id ?? product.productVariantId,
        },
        quantity,
      })
    );
  };

  const isInCart = cartProducts.find(
    (p) =>
      p.product._id === product._id &&
      p.product.productVariantId ===
        (selectedVariant._id ?? product.productVariantId) &&
      p.quantity === quantity
  );
  return (
    <div
      style={{ cursor: "pointer" }}
      className='product-card'
      onClick={() => navigate(`/product/${product._id}`)}>
      <div className='product-image'>
        <img alt='' src={product.productMedia?.at(0)?.file ?? fallbackImage} />
      </div>
      <div className='product-details'>
        <h3>{product.name}</h3>
        <div className='options' onClick={(e) => e.stopPropagation()}>
          {allAttributes.map(({ name, value }, index) => (
            <div key={index} className='option'>
              <span>{name}:</span>
              {name.toLowerCase().includes("color") ? (
                value.map((v, index) => (
                  <div
                    key={index}
                    onClick={() => handleVariantChange(name, v)}
                    style={{
                      backgroundColor: v,
                    }}
                    className={`color-option ${
                      v === selectedAttributes[name] ? "selected" : ""
                    }`}></div>
                ))
              ) : (
                <Select
                  key={index}
                  value={selectedAttributes[name] ?? ""}
                  fullWidth
                  onChange={(e) => handleVariantChange(name, e.target.value)}
                  displayEmpty
                  sx={{
                    textTransform: "capitalize",
                    maxHeight: "40px",
                    borderRadius: "0px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#F7B550",
                    },
                    "&.Mui-focused": {
                      color: "#F7B550",
                    },
                  }}
                  placeholder='Select an option'>
                  {(productAttributes[name] ?? []).map((value, index) => (
                    <MenuItem
                      style={{
                        textTransform: "capitalize",
                      }}
                      key={index}
                      value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
          ))}
        </div>
      </div>
      {selectedVariant ? (
        <>
          <div
            className='product-price-and-wishlist'
            onClick={(e) => e.stopPropagation()}>
            <IconButton onClick={toggleProductInWishlist} className='iconBttn'>
              <FaHeart className='icon' />
            </IconButton>
            <div className='price'>
              {product.isDiscounted &&
                product.finalPrice !== product.priceBeforeDiscount && (
                  <span className='old-price'>
                    ${product.priceBeforeDiscount?.toFixed(2) ?? 0}
                  </span>
                )}
              <span className='current-price'>
                ${product.maxPrice?.toFixed(2) ?? 0}
              </span>
            </div>
          </div>
          <div className='product-actions' onClick={(e) => e.stopPropagation()}>
            <div className='quantity-box'>
              <button
                className={`action-button ${quantity <= 0 ? "disabled" : ""}`}
                onClick={() => decreaseQuantity()}>
                {/* <svg
              xmlns='http://www.w3.org/2000/svg'
              width='17'
              height='17'
              viewBox='0 0 17 17'
              fill='none'>
              <path
                d='M4.84984 14.5C4.58317 14.5 4.34984 14.4 4.14984 14.2C3.94984 14 3.84984 13.7667 3.84984 13.5V4H3.6665C3.52206 4 3.40262 3.95278 3.30817 3.85833C3.21373 3.76389 3.1665 3.64444 3.1665 3.5C3.1665 3.35556 3.21373 3.23611 3.30817 3.14167C3.40262 3.04722 3.52206 3 3.6665 3H6.29984C6.29984 2.85556 6.34706 2.73611 6.4415 2.64167C6.53595 2.54722 6.65539 2.5 6.79984 2.5H10.1998C10.3443 2.5 10.4637 2.54722 10.5582 2.64167C10.6526 2.73611 10.6998 2.85556 10.6998 3H13.3332C13.4776 3 13.5971 3.04722 13.6915 3.14167C13.7859 3.23611 13.8332 3.35556 13.8332 3.5C13.8332 3.64444 13.7859 3.76389 13.6915 3.85833C13.5971 3.95278 13.4776 4 13.3332 4H13.1498V13.5C13.1498 13.7667 13.0498 14 12.8498 14.2C12.6498 14.4 12.4165 14.5 12.1498 14.5H4.84984ZM4.84984 4V13.5H12.1498V4H4.84984ZM6.6165 11.5667C6.6165 11.7111 6.66373 11.8306 6.75817 11.925C6.85261 12.0194 6.97206 12.0667 7.1165 12.0667C7.26095 12.0667 7.38039 12.0194 7.47484 11.925C7.56928 11.8306 7.6165 11.7111 7.6165 11.5667V5.91667C7.6165 5.77222 7.56928 5.65278 7.47484 5.55833C7.38039 5.46389 7.26095 5.41667 7.1165 5.41667C6.97206 5.41667 6.85261 5.46389 6.75817 5.55833C6.66373 5.65278 6.6165 5.77222 6.6165 5.91667V11.5667ZM9.38317 11.5667C9.38317 11.7111 9.43039 11.8306 9.52484 11.925C9.61928 12.0194 9.73873 12.0667 9.88317 12.0667C10.0276 12.0667 10.1471 12.0194 10.2415 11.925C10.3359 11.8306 10.3832 11.7111 10.3832 11.5667V5.91667C10.3832 5.77222 10.3359 5.65278 10.2415 5.55833C10.1471 5.46389 10.0276 5.41667 9.88317 5.41667C9.73873 5.41667 9.61928 5.46389 9.52484 5.55833C9.43039 5.65278 9.38317 5.77222 9.38317 5.91667V11.5667Z'
                fill='#FE353C'
              />
            </svg> */}
                <IoIosRemove />
              </button>
              <p>{quantity}</p>
              <button
                className={`action-button ${
                  product.trackQuantity && quantity >= maxQuantity
                    ? "disabled"
                    : ""
                }`}
                onClick={() => increaseQuantity()}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='17'
                  height='17'
                  viewBox='0 0 17 17'
                  fill='none'>
                  <path
                    d='M7.99984 9.00029H4.6665C4.52484 9.00029 4.40609 8.95236 4.31025 8.85649C4.21442 8.76061 4.1665 8.64181 4.1665 8.50009C4.1665 8.35836 4.21442 8.23963 4.31025 8.14391C4.40609 8.04819 4.52484 8.00033 4.6665 8.00033H7.99984V4.66699C7.99984 4.52533 8.04777 4.40658 8.14364 4.31074C8.23952 4.21491 8.35832 4.16699 8.50004 4.16699C8.64177 4.16699 8.7605 4.21491 8.85622 4.31074C8.95194 4.40658 8.9998 4.52533 8.9998 4.66699V8.00033H12.3331C12.4748 8.00033 12.5936 8.04826 12.6894 8.14413C12.7852 8.24 12.8331 8.3588 12.8331 8.50053C12.8331 8.64226 12.7852 8.76099 12.6894 8.85671C12.5936 8.95243 12.4748 9.00029 12.3331 9.00029H8.9998V12.3336C8.9998 12.4753 8.95187 12.594 8.856 12.6899C8.76013 12.7857 8.64133 12.8336 8.4996 12.8336C8.35787 12.8336 8.23914 12.7857 8.14342 12.6899C8.0477 12.594 7.99984 12.4753 7.99984 12.3336V9.00029Z'
                    fill='black'
                  />
                </svg>
              </button>
            </div>
            {moreThanOne &&
              (isInCart ? (
                <div className='added-to-cart'>Added To Cart</div>
              ) : (
                <Button
                  onClick={handleAddToCart}
                  disabled={!selectedVariant}
                  className={`add-to-cart ${
                    selectedVariant ? "" : "disabled"
                  }`}>
                  Add to Cart
                </Button>
              ))}
          </div>
        </>
      ) : (
        <div> This Variant is Currently Unavailable</div>
      )}
    </div>
  );
}

WishlistProduct.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number,
  setQuantity: PropTypes.func.isRequired,
  moreThanOne: PropTypes.bool,
};
