import './Cart.scss';
import { Container } from '@mui/material';
import CartProducts from '../../components/Cart/CartProducts';
// import {cartProducts} from "../../utils/constants";
import OrderTotal from '../../components/OrderTotal';
import { selectSelectedProducts } from '../../utils/features/cart';
import { useSelector } from 'react-redux';

const Cart = () => {
	const selectedProducts = useSelector(
		selectSelectedProducts
	);

	return (
		<Container>
			<div className="cart-wrapper">
				{selectedProducts.length > 0 ? (
					<div className="cart">
						<h2>Your Cart</h2>
						<div className="cart-products-wrapper">
							{selectedProducts.map((product, index) => (
								<CartProducts
									key={index}
									cartProduct={product}
								/>
							))}
						</div>
					</div>
				) : (
					<h3
						style={{
							textAlign: 'center',
							alignSelf: 'center',
							width: '100%',
							padding: '60px',
						}}
					>
						Oops, Looks Like Your Cart Is Empty
					</h3>
				)}
				{selectedProducts.length > 0 && <OrderTotal />}
			</div>
		</Container>
	);
};

export default Cart;
