import Accordion from '@/components/Accordion/Accordion';
import { selectSelectedProducts } from '@/utils/features/cart';
import { BsBoxSeam } from 'react-icons/bs';
import './ShippingMethod.scss';
import { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomLoader from '@/components/Shared/CustomLoader/CustomLoader';
import { CustomLoaderType } from '@/utils/constants';
import { useGetShippingRatesMutation } from '../../../../utils/API/features/ordersApi';

const ShippingMethodStep = forwardRef(
	(
		{
			data,
			handleChange,
			handleNextButton,
			handleStepsStatusChange,
			StepsCompletion,
			DeliveryCarrier,
			setDeliveryCarrier,
		},
		ShippingRef
	) => {
		// console.log(DeliveryCarrier);
		const { address, country, province, city, zipCode } =
			data;
		const { delivery } = StepsCompletion;
		const [shippo, setShippo] = useState([]);
		const [isLoading, setIsLoading] = useState(true);

		const selectedProducts = useSelector(
			selectSelectedProducts
		);

		const [getShippingRates] =
			useGetShippingRatesMutation();

		const getShippoData = async () => {
			console.log('shippo', data);

			if (
				address &&
				address !== '' &&
				country &&
				country !== '' &&
				city &&
				city !== '' &&
				province &&
				province !== '' &&
				zipCode &&
				zipCode !== '' &&
				data.email &&
				data.email !== '' &&
				data.phone &&
				data.phone !== '' &&
				data.firstName &&
				data.firstName !== '' &&
				data.lastName &&
				data.lastName !== ''
			) {
				const result = await getShippingRates({
					selectedProducts,
					address: {
						address: address,
						city: city,
						country: country,
						province: province,
						zipCode: zipCode,
						name: data.firstName + ' ' + data.lastName,
						company: data.company,
					},
				}).unwrap();

				console.log(result);

				setShippo(
					result.map((rate) => ({
						...rate,
						metadata: result.metadata,
					}))
				);
				setIsLoading(false);
			}
		};

		useEffect(() => {
			setIsLoading(true);
			getShippoData();
		}, [
			address,
			country,
			city,
			province,
			delivery,
			zipCode,
		]);
		const deliveryOptionsData = isLoading ? [] : shippo;
		return (
			<div className="Shipping Step">
				<Accordion
					currentStep="shipping"
					buttonText="Proceed to Payment Info"
					handleNextButton={handleNextButton}
					step={2}
					data={data}
					title="Shipping Method"
					validate={() => DeliveryCarrier !== ''}
					ref={ShippingRef}
					handleStepsStatusChange={handleStepsStatusChange}
					StepsCompletion={StepsCompletion}
				>
					<div className="radio-button-group">
						{isLoading ? (
							<CustomLoader
								type={CustomLoaderType.MEDIUM}
							/>
						) : deliveryOptionsData.length > 0 ? (
							deliveryOptionsData.map((option) => (
								<div
									key={option.objectId}
									className={`radio-button v3 ${
										DeliveryCarrier.objectId ===
										option.objectId
											? 'selected'
											: ''
									}`}
									onClick={() => setDeliveryCarrier(option)}
								>
									<div className="radio-circle">
										{DeliveryCarrier.objectId ===
											option.objectId && (
											<img
												src={'/icon/check-orange.svg'}
												alt=""
											/>
										)}
									</div>
									<div className="radio-content">
										<div className="radio-label">
											<div className="NameandImage">
												<div className="Name font1">
													{option.name}
												</div>
												<div className="Image">
													<img
														src={option.image}
														alt=""
														width={50}
														height={50}
													/>
												</div>
											</div>
										</div>
										<div className="radio-description">
											<div className="durationandPrice">
												<div>
													{option.estimatedDays && (
														<div>
															Estimated Duration:{' '}
															{option.estimatedDays} Day(s)
														</div>
													)}
													{option.duration && (
														<div className="duration">
															{option.duration}
														</div>
													)}
												</div>
												<div className="Price font1">
													$
													{Number(option.price)?.toFixed(
														2
													) ?? 0}
												</div>
											</div>
										</div>
									</div>
								</div>
							))
						) : (
							<div style={{ color: 'red' }}>
								Unable to provide delivery options to this
								order content,&nbsp; Please edit the order
								content, and try again
							</div>
						)}
					</div>
				</Accordion>
				{StepsCompletion.shipping && (
					<div className="confirmation MethodConfirmation">
						<div className="selectedCarrier">
							<div>
								<BsBoxSeam />
							</div>
							<div className="carrierInfo">
								<div className="NameandImage">
									<div className="Name font1">
										{DeliveryCarrier.name}
									</div>
									<div className="Image">
										<img
											src={DeliveryCarrier.image}
											alt=""
											width={50}
											height={50}
										/>
									</div>
								</div>
								<div className="durationandPrice">
									<div className="duration">
										{DeliveryCarrier.duration}
									</div>
									<div className="Price font1">
										$
										{Number(DeliveryCarrier.price).toFixed(
											2
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
);

ShippingMethodStep.displayName = 'ShippingMethodStep';
ShippingMethodStep.propTypes = {
	data: PropTypes.object,
	handleChange: PropTypes.func,
	handleNextButton: PropTypes.func,
	handleStepsStatusChange: PropTypes.func,
	StepsCompletion: PropTypes.object,
	DeliveryCarrier: PropTypes.object,
	setDeliveryCarrier: PropTypes.func,
};

export default ShippingMethodStep;
