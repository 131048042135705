import PropTypes from 'prop-types';
import { CustomLoaderType } from '../../../utils/constants';
import './CustomLoader.scss';

export default function CustomLoader({
	type = CustomLoaderType.MEDIUM,
	color,
}) {
	let loader;

	switch (type) {
		case CustomLoaderType.BIG:
			loader = (
				<div
					className="custom-loader"
					style={color ? { color } : null}
				></div>
			);
			break;
		case CustomLoaderType.SMALL:
			loader = (
				<div
					className="custom-loader-small"
					style={
						color
							? {
									background: `radial-gradient(farthest-side, ${color} 94%, #0000) top/16px 16px no-repeat,
						conic-gradient(#0000 30%, ${color})`,
							  }
							: null
					}
				></div>
			);
			break;
		default:
			loader = (
				<div
					className="custom-loader-medium"
					style={color ? { background: color } : null}
				></div>
			);
			break;
	}

	return (
		<div
			className={`custom-loader-container ${
				type === CustomLoaderType.BIG ? 'backdrop' : ''
			}`}
		>
			{loader}
		</div>
	);
}

CustomLoader.propTypes = {
	type: PropTypes.string,
	color: PropTypes.string,
};
