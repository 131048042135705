import { apiSlice } from "../api";

export const productTag = "productTag";

const apiSliceWithExtendedTags = apiSlice.enhanceEndpoints({
  addTagTypes: [productTag],
});

export const apiSliceWithSearchEndpoints =
  apiSliceWithExtendedTags.injectEndpoints({
    endpoints: (builder) => ({
      getProductsBySearchTerm: builder.query({
        query: ({ searchTerm, page, categoryId, subCategoryId }) => ({
          url: `/products/${
            subCategoryId
              ? `allProducts/${subCategoryId}`
              : categoryId
              ? `allCategoryProducts/${categoryId}`
              : "all"
          }?searchTerm=${encodeURIComponent(searchTerm)}${
            page ? `&page=${page}` : ""
          }`,
        }),
        providesTags: (result) => [
          productTag,
          ...(result?.data ?? []).map(({ id }) => ({
            type: productTag,
            id,
          })),
        ],
      }),
    }),
  });

export const { useGetProductsBySearchTermQuery } = apiSliceWithSearchEndpoints;
