import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	delivery: false,
	shipping: false,
	account: false,
	date: true,
	payment: false,
};

const CheckoutProgressSlice = createSlice({
	name: 'checkoutProgress',
	initialState,
	reducers: {
		completeStepAction: (state, action) => {
			const { currentStep, type } = action.payload;
			if (['Done', 'InComplete'].includes(type)) {
				state[currentStep] = type === 'Done';
			}
		},
		resetAllStepsAction: () => {
			return initialState;
		},
	},
	selectors: {
		selectDelivery: (state) => state.delivery,
		selectShipping: (state) => state.shipping,
		selectAccount: (state) => state.account,
		selectDate: (state) => state.date,
		selectPayment: (state) => state.payment,
		selectAllProgress: (state) => state,
	},
});

// Export the generated reducer function
export default CheckoutProgressSlice.reducer;

export const { completeStepAction, resetAllStepsAction } =
	CheckoutProgressSlice.actions;

export const {
	selectDelivery,
	selectShipping,
	selectAccount,
	selectDate,
	selectPayment,
	selectAllProgress,
} = CheckoutProgressSlice.selectors;
