import Accordion from '@/components/Accordion/Accordion';
import TelInput from '@/components/TelInput/TelInput';
import { TextField } from '@mui/material';
import { CiMail } from 'react-icons/ci';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { PiBuildingOffice, PiPhone } from 'react-icons/pi';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const AccountStep = forwardRef(
	(
		{
			data,
			handleChange,
			handleNextButton,
			handleStepsStatusChange,
			StepsCompletion,
		},
		AccountRef
	) => {
		const validateAccountForm = () => {
			return (
				data.phone &&
				data.email &&
				data.lastName &&
				data.firstName
			);
		};

		return (
			<>
				<Accordion
					buttonText="Proceed to Payment Info"
					currentStep="account"
					handleNextButton={handleNextButton}
					step={2}
					data={data}
					title="Account Information"
					validate={validateAccountForm}
					ref={AccountRef}
					handleStepsStatusChange={handleStepsStatusChange}
					StepsCompletion={StepsCompletion}
				>
					<div className="InputGroup">
						<TextField
							required
							label="First Name"
							name="firstName"
							error={data.firstName === ''}
							helperText={
								data.firstName === ''
									? 'First name is required'
									: ''
							}
							onChange={handleChange}
						/>
						<TextField
							required
							label="Last Name"
							name="lastName"
							error={data.lastName === ''}
							helperText={
								data.lastName === ''
									? 'Last name is required'
									: ''
							}
							onChange={handleChange}
						/>
						<TextField
							required
							label="Email Address"
							name="email"
							error={data.email === ''}
							helperText={
								data.email === ''
									? 'Valid Email is required'
									: ''
							}
							onChange={handleChange}
						/>
						{/* <TextField
              required
              label="Phone Number"
              name="phone"

              onChange={handleChange}
            /> */}
						<TelInput
							data={data}
							handleChange={handleChange}
							placeholder={'Phone'}
							name={'phone'}
						/>
						{data.profileType === 'buisness' && (
							<TextField
								required
								label="Company Name"
								name="company"
								error={data.company === ''}
								helperText={
									data.company === ''
										? 'Company name is required'
										: ''
								}
								onChange={handleChange}
							/>
						)}
					</div>
				</Accordion>
				{StepsCompletion.account && (
					<>
						<div className="confirmation">
							<div className="section ">
								<div className="data">
									<div>
										<div className="choice">
											<IoPersonCircleOutline className="icon" />
											{data.firstName} {data.lastName}
										</div>

										{data.profileType === 'buisness' && (
											<div className="choice">
												<PiBuildingOffice className="icon" />
												{data?.company}
											</div>
										)}
									</div>
									<div>
										<div className="choice">
											<PiPhone className="icon" />
											{data.phone}
										</div>
										<div className="choice">
											<CiMail className="icon" />
											{data.email}
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</>
		);
	}
);

AccountStep.displayName = 'AccountStep';
AccountStep.propTypes = {
	data: PropTypes.object,
	handleChange: PropTypes.func,
	handleNextButton: PropTypes.func,
	handleStepsStatusChange: PropTypes.func,
	StepsCompletion: PropTypes.object,
};

export default AccountStep;
