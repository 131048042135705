import {
  useState,
  // useContext,
  useEffect,
  useRef,
} from "react";
import "./Checkout.scss";
import {
  // Checkbox,
  // TextField,
  // useMediaQuery,
  // Select,
  // MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
// import { CartContext } from "../../Store/ShoppingCart";
import CartProduct from "../Cart/CartItem/CartItem";
// import { setCardType } from './CreditCard/CreditCardModel';
// import {
// 	FaCcVisa,
// 	FaPaypal,
// 	FaRegMoneyBillAlt,
// 	FaStore,
// } from 'react-icons/fa';
// import { IoPersonCircleOutline } from 'react-icons/io5';
// import {
// 	CiDeliveryTruck,
// 	CiMail,
// 	CiCreditCard1,
// 	CiCalendarDate,
// 	CiLocationOn,
// } from 'react-icons/ci';
// import { PiPhone, PiBuildingOffice } from 'react-icons/pi';
// import {
// 	SiMastercard,
// 	SiAmericanexpress,
// 	SiDiscover,
// } from 'react-icons/si';
// import { BsBoxSeam } from 'react-icons/bs';
// import CreditCardComponent from './CreditCard/CreditCard';
// import { CheckoutContext } from '../../Store/CheckoutProgressContext';
// import { pharmaSend } from '../../util/PharmasendApi';
import DeliveryStep from "./Steps/Delivery/Delivery";
import useGetSalesTax from "./hooks/useGetSalesTax";
import AccountStep from "./Steps/AccountInfo/AccountInfo";
import ShippingMethodStep from "./Steps/ShippingMethod/ShippingMethod";
import PaymentStep from "./Steps/PaymentByStripe/Payment";
import {
  addDiscountAction,
  addToShippingDiscountAction,
  // clearCartAction,
  clearDiscountsAction,
  clearShippingDiscountsAction,
  removeDiscountAction,
  removeFromShippingDiscountAction,
  selectDiscountsApplied,
  selectSelectedProducts,
  selectShippingDiscounts,
  selectTotalCartDiscount,
  selectTotalPrice,
  selectTotalQuantity,
  selectTotalShippingDiscount,
} from "../../utils/features/cart";
import {
  completeStepAction,
  resetAllStepsAction,
  selectAllProgress,
} from "../../utils/features/CheckoutProgress";
import { useGetAllStoreDiscountsQuery } from "../../utils/API/features/discountsApi";
import {
  clearUserDataAction,
  selectUserData,
  updateUserDataAction,
} from "../../utils/features/user";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { Elements } from '@stripe/react-stripe-js';
// import CheckoutForm from './CheckoutForm/CheckoutForm';
// import { loadStripe } from '@stripe/stripe-js';
import {
  useCreateNewOrderMutation,
  useGetPaymentIntentMutation,
} from "../../utils/API/features/ordersApi";
import { CustomLoaderType } from "../../utils/constants";
import CustomLoader from "../Shared/CustomLoader/CustomLoader";
import { useGetStoreAddressesQuery } from "../../utils/API/features/storeInfoApi";

const AlertTypes = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

const stripePromise = loadStripe(
  import.meta.env.VITE_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

export default function Checkout() {
  // useStates
  // const [shippingDiscount, setShippingDiscount] =
  // 	useState(0);
  // const [shippingDiscounts, setShippingDiscounts] =
  // 	useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState(AlertTypes.SUCCESS);
  const [orderProcessing, setOrderProcessing] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [clientSecret, setClientSecret] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [taxData, setTaxData] = useState({
    country: null,
    state: null,
  });
  ////////////////////////////////////////////////////////////////
  // redux store
  const data = useSelector(selectUserData);
  const DeliveryCarrier = data.DeliveryCarrier;
  const selectedProducts = useSelector(selectSelectedProducts);
  const totalPrice = useSelector(selectTotalPrice);
  const StepsCompletion = useSelector(selectAllProgress);
  const totalCartDiscount = useSelector(selectTotalCartDiscount);
  const shippingDiscounts = useSelector(selectShippingDiscounts);
  const totalQuantity = useSelector(selectTotalQuantity);
  const discountsApplied = useSelector(selectDiscountsApplied);
  const shippingDiscount = useSelector(selectTotalShippingDiscount);
  const setStepsCompletion = (payload) => dispatch(completeStepAction(payload));
  const setData = (data) => {
    dispatch(updateUserDataAction(data));
  };
  const setDeliveryCarrier = (carrier) =>
    dispatch(updateUserDataAction({ DeliveryCarrier: carrier }));
  const clearData = () => {
    setStartDate(new Date());
    dispatch(clearUserDataAction());
  };
  const resetAllProgress = () => {
    dispatch(resetAllStepsAction());
  };
  // const clearCart = () => {
  // 	dispatch(clearCartAction());
  // };
  // const clearDiscounts = () => {
  // 	dispatch(clearDiscountsAction());
  // };
  const addDiscount = (discount) => dispatch(addDiscountAction(discount));

  const removeDiscount = (discount) => {
    dispatch(removeDiscountAction(discount._id));
  };
  const clearShippingDiscounts = () => dispatch(clearShippingDiscountsAction());
  const addToShippingDiscount = (amount) =>
    dispatch(addToShippingDiscountAction(amount));
  const removeFromShippingDiscount = (amount) =>
    dispatch(removeFromShippingDiscountAction(amount));
  ////////////////////////////////////////////////////////////////
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    salesTax,
    // isFetching, error
  } = useGetSalesTax(
    taxData.country,
    taxData.state,
    taxData.country && taxData.state
  );
  const [getPaymentIntent] = useGetPaymentIntentMutation();
  const [createOrder] = useCreateNewOrderMutation();
  const {
    data: { data: allShippingDiscounts = [] } = {},
    // isLoading: shippingDiscountsLoading,
    // isError: shippingDiscountsError,
  } = useGetAllStoreDiscountsQuery({
    staticDiscountType: "SHIPPING",
  });
  const { data: addresses = [] } = useGetStoreAddressesQuery();
  ////////////////////////////////////////////////////////////////
  // Helper functions
  const handleProfileTypeChange = (type) => {
    setData({
      ...data,
      profileType: type,
    });
  };
  const handleDeliveryOptionChange = (option) => {
    setData({
      ...data,
      deliveryOption: option,
    });
    if (option !== "shipping") {
      clearShippingDiscounts();
      setDeliveryCarrier({});
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(data);
  };
  const handleStepsStatusChange = (step, value) => {
    setStepsCompletion({
      currentStep: step,
      type: value ? "Done" : "InComplete",
    });
  };
  // const validateDateForm = () => {
  // 	return startDate !== null;
  // };
  const handleProceed = (nextref) => {
    if (nextref.current) {
      // console.log(nextref.current);
      nextref.current.click();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };
  const handleNextButton = (currentStep) => {
    switch (currentStep) {
      case "delivery":
        if (data.deliveryOption === "shipping") {
          handleProceed(ShippingRef);
        } else {
          handleProceed(AccountRef);
        }
        break;
      case "shipping":
        handleProceed(PaymentRef);
        break;
      case "account":
        handleProceed(PaymentRef);
        break;
      // case 'date':
      // 	handleProceed(PaymentRef);
      // 	break;
      case "payment":
        handleProceed(PaymentRef);
        break;
      default:
      // console.log("Invalid step option");
    }
  };

  async function loadStripePayment() {
    const reqBody = {
      ...data,
      // isDiscounted: totalCartDiscount > 0,
      // discountedAmount:
      // 	totalCartDiscount + shippingDiscount,
      discountsApplied: discountsApplied.map((d) => ({
        _id: d._id,
      })),
      selectedProducts: selectedProducts.map((p) => ({
        quantity: p.quantity,
        product: {
          _id: p.product._id,
          productVariantId: p.product.productVariantId,
        },
      })),
      startDate,
      // subTotal: totalPrice,
      // tax: salesTax,
      shippingOption: {
        carrierCode: DeliveryCarrier?.carrierCode,
        serviceCode: DeliveryCarrier?.serviceCode,
        metadata: DeliveryCarrier?.metadata,
        objectId: DeliveryCarrier?.objectId,
        shipmentGateway: DeliveryCarrier?.shipmentGateway,
        version: DeliveryCarrier?.version,
      },
      shippingDiscount: shippingDiscounts,
      // shippingCost: DeliveryCarrier?.price
      // 	? Math.max(
      // 			0,
      // 			parseFloat(DeliveryCarrier?.price ?? 0)
      // 	  )
      // 	: 0,
      // grandTotal:
      // 	totalPrice +
      // 	(DeliveryCarrier
      // 		? parseFloat(DeliveryCarrier?.price ?? 0)
      // 		: 0) +
      // 	(totalPrice * salesTax ?? 0),
    };
    let paymentIntent;
    if (PaymentRef.current && PaymentRef.current.getPaymentIntent) {
      paymentIntent = await PaymentRef.current.getPaymentIntent(clientSecret);
    }

    const res = await getPaymentIntent({
      createOrderDto: reqBody,
      paymentIntent,
    }).unwrap();
    // const res = await request.json();
    // console.log(res);
    setClientSecret(res.client_secret);
  }
  const handleConfirmOrder = async (e) => {
    // console.log("Order Confirmed");
    setOrderProcessing(true);
    if (PaymentRef.current) {
      try {
        const paymentIntent = await PaymentRef.current.getPaymentIntent(
          clientSecret
        );

        // console.log(PaymentRef.current.getPaymentIntent);

        const reqBody = {
          ...data,
          // isDiscounted: totalCartDiscount > 0,
          // discountedAmount:
          // 	totalCartDiscount + shippingDiscount,
          discountsApplied: discountsApplied.map((d) => ({
            _id: d._id,
          })),
          shippingDiscount: shippingDiscounts,
          selectedProducts: selectedProducts.map((p) => ({
            quantity: p.quantity,
            product: {
              _id: p.product._id,
              productVariantId: p.product.productVariantId,
            },
          })),
          startDate,
          // subTotal: totalPrice,
          // tax: salesTax,
          shippingOption: {
            carrierCode: DeliveryCarrier?.carrierCode,
            serviceCode: DeliveryCarrier?.serviceCode,
            metadata: DeliveryCarrier?.metadata,
            objectId: DeliveryCarrier?.objectId,
            shipmentGateway: DeliveryCarrier?.shipmentGateway,
            version: DeliveryCarrier?.version,
          },
          // shippingCost: DeliveryCarrier?.price
          // 	? Math.max(
          // 			0,
          // 			parseFloat(DeliveryCarrier?.price ?? 0)
          // 	  )
          // 	: 0,
          // grandTotal:
          // 	totalPrice +
          // 	(DeliveryCarrier
          // 		? parseFloat(DeliveryCarrier?.price ?? 0)
          // 		: 0) +
          // 	(totalPrice * salesTax ?? 0),
          paymentIntent,
        };
        // console.log(reqBody);
        const order = await createOrder(reqBody).unwrap();
        const paymentResponse = await PaymentRef.current.handleSubmit(e);
        // console.log(paymentResponse);/
        // console.log("checked payment done");
        setOrderProcessing(false);
        setSnackBarMessage("Order Completed");
        setSnackBarType(AlertTypes.SUCCESS);
        setSnackBarOpen(true);
        setData({ ...data, currentOrderId: order._id });
        // clearData();
        // clearDiscounts();
        resetAllProgress();
        // clearCart();
        navigate("/payment-success");
        // console.log(data, selectedProducts, StepsCompletion);
      } catch (e) {
        // console.log(e);
        PaymentRef.current.click();
        setSnackBarMessage("Payment Failed");
        setSnackBarType(AlertTypes.ERROR);
        setSnackBarOpen(true);
      }
      setOrderProcessing(false);
    }

    // router.push('/stripe');
  };
  ////////////////////////////////////////////////////////////////
  // helper constants
  const authenticated = Object.entries(StepsCompletion).every(
    ([name, step]) => {
      if (name === "account" && data.deliveryOption === "shipping") {
        return true;
      } else if (name === "shipping" && data.deliveryOption === "pickup") {
        return true;
      }
      return step === true;
    }
  );
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  ////////////////////////////////////////////////////////////////
  // refs
  const DeliveryRef = useRef(null);
  const ShippingRef = useRef(null);
  const AccountRef = useRef(null);
  // const DateRef = useRef(null);
  const PaymentRef = useRef(null);
  ////////////////////////////////////////////////////////////////
  // useEffects
  useEffect(() => {
    clearData();
    resetAllProgress();
    loadStripePayment();
  }, []);
  // Applies Shipping Discount
  const userZipCode = data.zipCode;
  const deliveryOption = data.deliveryOption;
  useEffect(() => {
    // console.log(data, userZipCode, discountsApplied);
    dispatch(clearShippingDiscountsAction());
    if (
      deliveryOption === "shipping" &&
      userZipCode &&
      userZipCode.trim() !== ""
    ) {
      for (const discount of allShippingDiscounts) {
        const isEligible = discount.eligibleZipCodes.includes(userZipCode);
        const alreadyExists = shippingDiscounts.find(
          (d) => d._id === discount._id
        );
        const isMinAmount = discount.minPurchaseRequired === "MINIMUM_AMOUNT";
        const isMinQuantity =
          discount.minPurchaseRequired === "MINIMUM_QUANTITY";
        const minSufficed =
          (isMinAmount && totalPrice >= discount.minPurchaseAmount) ||
          (isMinQuantity && totalQuantity >= discount.minPurchaseQuantity) ||
          (!isMinAmount && !isMinQuantity);
        if (isEligible && minSufficed && !alreadyExists) {
          // addDiscount(discount);
          addDiscount(discount);
          if (discount.decreasePriceBy === "PERCENTAGE") {
            addToShippingDiscount(
              (parseFloat(DeliveryCarrier?.price ?? 0) * discount.amount) / 100
            );
          } else {
            addToShippingDiscount(discount.amount);
          }
        } else if (!(isEligible && minSufficed) && alreadyExists) {
          removeDiscount(discount);
          // removeDiscount(discount);
          if (discount.decreasePriceBy === "PERCENTAGE") {
            removeFromShippingDiscount(
              (parseFloat(DeliveryCarrier?.price ?? 0) * discount.amount) / 100
            );
          } else {
            removeFromShippingDiscount(discount.amount);
          }
        }
      }
    }
  }, [userZipCode, totalQuantity, totalPrice, DeliveryCarrier]);

  useEffect(() => {
    if (DeliveryCarrier && DeliveryCarrier.price) {
      loadStripePayment();
    }
  }, [shippingDiscount, DeliveryCarrier]);

  useEffect(() => {
    handleChange({
      target: { name: "tax", value: salesTax },
    });
  }, [salesTax]);

  const { country, province } = data;

  useEffect(() => {
    if (deliveryOption === "pickup" && addresses && addresses.length > 0) {
      setTaxData({
        country: addresses[0].country,
        state: addresses[0].state,
      });
    } else {
      setTaxData({
        country: country,
        state: province,
      });
    }
  }, [deliveryOption, province, country, addresses]);

  return (
    <>
      <div className={`CheckoutParent paddingClass `}>
        <div className='CheckoutContainer'>
          <div className={`steps`}>
            <div className='accountTypePrompt'>
              <div className='purpose'>Purpose</div>
              <div className='type'>Choosing Your Account Type</div>
            </div>
            <div className='radio-button-group horizontal'>
              <div
                className={`radio-button v2 ${
                  data.profileType === "personal" ? "selected" : ""
                }`}
                onClick={() => handleProfileTypeChange("personal")}>
                <div className='radio-circle'>
                  {data.profileType === "personal" && (
                    <img
                      src={"/icon/check-orange.svg"}
                      // fill
                      alt=''
                    />
                  )}
                </div>
                <div className='radio-content'>
                  <div className='radio-label'>Personal</div>
                  <div className='radio-description'>Keep it simple.</div>
                </div>
              </div>
              <div
                className={`radio-button v2 ${
                  data.profileType === "buisness" ? "selected" : ""
                }`}
                onClick={() => handleProfileTypeChange("buisness")}>
                <div className='radio-circle'>
                  {data.profileType === "buisness" && (
                    <img src={"/icon/check-orange.svg"} alt='' />
                  )}
                </div>
                <div className='radio-content'>
                  <div className='radio-label'>Business</div>
                  <div className='radio-description'>
                    Need an invoice for your business
                  </div>
                </div>
              </div>
            </div>

            {/* Delivery Step */}
            <DeliveryStep
              ref={DeliveryRef}
              data={data}
              handleChange={handleChange}
              handleNextButton={handleNextButton}
              handleStepsStatusChange={handleStepsStatusChange}
              StepsCompletion={StepsCompletion}
              handleDeliveryOptionChange={handleDeliveryOptionChange}
            />
            {/* Shipping Step */}
            {data.deliveryOption === "shipping" ? (
              <ShippingMethodStep
                ref={ShippingRef}
                data={data}
                handleChange={handleChange}
                handleNextButton={handleNextButton}
                StepsCompletion={StepsCompletion}
                DeliveryCarrier={DeliveryCarrier}
                setDeliveryCarrier={setDeliveryCarrier}
                handleStepsStatusChange={handleStepsStatusChange}
              />
            ) : (
              <div className='Step'>
                <AccountStep
                  ref={AccountRef}
                  data={data}
                  handleChange={handleChange}
                  handleNextButton={handleNextButton}
                  handleStepsStatusChange={handleStepsStatusChange}
                  StepsCompletion={StepsCompletion}
                />
              </div>
            )}

            {/* Date and Time Step */}
            {/* <div className="DateandTime Step">
							<Accordion
								optional
								currentStep="date"
								buttonText="Proceed to Payment Info"
								handleNextButton={handleNextButton}
								step={3}
								data={data}
								title="Preferred Date and Time"
								validate={validateDateForm}
								ref={DateRef}
								handleStepsStatusChange={
									handleStepsStatusChange
								}
								StepsCompletion={StepsCompletion}
							>
								<CustomDatePicker
									startDate={startDate}
									handleDateChange={setStartDate}
								/>
							</Accordion>
							<div className="DateandTimeResults">
								<div className="text">
									<CiCalendarDate />
									{startDate?.toLocaleDateString()}
								</div>
								<div className="text">
									<TbClock />
									{startDate?.toLocaleTimeString([], {
										hour: '2-digit',
										minute: '2-digit',
									})}
								</div>
							</div>
						</div> */}

            <PaymentStep
              stripePromise={stripePromise}
              paymentSuccess={paymentSuccess}
              handleChange={handleChange}
              handleNextButton={handleNextButton}
              handleStepsStatusChange={handleStepsStatusChange}
              setPaymentSuccess={setPaymentSuccess}
              StepsCompletion={StepsCompletion}
              clientSecret={clientSecret}
              options={options}
              ref={PaymentRef}
            />
          </div>

          {/* Order Summary */}
          <div className='OrderSummaryContainer'>
            <div className='OrderSummary'>
              <div className='summaryTitle'>
                Order Summary ({selectedProducts.length})
              </div>
              <div className='hr'>
                <hr />
              </div>
              <div className='Items'>
                {selectedProducts.map((product, index) => (
                  <div key={index}>
                    <CartProduct checkout CartProduct={product} />
                    {index < selectedProducts.length - 1 && (
                      <div className='hr'>
                        <hr />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className='total'>
                <div className='moneyLine'>
                  <div className='type'>Subtotal </div>
                  <div className='amount'>${totalPrice.toFixed(2)}</div>
                </div>
                <div className='moneyLine'>
                  <div className='type'>Tax</div>
                  <div className='amount'>
                    ${(totalPrice * salesTax).toFixed(2)}
                  </div>
                </div>
                <div className='moneyLine'>
                  <div className='type'>
                    {(DeliveryCarrier?.price ?? 0) > 0 && shippingDiscount > 0
                      ? "Original Delivery"
                      : "Delivery"}
                  </div>
                  <div
                    className='amount'
                    style={{
                      textDecoration:
                        DeliveryCarrier &&
                        (DeliveryCarrier?.price ?? 0) > 0 &&
                        shippingDiscount > 0
                          ? "line-through"
                          : null,
                    }}>
                    $
                    {DeliveryCarrier
                      ? parseFloat(DeliveryCarrier.price ?? 0).toFixed(2)
                      : 0}
                  </div>
                </div>
                {DeliveryCarrier &&
                  (DeliveryCarrier.price ?? 0) > 0 &&
                  shippingDiscount > 0 && (
                    <div className='moneyLine'>
                      <div className='type'>
                        Delivery after shipping discount
                      </div>
                      <div className='amount'>
                        $
                        {Math.max(
                          parseFloat(DeliveryCarrier.price ?? 0) -
                            shippingDiscount
                        ).toFixed(2)}
                      </div>
                    </div>
                  )}
                <div className='moneyLine'>
                  <div className='type'>Discount</div>
                  <div className='amount'>
                    ${(totalCartDiscount ?? 0).toFixed(2)}
                  </div>
                </div>
                <div id='grandTotal'>
                  <div className='type'>Total</div>
                  <div className='amount'>
                    <span>CAD</span> $
                    {Number(
                      totalPrice +
                        (DeliveryCarrier && DeliveryCarrier.price
                          ? parseFloat(DeliveryCarrier.price) - shippingDiscount
                          : 0) +
                        totalPrice * salesTax -
                        totalCartDiscount
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
              <div className='Delivery'>
                <div className='title font1'>Delivery</div>
                <div className='description'>
                  An estimate will appear after choosing the shipping method
                </div>
              </div>
              {/* <div className='SMS'>
								<div className='check'>
									<Checkbox />
								</div>
								<div className='description'>
									Sign up for SMS notifications about this
									order and receive texts directly to your
									phone at [Mobile Number]. This way, you
									will always know when your order ships and
									arrives.
								</div>
							</div> */}
              <div className='paymentContainer'>
                <div id='payment'>
                  {[1, 2, 3, 4, 5, 6].map((i, index) => (
                    <span key={index}>
                      <img
                        alt=''
                        src={`/cards/${i}.png`}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </span>
                  ))}
                </div>
                <div
                  style={{ textAlign: "center" }}
                  className='Footer-PoweredBy-Text Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400'>
                  Powered by{" "}
                  <span>
                    <svg
                      className='InlineSVG Icon Footer-PoweredBy-Icon Icon--md'
                      focusable='false'
                      width='33'
                      height='15'
                      role='img'
                      color='purple'
                      aria-labelledby='stripe-title'>
                      <title id='stripe-title'>Stripe</title>
                      <g fillRule='evenodd'>
                        <path d='M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z'></path>
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div
                className={`CartButtons ${
                  authenticated && !orderProcessing ? "Done" : "notDone"
                }`}>
                <button
                  className='btn_main'
                  disabled={!authenticated || orderProcessing}
                  onClick={handleConfirmOrder}>
                  {orderProcessing ? (
                    <CustomLoader
                      type={CustomLoaderType.SMALL}
                      color={"white"}
                    />
                  ) : (
                    "Confirm Order"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={1000}
        onClose={handleClose}>
        <Alert severity={snackBarType} variant='filled' sx={{ width: "100%" }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );

  // async function loadStripePayment() {
  // 	const request = await fetch(
  // 		'/api/create-payment-intent',
  // 		{
  // 			method: 'POST',
  // 			headers: { 'Content-Type': 'application/json' },
  // 			body: JSON.stringify({
  // 				totalPrice,
  // 				selectedProducts,
  // 			}),
  // 		}
  // 	);

  // 	const res = await request.json();

  // 	console.log(res);

  // 	setClientSecret(res.clientSecret);
  // 	// [DEV] For demo purposes only
  // 	setDpmCheckerLink(res.dpmCheckerLink);
  // }
}
