import React from "react";
import "./DedicatedToQuality.scss";
import { Container, Divider } from "@mui/material";
import { motion } from "framer-motion";
import logoservice from "../../assets/logo-service.png";
import logoservice2 from "../../assets/logo-service2.png";
import logoservice3 from "../../assets/logo-service3.png";
import logoservice4 from "../../assets/logo-service4.png";

const DedicatedToQuality = () => {
  const carts = [
    { name: "Training", image: logoservice },
    {
      name: "Pentair Gold Dealer",
      image: logoservice2,
    },
    { name: "MOE Certified", image: logoservice3 },
    {
      name: "Well Contractor Licensed",
      image: logoservice4,
    },
  ];
  return (
    <Container>
      <Divider />
      <div className="dedicated-quality-wrapper">
        <motion.div
          initial={{ opacity: 0, x: -50, y: -30 }}
          whileInView={{ opacity: 1, x: 0, y: 0 }}
          viewport={{ once: true, amount: 0.8 }}
          transition={{ ease: "easeOut", duration: 0.4 }}
          className="top-header">
          <p>Our Awards & Achievements</p>
          <h1>Excellence in Service and Dedication to Quality</h1>
        </motion.div>
        <div className="carts-wrapper">
          {carts.map((cart, index) => (
            <div className="cart" key={index}>
              <img
                src={cart.image}
                style={{
                  marginTop:
                    index === 0 || index === 2 || index === 3 ? "30px" : "20px",
                }}
                alt="logo"
              />
              <p>{cart.name}</p>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default DedicatedToQuality;
