import { Button, IconButton, InputAdornment, InputBase } from "@mui/material";
import "./Wishlist.scss";
import WishlistProduct from "../../components/Wishlist/WishlistProduct";
import FeaturedProducts from "../../components/Shop/FeaturedProducts";
import {
  useGetAllProductsQuery,
  useGetFrequentlyBoughtProductsQuery,
} from "../../utils/API/features/productsApi";
import { useSelector } from "react-redux";
import {
  batchAddProductsAction,
  selectWishlistProducts,
} from "../../utils/features/cart";
import CustomLoader from "../../components/Shared/CustomLoader/CustomLoader";
import { CustomLoaderType } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";

export default function Wishlist() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const wishlistProducts = useSelector(selectWishlistProducts);

  const wishlistIds = wishlistProducts.map(({ _id }) => _id);

  const [wishlistQuantity, setWishlistQuantity] = useState({});

  //   console.log(wishlistQuantity);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setWishlistQuantity((prev) => {
      const newQuantity = {};
      wishlistProducts.forEach((product) => {
        newQuantity[product._id] = prev[product._id] ?? 0;
      });
      return newQuantity;
    });
  }, [wishlistProducts]);

  const {
    data: { data: frequently = [] } = {},
    isLoading: frequentLoading,
    isFetching: frequentFetching,
  } = useGetFrequentlyBoughtProductsQuery(wishlistIds, {
    skip: wishlistProducts.length === 0,
  });

  const {
    data: { data: bestSellers = [] } = {},
    isLoading: bestSellersLoading,
    isFetching: bestSellersFetching,
  } = useGetAllProductsQuery({
    sort: "popularity",
  });

  const FeaturedProductsShop =
    wishlistProducts.length === 0 || !frequently || frequently.length === 0
      ? bestSellers
      : frequently;
  const isLoading =
    wishlistProducts.length === 0 ? bestSellersLoading : frequentLoading;
  const isFetching =
    wishlistProducts.length === 0 ? bestSellersFetching : frequentFetching;

  const heading =
    wishlistProducts.length === 0 || frequently?.length === 0
      ? "Featured Products"
      : "Frequently Bought Together";
  const handleSetQuantity = (id, quantity) => {
    const newQuantity = { ...wishlistQuantity };
    newQuantity[id] = quantity;
    setWishlistQuantity(newQuantity);
  };

  const batchAddAll = (payload) => dispatch(batchAddProductsAction(payload));

  const handleAddAllToCart = () => {
    // Check if all quantities are greater than 0
    if (
      Object.values(wishlistQuantity ?? {}).every((quantity) => quantity > 0)
    ) {
      const quantites = [];
      for (let i = 0; i < wishlistProducts.length; i++) {
        quantites.push(wishlistQuantity[wishlistProducts[i]._id]);
      }

      batchAddAll({
        products: wishlistProducts,
        quantities: quantites,
      });
    } else {
      // Optionally, show a message or handle the case where quantities are 0
      // console.log('All quantities must be at least 1.');
    }
  };

  let shownProducts = [];

  let shownQuantity = {};

  if (query.trim() !== "") {
    for (let i = 0; i < wishlistProducts.length; i++) {
      if (
        wishlistProducts[i].name
          .toLowerCase()
          .includes(query.trim().toLowerCase())
      ) {
        shownProducts.push(wishlistProducts[i]);
        shownQuantity[wishlistProducts[i]._id] =
          wishlistQuantity[wishlistProducts[i]._id];
      }
    }
  } else {
    shownProducts = wishlistProducts;
    shownQuantity = wishlistQuantity;
  }

  return (
    <div className='wishlist'>
      <div className='wrapper'>
        <div className='top-bar'>
          <h1>Wishlist</h1>
          <span className='actions'>
            {wishlistProducts?.length > 0 && (
              <InputBase
                className='search-bar'
                type='text'
                value={query}
                // onKeyDown={handleKeyPress}
                onChange={(e) => setQuery(e.target.value)}
                placeholder='Search among Wishlist Products'
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      //   disabled={categories && categories?.length === 0}
                      edge='end'
                      aria-label='search button'
                      //   onClick={handleSearch}
                    >
                      <SearchIcon className='SearchIcon' />
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
            <Button className='view-cart' onClick={() => navigate("/cart")}>
              View Cart
            </Button>
            {Object.values(wishlistQuantity ?? {}).length > 0 &&
              Object.values(wishlistQuantity ?? {}).every(
                (quantity) => quantity > 0
              ) &&
              wishlistProducts.every(
                (product) => product.productVariants.length <= 1
              ) && (
                <Button className='add-all' onClick={handleAddAllToCart}>
                  Add all to Cart
                </Button>
              )}
          </span>
        </div>
        <div className='wishlist-items'>
          {shownProducts.length > 0 ? (
            shownProducts.map((p) => (
              <WishlistProduct
                product={p}
                quantity={shownQuantity[p._id]}
                setQuantity={(quantity) => handleSetQuantity(p._id, quantity)}
                key={p._id}
                moreThanOne={shownQuantity[p._id] > 0}
              />
            ))
          ) : (
            <h2>No items in wishlist</h2>
          )}
        </div>
        {/* TODO: Add Featured Products */}
        {!(isLoading || isFetching) ? (
          <FeaturedProducts products={FeaturedProductsShop} heading={heading} />
        ) : (
          <CustomLoader type={CustomLoaderType.MEDIUM} />
        )}
      </div>
    </div>
  );
}
