import { apiSlice } from "../api";
import { productTag } from "./searchApi";

const apiSliceWithExtendedTags = apiSlice.enhanceEndpoints({
  addTagTypes: [productTag],
});

export const apiSliceWithProductEndpoints =
  apiSliceWithExtendedTags.injectEndpoints({
    endpoints: (builder) => ({
      getProductById: builder.query({
        query: (productId) => ({
          url: `/products/singleProduct/${productId}`,
        }),
        providesTags: (result) => [
          productTag,
          { type: productTag, id: result._id },
        ],
      }),
      getAllProducts: builder.query({
        query: (query) => {
          const searchQuery = new URLSearchParams();
          if (query) {
            Object.keys(query ?? {}).forEach((key) => {
              if (
                query[key] &&
                (!Array.isArray(query[key]) ||
                  (Array.isArray(query[key]) && query[key].length > 0))
              )
                searchQuery.append(key, encodeURIComponent(query[key]));
            });
          }
          return {
            url: `/products/all?${searchQuery.toString()}`,
          };
        },
        providesTags: (result) => [
          productTag,
          ...(result?.data ?? []).map(({ _id }) => ({
            type: productTag,
            id: _id,
          })),
        ],
      }),
      getAllCategoryProducts: builder.query({
        query: ({ categoryId, searchQuery } = {}) => {
          const searchParams = new URLSearchParams();
          // console.log(searchQuery);
          if (searchQuery) {
            Object.keys(searchQuery ?? {}).forEach((key) => {
              if (
                searchQuery[key] &&
                (!Array.isArray(searchQuery[key]) ||
                  (Array.isArray(searchQuery[key]) &&
                    searchQuery[key].length > 0))
              )
                searchParams.append(key, encodeURIComponent(searchQuery[key]));
            });
          }
          return {
            url: `/products/allCategoryProducts/${categoryId}?${searchParams.toString()}`,
          };
        },
        providesTags: (result) => [
          productTag,
          ...(result?.data ?? []).map(({ id }) => ({
            type: productTag,
            id,
          })),
        ],
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          // console.log(args);
          const { categoryId, searchQuery } = queryArgs;
          const searchArgs = new URLSearchParams();
          if (searchQuery) {
            Object.keys(searchQuery ?? {}).forEach((key) =>
              searchArgs.append(key, searchQuery[key])
            );
          }
          return `${endpointName}(${categoryId}?${searchArgs.toString()})`;
        },
      }),
      getAllSubCategoryProducts: builder.query({
        query: ({ subCategoryId, searchQuery } = {}) => {
          // console.log(subCategoryId, searchQuery);
          const searchParams = new URLSearchParams();
          if (searchQuery) {
            Object.keys(searchQuery ?? {}).forEach((key) => {
              if (
                searchQuery[key] &&
                (!Array.isArray(searchQuery[key]) ||
                  (Array.isArray(searchQuery[key]) &&
                    searchQuery[key].length > 0))
              ) {
                searchParams.append(key, encodeURIComponent(searchQuery[key]));
              }
            });
          }
          return {
            url: `/products/allProducts/${subCategoryId}?${searchParams.toString()}`,
          };
        },
        providesTags: (result) => [
          productTag,
          ...(result?.data ?? []).map(({ id }) => ({
            type: productTag,
            id,
          })),
        ],
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          // console.log(args);
          const { subCategoryId, searchQuery } = queryArgs;
          const searchArgs = new URLSearchParams();
          if (searchQuery) {
            Object.keys(searchQuery ?? {}).forEach((key) =>
              searchArgs.append(key, searchQuery[key])
            );
          }
          return `${endpointName}(${subCategoryId}?${searchArgs.toString()})`;
        },
      }),
      // getProductsByCategory: builder.query({
      //     query: (categoryId) => ({
      //         url: `/products/category/${categoryId}`,
      //     }),
      //     providesTags: (result) => [
      //         categoryTag,
      //         ...(result.data?? []).map(({ id }) => ({
      //             type: productTag,
      //             id,
      //         })),
      //     ],
      // }),
      getAllFilters: builder.query({
        query: () => ({
          url: "/products/allFilters",
        }),
      }),

      getRelatedProducts: builder.query({
        query: (productId) => ({
          url: `/products/relatedProducts/${productId}`,
        }),
        providesTags: (result) => [
          productTag,
          ...(result?.data ?? []).map(({ id }) => ({
            type: productTag,
            id,
          })),
        ],
      }),
      getFrequentlyBoughtProducts: builder.query({
        query: (productIds) => ({
          url: `/products/frequently-bought-together?productIds=${productIds.join(
            ","
          )}`,
        }),
        providesTags: (result) => [
          productTag,
          ...(result?.data ?? []).map(({ id }) => ({
            type: productTag,
            id,
          })),
        ],
      }),
    }),
  });

export const {
  useGetProductByIdQuery,
  useGetAllProductsQuery,
  useGetAllCategoryProductsQuery,
  useGetAllSubCategoryProductsQuery,
  useGetAllFiltersQuery,
  useGetRelatedProductsQuery,
  useGetFrequentlyBoughtProductsQuery,
} = apiSliceWithProductEndpoints;
