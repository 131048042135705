import "./ProductOnSwiper.scss";
import { useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Button, IconButton } from "@mui/material";
// import { FaStar } from 'react-icons/fa';
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  addProductToCartAction,
  selectSelectedProducts,
  selectWishlistProducts,
  toggleProductInWishlistAction,
} from "../utils/features/cart";
import { useSelector } from "react-redux";
import { fallbackImage } from "../utils/constants";

const ProductOnSwiper = ({ data }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const addToCart = (product) => dispatch(addProductToCartAction({ product }));

  const selectedProducts = useSelector(selectSelectedProducts);

  const isInCart = selectedProducts.find(
    (p) =>
      p.product._id === data._id &&
      p.product.productVariantId === data.productVariantId
  );

  const toggleInWishList = (product) =>
    dispatch(toggleProductInWishlistAction({ product }));

  const wishlistProducts = useSelector(selectWishlistProducts);

  const isInWishlist = wishlistProducts.find(
    (p) => p._id === data._id && p.productVariantId === data.productVariantId
  );

  // console.log("data", data);

  const isOutOfStock = data.totalInventory === 0;

  return (
    <div
      className='product-swiper'
      onClick={() => navigate(`/product/${data._id}`)}>
      {isOutOfStock && (
        <div className='out-of-stock'>
          <div>Out of Stock</div>
        </div>
      )}
      <img
        src={data.productMedia?.at(0)?.file ?? fallbackImage}
        alt='product'
        id='prodctCardImg'
      />
      <IconButton
        className='WishlistMobileIconBttn'
        onClick={(e) => {
          e.stopPropagation();
          toggleInWishList(data);
        }}>
        {isInWishlist ? (
          <FaHeart className='icon' />
        ) : (
          <FaRegHeart className='icon' />
        )}
      </IconButton>
      <div className='right-side'>
        <div className='prices'>
          {data.isDiscounted &&
            data.finalPrice !== data.priceBeforeDiscount && (
              <p className='price original-price'>
                ${data.priceBeforeDiscount.toFixed(2) ?? 0}
              </p>
            )}
          <p className='price'>${data.maxPrice?.toFixed(2) ?? 0}</p>
        </div>
        <p>{data.name}</p>
        <div
          className='cartHoverDiv'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}>
          <Button
            className='IconBttn'
            onClick={() => navigate(`/product/${data._id}`)}>
            <IoEyeOutline className='icon' />
          </Button>
          {!isInCart ? (
            <Button
              className='addToCart'
              onClick={() =>
                data.productVariants.length <= 1
                  ? addToCart(data)
                  : navigate(`/product/${data._id}`)
              }>
              Add to cart
            </Button>
          ) : (
            <div className='addedToCart'>Added to Cart</div>
          )}
          <IconButton
            className='IconBttn'
            onClick={() => toggleInWishList(data)}>
            {isInWishlist ? (
              <FaHeart className='icon' />
            ) : (
              <FaRegHeart className='icon' />
            )}
          </IconButton>
        </div>
      </div>
      {/* <button className="blue-button">Add to cart</button> */}
    </div>
  );
};

ProductOnSwiper.propTypes = {
  data: PropTypes.object,
};

export default ProductOnSwiper;
