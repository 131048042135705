'use client';

import React, {
	useState,
	forwardRef,
	useContext,
	useEffect,
} from 'react';
import './Accordion.scss';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { Snackbar } from '@mui/material';
// import { CheckoutContext } from "../../../Store/CheckoutProgressContext";

const Accordion = forwardRef((props, ref) => {
	const {
		buttonText,
		data,
		currentStep,
		handleNextButton,
		optional,
		handleStepsStatusChange,
		title,
		step,
		children,
		validate,
	} = props;
	const [activeIndex, setActiveIndex] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [Snackopen, setSnackOpen] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackOpen(false);
	};
	useEffect(() => {
		if (
			(currentStep === 'delivery' &&
				data.deliveryOption === 'shipping') ||
			currentStep === 'account'
		) {
			if (data.profileType === 'buisness') {
				setIsDone(false);
				handleStepsStatusChange(currentStep, false);
			}
		}
	}, [data?.profileType]);
	const toggleAccordion = () => {
		setActiveIndex(!activeIndex);
	};

	const handleEdit = () => {
		setActiveIndex(true);
		setIsDone(false);
		handleStepsStatusChange(currentStep, false);
	};

	const handleProceedClick = () => {
		if (validate()) {
			setActiveIndex(false);
			setIsDone(true);
			handleStepsStatusChange(currentStep, true);
			handleNextButton(currentStep);
		} else {
			setSnackOpen(true);
		}
	};

	const GetDeliveryText = () => {
		if (data.deliveryOption === 'shipping') {
			return 'Proceed to Shipping Method';
		} else if (data.deliveryOption === 'pickup') {
			return 'Proceed to Account Information';
		}
		return '';
	};

	return (
		<div
			className={`accordion ${activeIndex ? 'active' : ''}`}
		>
			<div className='Header'>
				<div
					ref={ref}
					className='subHeader'
					onClick={() => {
						if (!isDone) {
							toggleAccordion();
						}
					}}
				>
					<div className='StepNo'>
						<span>
							{isDone ? <IoCheckmarkSharp /> : step}
						</span>
					</div>
					<div className='mainQ'>{title}</div>
					{optional && <div id='optional'>Optional</div>}
				</div>
				{isDone && (
					<div className='editButton' onClick={handleEdit}>
						Edit
					</div>
				)}
			</div>
			<div className='Content'>
				<div className='contentData'>
					{children}
					<div className='button'>
						<button onClick={handleProceedClick}>
							{currentStep === 'delivery'
								? GetDeliveryText()
								: buttonText}
						</button>
					</div>
				</div>
			</div>
			<Snackbar
				open={Snackopen}
				autoHideDuration={1000}
				onClose={handleClose}
				message='Fill Empty Fields or Fix Errors'
			/>
		</div>
	);
});

export default Accordion;
