import React from "react";
import { Link } from "react-router-dom";

const Service = ({ data }) => {
  const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 60, 89, 0.40) 0%, rgba(0, 60, 89, 0.40) 100%), url(${data.backgroundImage}), lightgray 50%`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderTop: `22px solid ${data.topColor}`,
  };
  return (
    <div className="service-wrapper" style={backgroundImageStyle}>
      <div className="content">
        <h1>{data.name}</h1>
        <Link to={data.path}>View Service</Link>
      </div>
    </div>
  );
};

export default Service;
