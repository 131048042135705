import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, Suspense } from "react";
import Home from "./pages/Home/Home";
import Layout from "./components/Layout/Layout";
import WebFont from "webfontloader";
import { CircularProgress } from "@mui/material";
import Cart from "./pages/Cart/Cart";
// import PaymentInfo from './pages/Payment/PaymentInfo';
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import StoreProvider from "./components/StoreProvider/StoreProvider";
import CheckoutPage from "./pages/checkout/page";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Wishlist from "./pages/Wishlist/Wishlist";

const RequestQuote = React.lazy(() =>
  import("./pages/RequestQuote/RequestQuote")
);
const ServiceCompleted = React.lazy(() =>
  import("./pages/RequestQuote/ServiceCompleted")
);
const Shop = React.lazy(() => import("./pages/Shop/Shop"));
const ShopCategories = React.lazy(() => import("./pages/Shop/ShopCategories"));
const ShopSubCategories = React.lazy(() =>
  import("./pages/Shop/ShopSubCategories")
);
const ProductPage = React.lazy(() => import("./pages/ProductPage/ProductPage"));
const About = React.lazy(() => import("./pages/About/About"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const PrivacyPolicy = React.lazy(() =>
  import("./pages/PrivacyPolicy/PrivacyPolicy")
);
const TermsAndCondition = React.lazy(() =>
  import("./pages/TermsAndConditions/TermsAndCondition")
);
const ShippingPolicy = React.lazy(() =>
  import("./pages/ShippingPolicy/ShippingPolicy")
);
const ScheduleAnnualService = React.lazy(() =>
  import("./pages/ScheduleAnnualService/ScheduleAnnualService")
);
const Fixtures = React.lazy(() =>
  import("./pages/NewServices/Plumping/Fixtures")
);
const Sumppump = React.lazy(() =>
  import("./pages/NewServices/Plumping/Sumppump")
);
const Plumping = React.lazy(() =>
  import("./pages/NewServices/Plumping/Plumping")
);
const AirCondition = React.lazy(() =>
  import("./pages/NewServices/AirCondition/AirCondition")
);
const WaterTreatment = React.lazy(() =>
  import("./pages/NewServices/Water/WaterTreatment")
);
const WaterPurification = React.lazy(() =>
  import("./pages/NewServices/Water/WaterPurification")
);
const WaterSoftner = React.lazy(() =>
  import("./pages/NewServices/Water/WaterSoftner")
);
const Hvac = React.lazy(() => import("./pages/NewServices/Heating/Hvac"));
const PerthPage = React.lazy(() => import("./pages/ServingAreas/Perth"));
const OttowaPage = React.lazy(() => import("./pages/ServingAreas/Ottowa"));
const KingstonePage = React.lazy(() =>
  import("./pages/ServingAreas/Kingstone")
);
const SharbotPage = React.lazy(() => import("./pages/ServingAreas/Sharbot"));
const RebateProgram = React.lazy(() =>
  import("./pages/RebateProgram/RebateProgram")
);

const PlumbingQuotingPage = React.lazy(() =>
  import("./pages/Services/Plumping/Plumping")
);
const HeatingQuotingPage = React.lazy(() =>
  import("./pages/Services/Heating/Heating")
);

const WaterQuotingPage = React.lazy(() =>
  import("./pages/Services/WaterTreatment/Water")
);

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "DM Sans", "Poppins", "Source Sans Pro", "Inter"],
      },
    });
  }, []);

  const fallbackComponent = (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}>
      <CircularProgress />
    </div>
  );

  return (
    <StoreProvider>
      <BrowserRouter>
        <ToastContainer position='bottom-left' />
        <Layout>
          <Routes>
            <Route index element={<Home />} />
            <Route
              path='/request-quote'
              element={
                <Suspense fallback={fallbackComponent}>
                  <RequestQuote />
                </Suspense>
              }
            />
            <Route
              path='/request-quote/completed'
              element={
                <Suspense fallback={fallbackComponent}>
                  <ServiceCompleted />
                </Suspense>
              }
            />
            <Route
              path='/schedule-annual-service'
              element={
                <Suspense fallback={fallbackComponent}>
                  <ScheduleAnnualService />
                </Suspense>
              }
            />
            <Route
              path='/schedule-annual-service/completed'
              element={
                <Suspense fallback={fallbackComponent}>
                  <ServiceCompleted annual={true} />
                </Suspense>
              }
            />
            <Route
              path='privacy-policy'
              element={
                <Suspense fallback={fallbackComponent}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path='Terms-and-Conditions'
              element={
                <Suspense fallback={fallbackComponent}>
                  <TermsAndCondition />
                </Suspense>
              }
            />
            <Route
              path='shipping-policy'
              element={
                <Suspense fallback={fallbackComponent}>
                  <ShippingPolicy />
                </Suspense>
              }
            />
            <Route
              path='/about'
              element={
                <Suspense fallback={fallbackComponent}>
                  <About />
                </Suspense>
              }
            />
            <Route
              path='/RebateProgram'
              element={
                <Suspense fallback={fallbackComponent}>
                  <RebateProgram />
                </Suspense>
              }
            />
            <Route
              path='/contact'
              element={
                <Suspense fallback={fallbackComponent}>
                  <Contact />
                </Suspense>
              }
            />
            <Route path='/services'>
              <Route
                path='water-treatment'
                element={
                  <Suspense fallback={fallbackComponent}>
                    <WaterTreatment />
                  </Suspense>
                }
              />
              <Route
                path='water-purifcation'
                element={
                  <Suspense fallback={fallbackComponent}>
                    <WaterPurification />
                  </Suspense>
                }
              />
              <Route
                path='water-softner'
                element={
                  <Suspense fallback={fallbackComponent}>
                    <WaterSoftner />
                  </Suspense>
                }
              />
              <Route
                path='heating'
                element={
                  <Suspense fallback={fallbackComponent}>
                    <Hvac />
                  </Suspense>
                }
              />
              <Route
                path='Plumping'
                element={
                  <Suspense fallback={fallbackComponent}>
                    <PlumbingQuotingPage />
                  </Suspense>
                }
              />
              <Route
                path='heating-quotations'
                element={
                  <Suspense fallback={fallbackComponent}>
                    <HeatingQuotingPage />
                  </Suspense>
                }
              />
              <Route
                path='water-quotations'
                element={
                  <Suspense fallback={fallbackComponent}>
                    <WaterQuotingPage />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path='/air-condition'
              element={
                <Suspense fallback={fallbackComponent}>
                  <AirCondition />
                </Suspense>
              }
            />
            <Route
              path='/Lanark-County'
              element={
                <Suspense fallback={fallbackComponent}>
                  <PerthPage />
                </Suspense>
              }
            />
            <Route
              path='/ottowa'
              element={
                <Suspense fallback={fallbackComponent}>
                  <OttowaPage />
                </Suspense>
              }
            />
            <Route
              path='/Frontenac-County'
              element={
                <Suspense fallback={fallbackComponent}>
                  <KingstonePage />
                </Suspense>
              }
            />
            <Route
              path='/Leeds&Grenville'
              element={
                <Suspense fallback={fallbackComponent}>
                  <SharbotPage />
                </Suspense>
              }
            />

            <Route
              path='/fixtures'
              element={
                <Suspense fallback={fallbackComponent}>
                  <Fixtures />
                </Suspense>
              }
            />
            <Route
              path='/sumppump'
              element={
                <Suspense fallback={fallbackComponent}>
                  <Sumppump />
                </Suspense>
              }
            />
            <Route
              path='/plumping'
              element={
                <Suspense fallback={fallbackComponent}>
                  <Plumping />
                </Suspense>
              }
            />
            <Route path='/shop'>
              <Route
                index
                element={
                  <Suspense fallback={fallbackComponent}>
                    <Shop />
                  </Suspense>
                }
              />
              <Route
                path=':id'
                element={
                  <Suspense fallback={fallbackComponent}>
                    <ShopCategories />
                  </Suspense>
                }
              />
              <Route
                path=':id/:subcategory/*'
                element={
                  <Suspense fallback={fallbackComponent}>
                    <ShopSubCategories />
                  </Suspense>
                }
              />
            </Route>
            <Route path='/product/:id'>
              <Route
                index
                element={
                  <Suspense fallback={fallbackComponent}>
                    <ProductPage />
                  </Suspense>
                }
              />
            </Route>
            <Route index path='/cart' element={<Cart />} />
            <Route index path='/payment-success' element={<PaymentSuccess />} />
            <Route path='/wishlist' element={<Wishlist />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
