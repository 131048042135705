import { useEffect, useState } from 'react';
import './PaymentSuccess.scss';
import { Container } from '@mui/material';
import WeReceivedOrder from '../../components/Payment/WeReceivedOrder';
import PaidProducts from '../../components/Payment/PaidProducts';
import OrderTotalPaid from '../../components/Payment/OrderTotalPaid';
import {
	clearUserDataAction,
	selectUserData,
} from '../../utils/features/user';
import { useSelector } from 'react-redux';
import {
	clearCartAction,
	clearDiscountsAction,
	selectSelectedProducts,
	selectTotalCartDiscount,
	selectTotalPrice,
	selectTotalShippingDiscount,
} from '../../utils/features/cart';
import { useDispatch } from 'react-redux';
// import image47 from '../../assets/image 47.png';

const PaymentSuccess = () => {
	// const products = [
	// 	{
	// 		name: 'Kohler Simplice 1.5 GPM Single Hole Pull Down Kitchen Faucet - Includes Escutcheon',
	// 		image: image47,
	// 		color: 'Grey',
	// 		options: 'Single handle',
	// 		price: 120.6,
	// 	},
	// 	{
	// 		name: 'Kohler Simplice 1.5 GPM Single Hole Pull Down Kitchen Faucet - Includes Escutcheon',
	// 		image: image47,
	// 		color: 'Grey',
	// 		options: 'Single handle',
	// 		price: 120.6,
	// 	},
	// 	{
	// 		name: 'Kohler Simplice 1.5 GPM Single Hole Pull Down Kitchen Faucet - Includes Escutcheon',
	// 		image: image47,
	// 		color: 'Grey',
	// 		options: 'Single handle',
	// 		price: 120.6,
	// 	},
	// ];
	const dispatch = useDispatch();

	const [paymentSuccessData, setPaymentSuccessData] =
		useState({
			userData: {},
			selectedProducts: [],
			totalPrice: 0,
			totalShippingDiscount: 0,
			totalCartDiscount: 0,
		});

	const userData = useSelector(selectUserData);

	const selectedProducts = useSelector(
		selectSelectedProducts
	);

	const totalPrice = useSelector(selectTotalPrice);

	const totalShippingDiscount = useSelector(
		selectTotalShippingDiscount
	);

	const totalCartDiscount = useSelector(
		selectTotalCartDiscount
	);

	useEffect(() => {
		setPaymentSuccessData({
			userData,
			selectedProducts,
			totalPrice,
			totalShippingDiscount,
			totalCartDiscount,
		});

		dispatch(clearCartAction());
		dispatch(clearDiscountsAction());
		dispatch(clearUserDataAction());

		window.scrollTo(0, 0);
	}, []);
	return (
		<Container>
			<div className="payment-success-wrapper">
				<div className="right-side-wrapper">
					<WeReceivedOrder
						currentOrderId={
							paymentSuccessData.userData.currentOrderId
						}
					/>
					<PaidProducts
						products={paymentSuccessData.selectedProducts}
					/>
				</div>
				<OrderTotalPaid
					totalCartDiscount={
						paymentSuccessData.totalCartDiscount
					}
					totalShippingDiscount={
						paymentSuccessData.totalShippingDiscount
					}
					totalPrice={paymentSuccessData.totalPrice}
					userData={paymentSuccessData.userData}
				/>
			</div>
		</Container>
	);
};

export default PaymentSuccess;
