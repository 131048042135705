import {
	PaymentElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import {
	forwardRef,
	useImperativeHandle,
	useState,
} from 'react';

const CheckoutForm = forwardRef(
	({ clientSecret, setPaymentSuccess }, ref) => {
		const stripe = useStripe();
		const elements = useElements();
		const [message, setMessage] = useState(null);
		const [isLoading, setIsLoading] = useState(false);
		const [paymentElementState, setPaymentElementState] =
			useState({
				complete: false,
				error: null,
			});

		useImperativeHandle(ref, () => ({
			handleSubmit,
			validate: validatePaymentElement,
			getPaymentIntent,
		}));

		const getPaymentIntent = async (secret) => {
			if (stripe) {
				console.log('Calling getPaymentIntent');
				return await stripe.retrievePaymentIntent(secret);
			}
		};

		const validatePaymentElement = () => {
			return paymentElementState;
		};

		const handlePaymentElementChange = (event) => {
			setPaymentElementState({
				complete: event.complete,
				error: event.error ? event.error.message : null,
			});
		};

		const handleSubmit = async (e) => {
			e.preventDefault();

			if (!stripe || !elements) {
				// Stripe.js hasn't yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				return;
			}

			setIsLoading(true);

			const { error } = await stripe.confirmPayment({
				elements,
				redirect: 'if_required',
			});

			// This point will only be reached if there is an immediate error when
			// confirming the payment. Otherwise, your customer will be redirected to
			// your `return_url`. For some payment methods like iDEAL, your customer will
			// be redirected to an intermediate site first to authorize the payment, then
			// redirected to the `return_url`.
			if (error) {
				if (
					error.type === 'card_error' ||
					error.type === 'validation_error'
				) {
					setMessage(error.message);
				} else {
					setMessage('An unexpected error occurred.');
				}
				throw new Error(error);
			} else {
				const paymentResult =
					await stripe.retrievePaymentIntent(clientSecret);

				console.log(paymentResult);
				const { status } = paymentResult.paymentIntent;
				switch (status) {
					case 'succeeded':
						setMessage('Payment succeeded!');
						setPaymentSuccess(true);
						break;
					case 'processing':
						setMessage('Payment is processing.');
						setPaymentSuccess(false);
						break;
					case 'requires_payment_method':
						setMessage(
							'You need to provide a payment method.'
						);
						setPaymentSuccess(false);
						break;
					default:
						setMessage('Unknown payment status.');
						setPaymentSuccess(false);
				}
			}

			setIsLoading(false);
		};

		const paymentElementOptions = {
			layout: 'tabs',
		};

		return (
			<>
				<form id="payment-form">
					<PaymentElement
						id="payment-element"
						options={paymentElementOptions}
						onChange={handlePaymentElementChange}
					/>
					{/* Show any error or success messages */}
					{message && (
						<div id="payment-message">{message}</div>
					)}
				</form>
				{/* [DEV]: For demo purposes only, display dynamic payment methods annotation and integration checker */}
				{/* <div id='dpm-annotation'>
					<p>
						Payment methods are dynamically displayed based
						on customer location, order amount, and
						currency.&nbsp;
						<a
							href={dpmCheckerLink}
							target='_blank'
							rel='noopener noreferrer'
							id='dpm-integration-checker'
							style={{ color: 'blue' }}
						>
							Preview payment methods by transaction
						</a>
					</p>
				</div> */}
			</>
		);
	}
);

CheckoutForm.displayName = 'CheckoutForm';
CheckoutForm.propTypes = {
	clientSecret: PropTypes.string,
	setPaymentSuccess: PropTypes.func,
};

export default CheckoutForm;
