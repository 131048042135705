import axios from 'axios';

export const pharmaSend = axios.create({
	baseURL: import.meta.env.VITE_PUBLIC_PHARMASENDAPI_URL,
	headers: {
		Authorization: `Bearer ${
			import.meta.env.VITE_PUBLIC_PHARAMSEND_TOKEN
		}`,
	},
});
