import React from "react";
import "./SliderCart.scss";
// import satisfied from '../../assets/satisfied-person.png';

const SliderCart = ({ data }) => {
  return (
    <div className="slider-cart-wrapper">
      {/* <img className="satisfied-person" src={satisfied} alt="person" /> */}
      <div className="top-part">
        <h1>{data.name}</h1>
        <div className="rating">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none">
            <g clipPath="url(#clip0_1756_13588)">
              <path
                d="M9.05927 1.89538L10.8764 5.56395C10.9177 5.65707 10.9828 5.73773 11.065 5.79789C11.1472 5.85803 11.2437 5.8956 11.345 5.90681L15.3564 6.5011C15.4726 6.51602 15.5821 6.56369 15.6721 6.63854C15.7622 6.71337 15.8292 6.8123 15.8651 6.92374C15.9011 7.03519 15.9045 7.15457 15.8751 7.26793C15.8459 7.3813 15.7848 7.48397 15.6992 7.56395L12.8078 10.4325C12.7341 10.5015 12.6787 10.5878 12.6468 10.6836C12.6148 10.7794 12.6073 10.8817 12.6249 10.9811L13.3221 15.0153C13.3423 15.1313 13.3295 15.2505 13.2853 15.3596C13.2411 15.4686 13.1671 15.563 13.0718 15.6321C12.9766 15.7012 12.8638 15.7421 12.7464 15.7503C12.6291 15.7584 12.5117 15.7335 12.4078 15.6782L8.79641 13.7696C8.70394 13.7243 8.60228 13.7007 8.49927 13.7007C8.39625 13.7007 8.2946 13.7243 8.20212 13.7696L4.5907 15.6782C4.4868 15.7335 4.36948 15.7584 4.2521 15.7503C4.13472 15.7421 4.02198 15.7012 3.92672 15.6321C3.83145 15.563 3.75748 15.4686 3.71323 15.3596C3.66899 15.2505 3.65623 15.1313 3.67641 15.0153L4.37355 10.9354C4.39122 10.836 4.38375 10.7337 4.35182 10.6379C4.31988 10.5421 4.26449 10.4558 4.1907 10.3868L1.26498 7.56395C1.17843 7.48178 1.11756 7.37626 1.08974 7.26021C1.06193 7.14414 1.06837 7.02249 1.10828 6.91001C1.1482 6.79753 1.21987 6.69904 1.31462 6.62646C1.40937 6.55389 1.52313 6.51033 1.64212 6.5011L5.65355 5.90681C5.75481 5.8956 5.85136 5.85803 5.93358 5.79789C6.01579 5.73773 6.0808 5.65707 6.12212 5.56395L7.93927 1.89538C7.98875 1.78854 8.06777 1.69808 8.167 1.63468C8.26623 1.57128 8.38152 1.5376 8.49927 1.5376C8.61701 1.5376 8.73232 1.57128 8.83154 1.63468C8.93076 1.69808 9.00978 1.78854 9.05927 1.89538Z"
                fill="#FC7A01"
                stroke="#FC7A01"
                strokeWidth="1.14286"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1756_13588">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0.5 0.649414)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>{data.rating}</p>
        </div>
      </div>
      <p className="text">{data.text}</p>
    </div>
  );
};

export default SliderCart;
