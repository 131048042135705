import React from "react";
import "./OurPartners.scss";
import { Container, Divider } from "@mui/material";
import { motion } from "framer-motion";
import gasFurnaceImage from "../../assets/contLogo.png";
import moovairImage from "../../assets/moovair.png";
import image0 from "../../assets/berkeley.png";
import image1 from "../../assets/image (1) 1.png";
import image2 from "../../assets/image (2) 2.png";
import image3 from "../../assets/ASLogo.png";
import image4 from "../../assets/longevityLogo.png";
import image5 from "../../assets/maaxLogo.png";
import image6 from "../../assets/MoenLogo.png";
import image7 from "../../assets/purusH2O.png";

const OurPartners = ({ style, photosProp }) => {
  const photos = [
    image0,
    gasFurnaceImage,
    moovairImage,
    image2,
    image3,
    image5,
    image6,
    image4,
    image1,
    image7,
  ];
  return (
    <div className='partnersComponent' style={style || {}}>
      <Divider />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true, amount: 0.8 }}
        transition={{ ease: "easeOut", duration: 0.4 }}
        className='our-partners-wrapper'>
        <h1>Our Trusted Partners</h1>
        <div className='logos'>
          {photosProp
            ? photosProp.map((photo, index) => (
                <img src={photo} alt='brand' key={index} />
              ))
            : photos.map((photo, index) => (
                <img src={photo} alt='brand' key={index} />
              ))}
        </div>
      </motion.div>
      <Divider />
    </div>
  );
};

export default OurPartners;
