import React from "react";
import "./WhyValley.scss";
import { Container, Divider, useMediaQuery } from "@mui/material";
import PuzzleCart from "./PuzzleCart";
import { motion } from "framer-motion";
import subtract2 from "../../assets/Subtract2.png";
import subtract8 from "../../assets/Subtract (8).png";
const WhyValley = () => {
  const puzzleCarts = [
    {
      name: "Live customer service",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none">
          <g clipPath="url(#clip0_106_3168)">
            <path
              d="M23.1422 14.3638V18.6496C23.1422 18.8769 23.0518 19.0949 22.8912 19.2557C22.7304 19.4164 22.5123 19.5067 22.285 19.5067H1.71359C1.48626 19.5067 1.26824 19.4164 1.1075 19.2557C0.94675 19.0949 0.856445 18.8769 0.856445 18.6496V4.93527C0.856445 4.70794 0.94675 4.48991 1.1075 4.32918C1.26824 4.16843 1.48626 4.07812 1.71359 4.07812H5.14216"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.2851 19.5068L8.5708 23.7925"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.7134 19.5068L15.4277 23.7925"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.85645 23.7925H17.1422"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.42773 10.0783H14.5706V13.5068L19.7134 10.0783H23.142V1.50684H9.42773V10.0783Z"
              fill="#008FD5"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_106_3168">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.649414)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      name: "35+ years of expertise",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none">
          <g clipPath="url(#clip0_106_3181)">
            <path
              d="M22.988 6.81465C22.9549 6.63501 22.8845 6.46434 22.7811 6.3137C22.6777 6.16309 22.5439 6.03589 22.388 5.94037L18.9595 9.36894C18.7995 9.53485 18.6079 9.66683 18.3958 9.75697C18.1837 9.84711 17.9556 9.89356 17.7252 9.89356C17.4948 9.89356 17.2668 9.84711 17.0546 9.75697C16.8426 9.66683 16.6508 9.53485 16.4909 9.36894L15.1881 8.20323C14.874 7.88277 14.698 7.45194 14.698 7.00322C14.698 6.55451 14.874 6.12368 15.1881 5.80322L18.6166 2.37465C18.5419 2.199 18.4294 2.04193 18.2873 1.91455C18.145 1.78717 17.9767 1.69259 17.7937 1.63751C16.5975 1.39895 15.3583 1.50107 14.2172 1.93224C13.0761 2.36341 12.079 3.10629 11.3394 4.07634C10.5998 5.04638 10.1475 6.20461 10.0338 7.41913C9.92738 8.55683 10.1222 9.70112 10.5967 10.7376L1.39664 19.9376C0.717489 20.6168 0.728752 21.7213 1.42161 22.3865L2.38295 23.3095C3.05347 23.9532 4.11487 23.9453 4.77571 23.2915L14.0814 14.0866C15.0879 14.5022 16.1843 14.6604 17.2707 14.5439C18.4719 14.4151 19.614 13.9557 20.5697 13.2166C21.5256 12.4776 22.2576 11.488 22.6844 10.3577C23.1115 9.22751 23.2165 8.00102 22.988 6.81465Z"
              fill="#008FD5"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_106_3181">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.649414)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      name: "Same-day after-hours emergency services",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none">
          <g clipPath="url(#clip0_106_3190)">
            <path
              d="M14.5708 10.0781H19.7137C20.6229 10.0781 21.495 10.4393 22.138 11.0823C22.781 11.7253 23.1422 12.5974 23.1422 13.5067V20.3638H20.5708"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.57073 20.3637H0.856445V8.3637C0.856445 7.90904 1.03706 7.47301 1.35855 7.15151C1.68004 6.83003 2.11607 6.64941 2.57073 6.64941H12.8564C13.3111 6.64941 13.7471 6.83003 14.0686 7.15151C14.3901 7.47301 14.5707 7.90904 14.5707 8.3637V20.3637"
              fill="#008FD5"
            />
            <path
              d="M2.57073 20.3637H0.856445V8.3637C0.856445 7.90904 1.03706 7.47301 1.35855 7.15151C1.68004 6.83003 2.11607 6.64941 2.57073 6.64941H12.8564C13.3111 6.64941 13.7471 6.83003 14.0686 7.15151C14.3901 7.47301 14.5707 7.90904 14.5707 8.3637V20.3637"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M13.7134 20.3638H9.42773H13.7134Z" fill="#008FD5" />
            <path
              d="M13.7134 20.3638H9.42773"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5708 23.7925C15.9139 23.7925 14.5708 22.4493 14.5708 20.7925C14.5708 19.1356 15.9139 17.7925 17.5708 17.7925C19.2277 17.7925 20.5708 19.1356 20.5708 20.7925C20.5708 22.4493 19.2277 23.7925 17.5708 23.7925Z"
              fill="#008FD5"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.99951 23.7925C4.34265 23.7925 2.99951 22.4493 2.99951 20.7925C2.99951 19.1356 4.34265 17.7925 5.99951 17.7925C7.65637 17.7925 8.99951 19.1356 8.99951 20.7925C8.99951 22.4493 7.65637 23.7925 5.99951 23.7925Z"
              fill="#008FD5"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_106_3190">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.649414)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      name: "4000 annual service customers",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none">
          <g clipPath="url(#clip0_106_3203)">
            <path
              d="M8.57087 11.7921C10.9378 11.7921 12.8566 9.87335 12.8566 7.50642C12.8566 5.13949 10.9378 3.2207 8.57087 3.2207C6.20394 3.2207 4.28516 5.13949 4.28516 7.50642C4.28516 9.87335 6.20394 11.7921 8.57087 11.7921Z"
              fill="#008FD5"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M0.856445 23.7919H16.285V22.8626C16.2714 21.556 15.9268 20.274 15.2834 19.1368C14.64 17.9993 13.7189 17.0436 12.606 16.3588C11.4932 15.6739 10.2249 15.2823 8.91962 15.2205C8.80326 15.215 8.68692 15.2122 8.57073 15.2119C8.45454 15.2122 8.33821 15.215 8.22184 15.2205C6.9166 15.2823 5.6483 15.6739 4.53542 16.3588C3.42256 17.0436 2.50142 17.9993 1.85805 19.1368C1.21469 20.274 0.870093 21.556 0.856445 22.8626V23.7919Z"
              fill="#008FD5"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.4277 11.7921C17.7946 11.7921 19.7134 9.87335 19.7134 7.50642C19.7134 5.13949 17.7946 3.2207 15.4277 3.2207"
              fill="#008FD5"
            />
            <path
              d="M15.4277 11.7921C17.7946 11.7921 19.7134 9.87335 19.7134 7.50642C19.7134 5.13949 17.7946 3.2207 15.4277 3.2207"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.7135 23.7921H23.1421V22.8626C23.1284 21.556 22.7838 20.274 22.1404 19.1368C21.4971 17.9993 20.576 17.0437 19.4631 16.3588C18.7437 15.9161 17.9593 15.596 17.1421 15.4082"
              fill="#008FD5"
            />
            <path
              d="M19.7135 23.7921H23.1421V22.8626C23.1284 21.556 22.7838 20.274 22.1404 19.1368C21.4971 17.9993 20.576 17.0437 19.4631 16.3588C18.7437 15.9161 17.9593 15.596 17.1421 15.4082"
              stroke="#008FD5"
              strokeWidth="1.71429"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_106_3203">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0 0.649414)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  ];
  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <Container>
      <Divider
        sx={{ paddingTop: "24px !important", paddingBottom: "24px !important" }}
      />
      <div className="why-walley-wrapper">
        <div className="text-box mobile">
          <div>
            <p className="blue">Features</p>
            <h1>Why Valley Plumbing, Heating & Water Treatment?</h1>
          </div>
        </div>
        <div className="left-side-image">
          <img src={!matches ? subtract2 : subtract8} alt="pumps" />
          <div className="text-box">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ ease: "easeOut", duration: 0.4 }}>
              <p className="blue">Features</p>
              <h1>Why Valley Plumbing, Heating & Water Treatment?</h1>
            </motion.div>
          </div>
        </div>
        <div className="right-side-wrapper">
          {puzzleCarts.map((puzzleCart, index) => (
            <PuzzleCart key={index} data={puzzleCart} />
          ))}
        </div>
      </div>
      <Divider
        sx={{ paddingTop: "24px !important", paddingBottom: "24px !important" }}
      />
    </Container>
  );
};

export default WhyValley;
