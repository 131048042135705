import Accordion from '@/components/Accordion/Accordion';
import './Delivery.scss';
import {
	MenuItem,
	Select,
	TextField,
	useMediaQuery,
} from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { SlLocationPin } from 'react-icons/sl';
import { State } from 'country-state-city';
import {
	CiDeliveryTruck,
	CiLocationOn,
	CiMail,
} from 'react-icons/ci';
import { FaStore } from 'react-icons/fa';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { PiBuildingOffice, PiPhone } from 'react-icons/pi';
import TelInput from '@/components/TelInput/TelInput';
import PropTypes from 'prop-types';

const DeliveryStep = forwardRef(
	(
		{
			data,
			handleChange,
			handleNextButton,
			handleStepsStatusChange,
			handleDeliveryOptionChange,
			StepsCompletion,
		},
		DeliveryRef
	) => {
		const [billingOption, setBillingOption] =
			useState('same');
		const isMobile = useMediaQuery('(max-width:850px)');
		const [shippingCountryList, setShippingCountryList] =
			useState([]);
		const [billingCountryList, setBillingCountryList] =
			useState([]);

		// Fetch states for shipping country
		useEffect(() => {
			const getStateList = () => {
				const list = State.getStatesOfCountry(data.country);
				setShippingCountryList(list);
			};
			getStateList();
		}, [data.country]);

		useEffect(() => {
			if (billingOption === 'different') {
				const getBillingStateList = () => {
					const list = State.getStatesOfCountry(
						data.billingCountry
					);
					setBillingCountryList(list);
				};
				getBillingStateList();
			}
			handleChange({
				target: {
					name: 'isSameAsShipping',
					value: billingOption === 'same',
				},
			});
		}, [data.billingCountry, billingOption]);

		const validateDeliveryForm = () => {
			if (data.deliveryOption === 'pickup') {
				return true;
			} else {
				const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex

				const isShippingValid =
					data.deliveryOption === 'shipping' &&
					data.firstName &&
					data.lastName &&
					emailRegex.test(data.email) &&
					data.phone &&
					((data.profileType === 'buisness' &&
						data.company) ||
						data.profileType === 'personal') &&
					data.country &&
					data.province &&
					data.city &&
					data.address &&
					data.zipCode;

				const isBillingValid =
					billingOption === 'different' &&
					data.billingFirstName &&
					data.billingLastName &&
					emailRegex.test(data.billingEmail) &&
					data.billingPhone &&
					data.billingCountry &&
					data.billingProvince &&
					data.billingCity &&
					data.billingAddress &&
					data.billingZipCode;

				// Return true only if both shipping and billing validations are satisfied
				return (
					isShippingValid &&
					(billingOption === 'same' || isBillingValid)
				);
			}
		};

		return (
			<div className="checkoutStep Delivery Step">
				<Accordion
					currentStep="delivery"
					data={data}
					handleNextButton={handleNextButton}
					step={1}
					title="Delivery"
					validate={validateDeliveryForm}
					ref={DeliveryRef}
					handleStepsStatusChange={handleStepsStatusChange}
					StepsCompletion={StepsCompletion}
				>
					<div className="radio-button-group deliveryGroup">
						<div
							className={`radio-button ${
								data.deliveryOption === 'pickup'
									? 'selected'
									: ''
							}`}
							onClick={() =>
								handleDeliveryOptionChange('pickup')
							}
						>
							<div className="parent">
								<div className="radio-circle">
									{data.deliveryOption === 'pickup' && (
										<img
											src={'/icon/check-orange.svg'}
											alt=""
										/>
									)}
								</div>
								<div className="radio-content">
									<div>
										<div className="radio-title">
											<div>In-Store Pick-up</div>
											{!isMobile && (
												<div className="radio-location">
													<SlLocationPin /> 15749 Highway 7,
													Perth, ON
												</div>
											)}
										</div>
										<div className="radio-description">
											Go for picking up your order from our
											store location.
										</div>
									</div>
									<div className="radio-cost">
										<span>Free</span>
									</div>
								</div>
							</div>
							{isMobile && (
								<div className="radio-location">
									<SlLocationPin /> 15749 Highway 7, Perth,
									ON
								</div>
							)}
						</div>
						<div
							className={`radio-button v2 ${
								data.deliveryOption === 'shipping'
									? 'selected'
									: ''
							}`}
							onClick={() =>
								handleDeliveryOptionChange('shipping')
							}
						>
							<div className="radio-circle">
								{data.deliveryOption === 'shipping' && (
									<img
										src={'/icon/check-orange.svg'}
										alt=""
									/>
								)}
							</div>
							<div className="radio-content">
								<div className="radio-label">Shipping</div>
								<div className="radio-description">
									Get your delivery directly to your
									doorstep.
								</div>
							</div>
						</div>
					</div>

					{data.deliveryOption === 'shipping' && (
						<>
							<div className="InputGroup main">
								<TextField
									required
									label="First Name"
									name="firstName"
									error={data.firstName === ''}
									helperText={
										data.firstName === ''
											? 'First name is required'
											: ''
									}
									onChange={(e) => handleChange(e)}
								/>
								<TextField
									required
									label="Last Name"
									name="lastName"
									error={data.lastName === ''}
									helperText={
										data.lastName === ''
											? 'Last name is required'
											: ''
									}
									onChange={(e) => handleChange(e)}
								/>
								<TextField
									required
									label="Email Address"
									name="email"
									error={data.email === ''}
									helperText={
										data.email === ''
											? 'Valid Email is required'
											: ''
									}
									onChange={(e) => handleChange(e)}
								/>
								<TelInput
									data={data}
									handleChange={handleChange}
									placeholder={'Phone'}
									name={'phone'}
								/>
								{data.profileType === 'buisness' && (
									<TextField
										required
										label="Company Name"
										name="company"
										error={data.company === ''}
										helperText={
											data.company === ''
												? 'Company Name is required'
												: ''
										}
										onChange={(e) => handleChange(e)}
									/>
								)}
								{/* <TextField
                  required
                  label="Phone Number"
                  name="phone"
                  error={data.phone === ""}
                  helperText={
                    data.phone === "" ? "Valid Phone number is required" : ""
                  }
                  onChange={(e) => handleChange(e)}
                /> */}
								<Select
									required
									name="country"
									value={data.country || ''}
									onChange={(e) => handleChange(e)}
									displayEmpty
								>
									<MenuItem value="" disabled>
										<em>Country/Region</em>
									</MenuItem>
									<MenuItem value="US">US</MenuItem>
									<MenuItem value="CA">Canada</MenuItem>
								</Select>
								<Select
									required
									name="province"
									value={data.province || ''}
									onChange={(e) => handleChange(e)}
									displayEmpty
								>
									<MenuItem value="" disabled>
										<em>Province</em>
									</MenuItem>
									{shippingCountryList.map((state) => (
										<MenuItem
											key={state.isoCode}
											value={state.isoCode}
										>
											{state.name}
										</MenuItem>
									))}
								</Select>
								<TextField
									required
									label="Town/City"
									name="city"
									error={data.city === ''}
									helperText={
										data.city === ''
											? 'Town/City is required'
											: ''
									}
									onChange={(e) => handleChange(e)}
								/>
								<TextField
									required
									label="Street Address"
									name="address"
									error={data.address === ''}
									helperText={
										data.address === ''
											? 'Street address is required'
											: ''
									}
									onChange={(e) => handleChange(e)}
								/>
								<TextField
									required
									label="Zip Code"
									name="zipCode"
									error={data.zipCode === ''}
									helperText={
										data.zipCode === ''
											? 'Zip Code is required'
											: ''
									}
									onChange={(e) => handleChange(e)}
								/>
							</div>

							{/* Billing Address Section */}
							<div id="addresspref">
								<div className="addressprefTitle">
									Billing Address
								</div>
								<div
									className={`addressChoice ${
										billingOption === 'same'
											? 'selected'
											: ''
									}`}
									onClick={() => setBillingOption('same')}
								>
									<div className="radio-circle">
										{billingOption === 'same' && (
											<img
												src={'/icon/check-orange.svg'}
												alt=""
											/>
										)}
									</div>
									<div className="addressOption">
										Same as Shipping Address
									</div>
								</div>
								<div
									className={`addressChoice ${
										billingOption === 'different'
											? 'selected'
											: ''
									}`}
									onClick={() =>
										setBillingOption('different')
									}
								>
									<div className="radio-circle">
										{billingOption === 'different' && (
											<img
												src={'/icon/check-orange.svg'}
												alt=""
											/>
										)}
									</div>
									<div className="addressOption">
										Use a Different Address
									</div>
								</div>
							</div>

							{billingOption === 'different' && (
								<div className="InputGroup">
									<TextField
										required
										label="First Name"
										name="billingFirstName"
										error={data.billingFirstName === ''}
										helperText={
											data.billingFirstName === ''
												? 'First name is required'
												: ''
										}
										onChange={(e) => handleChange(e)}
									/>
									<TextField
										required
										label="Last Name"
										name="billingLastName"
										error={data.billingLastName === ''}
										helperText={
											data.billingLastName === ''
												? 'Last name is required'
												: ''
										}
										onChange={(e) => handleChange(e)}
									/>
									<TextField
										required
										label="Email Address"
										name="billingEmail"
										error={data.billingEmail === ''}
										helperText={
											data.billingEmail === ''
												? 'Valid Email is required'
												: ''
										}
										onChange={(e) => handleChange(e)}
									/>

									<TelInput
										data={data}
										handleChange={handleChange}
										placeholder={'Billing Phone'}
										name={'billingPhone'}
									/>
									<Select
										required
										name="billingCountry"
										value={data.billingCountry}
										onChange={(e) => handleChange(e)}
										displayEmpty
									>
										<MenuItem value="" disabled>
											<em>Country/Region</em>
										</MenuItem>
										<MenuItem value="US">US</MenuItem>
										<MenuItem value="CA">Canada</MenuItem>
									</Select>
									<Select
										required
										name="billingProvince"
										value={data.billingProvince}
										onChange={(e) => handleChange(e)}
										displayEmpty
									>
										<MenuItem value="" disabled>
											<em>Province</em>
										</MenuItem>
										{billingCountryList.map((state) => (
											<MenuItem
												key={state.isoCode}
												value={state.isoCode}
											>
												{state.name}
											</MenuItem>
										))}
									</Select>
									<TextField
										required
										label="Town/City"
										name="billingCity"
										error={data.billingCity === ''}
										helperText={
											data.billingCity === ''
												? 'Town/City is required'
												: ''
										}
										onChange={(e) => handleChange(e)}
									/>
									<TextField
										required
										label="Street Address"
										name="billingAddress"
										error={data.billingAddress === ''}
										helperText={
											data.billingAddress === ''
												? 'Street address is required'
												: ''
										}
										onChange={(e) => handleChange(e)}
									/>
									<TextField
										required
										label="Zip Code"
										name="billingZipCode"
										error={data.billingZipCode === ''}
										helperText={
											data.billingZipCode === ''
												? 'Zip Code is required'
												: ''
										}
										onChange={(e) => handleChange(e)}
									/>
								</div>
							)}
						</>
					)}
				</Accordion>
				{StepsCompletion.delivery && (
					<div className="confirmation deliveryConfirmation">
						<div className="section">
							<div className="title">Method</div>
							<div className="choice">
								{data.deliveryOption === 'pickup' ? (
									<>
										<FaStore />
										In Store Pick Up
									</>
								) : (
									<>
										<CiDeliveryTruck className="icon" />
										Delivery
									</>
								)}
							</div>
						</div>

						{data.deliveryOption === 'shipping' && (
							<>
								<div className="section">
									<div className="title">
										{' '}
										SHIPPING ADDRESS
									</div>
									<div className="data">
										<div>
											<div className="choice">
												<IoPersonCircleOutline className="icon" />
												{data?.firstName} {data?.lastName}
											</div>
											<div className="choice">
												<PiPhone className="icon" />
												{data?.phone}
											</div>
										</div>
										<div>
											<div className="choice">
												<CiMail className="icon" />
												{data?.email}
											</div>
											<div className="choice">
												<CiLocationOn className="icon" />
												{data?.country}, {data?.province}
											</div>
										</div>
										{data.profileType === 'buisness' && (
											<div>
												<div className="choice">
													<PiBuildingOffice className="icon" />
													{data?.company}
												</div>
											</div>
										)}
									</div>
								</div>

								{billingOption !== 'same' && (
									<>
										<div className="section">
											<div className="title">
												{' '}
												BILLING ADDRESS
											</div>
											<div className="data">
												<div>
													<div className="choice">
														<IoPersonCircleOutline className="icon" />
														{data.billingFirstName}{' '}
														{data.billingLastName}
													</div>
													<div className="choice">
														<PiPhone className="icon" />
														{data.billingPhone}
													</div>
												</div>
												<div>
													<div className="choice">
														<CiMail className="icon" />
														{data.billingEmail}
													</div>
													<div className="choice">
														<CiLocationOn className="icon" />
														{data.billingCountry},{' '}
														{data.billingProvince}
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</>
						)}
					</div>
				)}
			</div>
		);
	}
);

DeliveryStep.propTypes = {
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleNextButton: PropTypes.func.isRequired,
	handleStepsStatusChange: PropTypes.func.isRequired,
	handleDeliveryOptionChange: PropTypes.func.isRequired,
	StepsCompletion: PropTypes.object.isRequired,
};

DeliveryStep.displayName = 'DeliveryStep';

export default DeliveryStep;
