'use client';

import React from 'react';
import './TelInput.scss';
import { MuiTelInput } from 'mui-tel-input';

const TelInput = ({ handleChange, data, name,placeholder }) => {
  return (
    <MuiTelInput
    error={data?.[name] === ""}
    helperText={
      data?.[name] === "" ? "Valid Phone number is required" : ""
    }
    
      className="mui-tel-input"
      value={data?.[name] || ''}
      onChange={(newValue) =>
        handleChange({
          target: {
            name: name,
            value: newValue,
          },
        })
      }
      placeholder={placeholder}
      defaultCountry="CA"
    />
  );
};

export default TelInput;
