import Accordion from '@/components/Accordion/Accordion';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../CheckoutForm/CheckoutForm';
import { forwardRef, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CustomLoader from '../../../Shared/CustomLoader/CustomLoader';
import { CustomLoaderType } from '@/utils/constants';

const PaymentStep = forwardRef(
	(
		{
			data,
			handleNextButton,
			handleStepsStatusChange,
			StepsCompletion,
			clientSecret,
			options,
			stripePromise,
		},
		PaymentRef
	) => {
		const checkOutRef = useRef(null);
		const [paymentSuccess, setPaymentSuccess] =
			useState(false);

		const validatePaymentForm = () => {
			if (checkOutRef.current) {
				console.log(checkOutRef.current.validate());
				const { complete, error } =
					checkOutRef.current.validate();
				return complete && !error;
			}

			return false;
		};

		const handleSubmit = async (e) => {
			if (checkOutRef.current) {
				await checkOutRef.current.handleSubmit(e);
			}
		};
		const getPaymentIntent = async (e) => {
			if (checkOutRef.current) {
				return await checkOutRef.current.getPaymentIntent(
					e
				);
			}
		};

		if (PaymentRef.current) {
			PaymentRef.current.handleSubmit = handleSubmit;
			PaymentRef.current.getPaymentIntent =
				getPaymentIntent;
		}

		return (
			<div className="Payment Step">
				<Accordion
					buttonText="Confirm Payment Info"
					currentStep="payment"
					handleNextButton={handleNextButton}
					step={3}
					data={data}
					title="Payment Method"
					validate={validatePaymentForm}
					ref={PaymentRef}
					handleStepsStatusChange={handleStepsStatusChange}
					StepsCompletion={StepsCompletion}
				>
					{clientSecret ? (
						<Elements
							options={options}
							stripe={stripePromise}
						>
							<CheckoutForm
								ref={checkOutRef}
								setPaymentSuccess={setPaymentSuccess}
								clientSecret={clientSecret}
							/>
						</Elements>
					) : (
						<CustomLoader type={CustomLoaderType.SMALL} />
					)}
				</Accordion>
			</div>
		);
	}
);

PaymentStep.displayName = 'PaymentStep';
PaymentStep.propTypes = {
	data: PropTypes.object,
	handleNextButton: PropTypes.func,
	handleStepsStatusChange: PropTypes.func,
	StepsCompletion: PropTypes.object,
	clientSecret: PropTypes.string,
	options: PropTypes.object,
	stripePromise: PropTypes.object,
};

export default PaymentStep;
