import CartPaidProducts from './CartProducts';
import PropTypes from 'prop-types';

const PaidProducts = ({ products }) => {
	return (
		<div className="paid-products-wrapper">
			{products.map((product, index) => (
				<CartPaidProducts
					cartProduct={product}
					key={index}
				/>
			))}
		</div>
	);
};

PaidProducts.propTypes = {
	products: PropTypes.array,
};

export default PaidProducts;
