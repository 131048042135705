import React, { useEffect, useState } from "react";
import "./SatisfiedClients.scss";
import { Container, useMediaQuery } from "@mui/material";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import SwiperNavButton from "../SwiperNavButton";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderCart from "./SliderCart";
import { fetchReviews } from "../../utils/reviews";

const SatisfiedClients = () => {
  const [reviews, setReviews] = useState([]);

  const carts = [
    {
      name: "Joe Moore",
      text: "We are very happy with our new CitySoft water conditioner product by Viqua. No more chlorine odour plus the additional softening made a drastic improvement to the Town water. I was also pleased with the quality of Curtis and Matt's installation.....neat, tidy, and pro workmanship. Great coordination from Angela too at the office.",
      rating: 5,
    },
    {
      name: "Don Rideout",
      text: "We just moved to the Perth area after living in Orleans for 27+ years. New to the rural area and we've had questions and concerns about our water system since day 1. Graham came out to the house today to check everything out and gave it a passing grade. The nice part was there was no sales pitch, no push to buy something we didn't need, only a few suggestions moving forward. Thanks!",
      rating: 5,
    },
    {
      name: "Name Goeshere",
      text: "We have been using Valley for about 3 years for plumbing and heating. Reliable, respectful on appointment times, fair pricing and friendly employees. Just had a new furnace installed yesterday and am pleased with the heat I am enjoying as I write this review. Professional install. No overpriced add-ons or pushy upsells, just solid products installed right. These guys know what they are doing.  Thanks everyone involved.",
      rating: 5,
    },
    {
      name: "Jessica Smith",
      text: "Excellent customer service! We were new homeowners when a pipe blew on our pressure tank last year. They were able to show up within a couple hours and get us back up and running. Ever since then, every problem or even any questions we had they have been amazing to work with. Very patient, and always felt like we were well informed before we made any decisions. Very good company and has definitely made our year less stressful. Thank you!",
      rating: 5,
    },
    {
      name: "Richard Berthelot & Margot Haug",
      text: "Wow; finally, a company that answers their phone, makes appointments to arrive in quick time, comes when they say, charges reasonable rates, and provides competent, friendly service. Thank you, Angela, for giving us a wealth of information on water treatment systems, and thank you, Curtis, for being so competent and informative.",
      rating: 4.8,
    },
    {
      name: "Moran Ken",
      text: "I would like to express my appreciation concerning my service call this past Monday. The technician, (Ross), was knowledgeable, thorough, and informative. I was impressed with how he took the time to answer and explain my questions. When calling the office, the staff (Angela and Curtis) were very pleasant and helpful. A five-star experience all around. Thank you all very much.",
      rating: 4.8,
    },
    {
      name: "Jamie O’Connor",
      text: "I wish to thank you for your quick response time when I had plumbing problems. The technician was so professional, and I felt he went beyond to make sure the plumbing problem was fixed. I will certainly recommend your company whenever I can.",
      rating: 4.8,
    },
    {
      name: "Don Rideout",
      text: "We just moved to the Perth area after living in Orleans for 27+ years. New to the rural area, and we've had questions and concerns about our water system since day 1. Graham came out to the house today to check everything out and gave it a passing grade. The nice part was there was no sales pitch, no push to buy something we didn't need, only a few suggestions moving forward. Thanks!",
      rating: 4.8,
    },
    {
      name: "Abby Locke",
      text: "Valley installed a reverse osmosis water system at our photography studio. So happy that our staff and clients can drink clean water without using plastic water bottles. They installed it right under our sink and took less than an hour, including the installation of the filtered water faucet.",
      rating: 4.8,
    },
    {
      name: "Mike Jeays",
      text: "Excellent service - new well pump installed within 3 hours of the call.",
      rating: 4.8,
    },
    {
      name: "Brodie B.",
      text: "I had the pleasure of getting excellent help by the team at Valley Plumbing. Started with the purchase of our home and needing servicing done to the water system that was here. Graham helped with loads of information and tuning up the system. After servicing, a year later I needed help with the water softener and Graham was able to come up and help while giving great advice about needing to change the old water softener system. I ended up getting the new one installed today by Iain and Nic, they provide amazing service and information. Angela provide me with great service helping me with payment, information, and setting it all up. Thank you Graham, Angela, Iain, and Nic.",
      rating: 4.8,
    },
    {
      name: "Pascal Zois",
      text: "Got a Reverse Osmosis System from Valley to remove Fluoride from our tap water. Can already feel a health difference. They also installed a city soft water softener and chlorine removal system because we had Hard Water. We wanted an extra bathroom added to our house so they did the plumbing and shower/fixture installation. They have great customer service and were able to service us quickly.",
      rating: 4.8,
    },
    {
      name: "Cathy Morgan",
      text: "I just wanted to give a huge shout out to Connor and Keir for their incredible help. They came out on such short notice to fix my plumbing issues, and I’m so grateful. Both of them were professional, patient, and took the time to answer all my questions, which made the whole experience less stressful. I also want to extend a big thank you to Jordan Drynan for setting everything up so quickly. Jordan provided step-by-step details on how I should address the problem, which was extremely helpful. Thanks again to everyone for your outstanding support and swift action. It made a huge difference!",
      rating: 5,
    },
    {
      name: "Stephen Abbott",
      text: "Friendly, fast, understanding,  and honest.   Need I say more.",
      rating: 5,
    },
    {
      name: "Cole Jordan",
      text: "Good customer service Al's. Awesome place to Work!",
      rating: 5,
    },
    {
      name: "John Miotte",
      text: "Excellent work very fast and efficient",
      rating: 5,
    },
    {
      name: "Erica Zwicker",
      text: "Thanks for all your help! They always respond quickly to our calls. Fast, friendly service.",
      rating: 5,
    },
    {
      name: "Mike Jeays",
      text: "Excellent service - new well pump installed within 3 hours of call.",
      rating: 5,
    },
    {
      name: "Kayla McCallum",
      text: "Had a very pleasant experience with Harrison. Very knowledgeable and helpful. Will be recommending this business to many. Thank you Harrison!",
      rating: 5,
    },
    {
      name: "mel",
      text: "I was EXTREMLY pleased with both the call handeling and the prompt service. The techs did the necessary replacement very quickly and the job was well done. Certainly have my recommendation based on this one call.",
      rating: 5,
    },
    {
      name: "Burwash Electric",
      text: "Valley plumbing and water treatment provided fast reliable service from the moment we reached out to them for a water test and Solution to our hard water. Great service complimented by good quality product and installs.",
      rating: 5,
    },
  ];

  useEffect(() => {
    const getReviews = async () => {
      const googleReviews = await fetchReviews();
      setReviews([...carts, ...googleReviews]); // Merge arrays
    };

    getReviews();
  }, []);

  const handleReview = () => {
    window.open(
      "https://www.google.com/maps/place/Valley+Plumbing,+Heating+%26+Water+Treatment/@44.9691733,-76.231041,17z/data=!4m8!3m7!1s0x4cd271a0b11cd089:0x6017ba108c0236fc!8m2!3d44.9691695!4d-76.2284661!9m1!1b1!16s%2Fg%2F11bc8cyzdk?entry=ttu",
      "_blank"
    );
  };

  const matches = useMediaQuery("(max-width:1200px)");
  return (
    <div className='satisfied-clients-wrapper'>
      <Container>
        <Swiper
          slidesPerView={matches ? 1.1 : 2.5}
          spaceBetween={matches ? 20 : 50}
          modules={[Navigation, FreeMode, Pagination]}
          navigation={true}
          className={`mySwiper`}
          style={{ paddingTop: "160px" }}
          freeMode={true}
          speed={800}>
          <div className='top-section'>
            <div className='top-buttons'>
              <div className='header'>
                <p>Testimonials</p>
                <h1>Hear It From Our Satisfied Clients</h1>
                <button className='white-button' onClick={handleReview}>
                  Write a Review
                </button>
              </div>
              <div className='nav-buttons'>
                <SwiperNavButton />
              </div>
            </div>
          </div>
          {reviews.map((cart, index) => (
            <SwiperSlide key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "24px",
                  width: "100%",
                  background: "#F2F0EE",
                }}>
                <SliderCart data={cart} />
              </div>
            </SwiperSlide>
          ))}
          {matches && (
            <div className='nav-buttons'>
              <SwiperNavButton whiteButtons={true} />
            </div>
          )}
        </Swiper>
      </Container>
    </div>
  );
};

export default SatisfiedClients;
