import React from 'react';
import './PuzzleCart.scss'

const PuzzleCart = ({data, slider}) => {
    return (
        <div className={`puzzle-cart ${slider && 'slider'}`}>
            {data?.name && <h3>{data.name}</h3>}
            {data.details?.toll_free &&
                <div style={{width:"100%", display:"flex", alignItems:"center", flexDirection:"column"}}>
                    <h3>Toll-Free</h3>
                    <p>{data.details.toll_free}</p>
                </div>
            }
            {data.details?.emergency &&
                <div style={{width:"100%", display:"flex", alignItems:"center", flexDirection:"column"}}>
                    <h3>Emergency</h3>
                    <p>{data.details.emergency}</p>
                </div>
            }
            {data.details?.email &&
                <div style={{width:"100%", display:"flex", alignItems:"center", flexDirection:"column"}}>
                    <h3>E-mail</h3>
                    <p>{data.details.email}</p>
                </div>
            }
            {data.details?.address &&
                <div style={{width:"100%", display:"flex", alignItems:"center", flexDirection:"column"}}>
                    <h3>Address</h3>
                    <p>{data.details.address}</p>
                </div>
            }
            {data.details?.office_hours &&
                <div style={{width:"100%", display:"flex", alignItems:"center", flexDirection:"column"}}>
                    <h3>Office Hours</h3>
                    <p>{data.details.office_hours}</p>
                </div>
            }
            {data.details?.extra && <p className='extra'>{data.details.extra}</p>}
            {data.svg}
        </div>
    );
};

export default PuzzleCart;