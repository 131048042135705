import React from "react";
import "./Services.scss";
import { Container, Divider } from "@mui/material";
import Service from "./Service";
import background4 from "../../assets/background4.png";
import background5 from "../../assets/background5.png";
import background6 from "../../assets/background6.png";

const Services = () => {
  const services = [
    {
      name: "Plumbing",
      backgroundImage: background4,
      topColor: "#D3D3D0",
      path: "/Plumping",
    },
    {
      name: "Heating",
      backgroundImage: background5,
      topColor: "#FC7A01",
      path: "/services/heating",
    },
    {
      name: "Water Treatment",
      backgroundImage: background6,
      topColor: "#008FD5",
      path: "/services/water-quotations",
    },
  ];

  return (
    <Container>
      <div className='services-wrapper'>
        {services.map((service, index) => (
          <Service key={index} data={service} />
        ))}
      </div>
      <Divider
        sx={{ paddingTop: "24px !important", paddingBottom: "24px !important" }}
      />
    </Container>
  );
};

export default Services;
