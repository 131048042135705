import React from 'react';
import { Container, useMediaQuery } from '@mui/material';
import './Renovating.scss';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import subrract1 from '../../assets/Subtractdreamhome.webp';
import subrract2 from '../../assets/Subtract (7).webp';

const Renovating = () => {
	const matches = useMediaQuery('(max-width:1200px)');

	const navigate = useNavigate();

	return (
		<Container
			sx={{
				maxWidth: '1352px !important',
				paddingX: {
					xs: '20px !important',
					lg: '0px !important',
				},
			}}
		>
			<div className="renovating-wrapper">
				<div className="image-part">
					<motion.img
						initial={{ opacity: 0, x: !matches ? 250 : 0 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true, amount: 0.8 }}
						transition={{ ease: 'easeOut', duration: 0.3 }}
						src={!matches ? subrract1 : subrract2}
						alt="Dream Home"
					/>
					{matches && (
						<button
							onClick={() => navigate('/request-quote')}
							className="light-blue-button"
						>
							Get A Quote
						</button>
					)}
				</div>
				<motion.div
					initial={{ opacity: 0 }}
					whileInView={{ opacity: 1 }}
					viewport={{ once: true, amount: 0.8 }}
					transition={{
						ease: 'easeOut',
						duration: 0.4,
						delay: 0.3,
					}}
					className="right-side-info"
				>
					<div className="top-part">
						<div>
							<p className="blue">Services</p>
							<h1>
								Embarked on renovating or building a new
								home?
							</h1>
						</div>
						<p>
							Valley Plumbing seamlessly integrates top-tier
							plumbing, heating, and water treatment
							solutions; we ensure every project meets the
							pinnacle of comfort and functionality.
						</p>
					</div>
					{!matches && (
						<Link
							className="light-blue-button"
							to="/request-quote"
						>
							Get A Quote
						</Link>
					)}
				</motion.div>
			</div>
		</Container>
	);
};

export default Renovating;
