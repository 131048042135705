import './BestProducts.scss';
import { Container, Divider } from '@mui/material';
import ProductCartWithBackground from '../ProductCartWithBackground';
import background3 from '../../assets/background3.png';
import { useGetAllProductsQuery } from '../../utils/API/features/productsApi';
import CustomLoader from '../Shared/CustomLoader/CustomLoader';
import {
	CustomLoaderType,
	fallbackImage,
} from '../../utils/constants';
const BestProducts = () => {
	const {
		data: { data: products = [] } = {},
		isLoading,
		isFetching,
	} = useGetAllProductsQuery({
		sort: 'popularity',
	});

	if (isLoading || isFetching)
		return <CustomLoader type={CustomLoaderType.SMALL} />;

	const [product1, product2] = products;

	const data1 = {
		...product1,
		backgroundImage: background3,
		category: product1.parentCategories[0].name,
		productImage:
			product1.productMedia?.at(0)?.file ?? fallbackImage,
		productDiscount: product1.discountPercent ?? 0,
		variantsLength: product1.productVariants?.length ?? 0,
	};
	const data2 = {
		...product2,
		backgroundImage: background3,
		category: product2.parentCategories[0].name,
		productImage:
			product2.productMedia?.at(0)?.file ?? fallbackImage,
		productDiscount: product2.discountPercent ?? 0,
		variantsLength: product2.productVariants?.length ?? 0,
	};
	return (
		<Container
			sx={{
				maxWidth: '1352px !important',
				paddingX: {
					xs: '20px !important',
					lg: '0px !important',
				},
			}}
		>
			<div className="best-products-wrapper">
				<ProductCartWithBackground data={data1} />
				{product2 && (
					<ProductCartWithBackground data={data2} />
				)}
			</div>
			<Divider />
		</Container>
	);
};

export default BestProducts;
