import { IconButton, InputAdornment, InputBase, Skeleton } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useNavigate,
  // useParams
} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchBarComponent.scss";
// import { BestSellerShop } from '../../utils/constants';
import PropTypes from "prop-types";
// import { useSelector } from 'react-redux';
import {
  useGetAllCategoryProductsQuery,
  useGetAllProductsQuery,
  useGetAllSubCategoryProductsQuery,
} from "../../utils/API/features/productsApi";
import { useGetProductsBySearchTermQuery } from "../../utils/API/features/searchApi";
import { debounce } from "../../utils/helperFunctions";
import { fallbackImage } from "../../utils/constants";

const SearchBarComponent = ({ short, categoryId, subCategoryId }) => {
  const scrollableDivRef = useRef(null); // Ref to the scrollable div
  const navigate = useNavigate();
  const [searchInView, setSearchInView] = useState(false);
  const [query, setQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  // const [isLoading, setisLoading] = useState(false);
  const [page, setPage] = useState(1);
  // const [getQuery, setGetQuery] = useState(false);
  const [end, setEnd] = useState(false);
  const [isMoreData, setIsMoreData] = useState(false);
  // const [error, setError] = useState(false);
  const serachResultRef = useRef();
  // const { id } = useParams();
  //   const { categories } = useSelector((state) => state.category);

  const { data: { data: allSuggestions = [] } = {} } = useGetAllProductsQuery(
    {
      sort: "popularity",
    },
    {
      skip: categoryId || subCategoryId,
    }
  );
  const { data: { data: catSuggestions = [] } = {} } =
    useGetAllCategoryProductsQuery(
      {
        categoryId,
        searchQuery: { sort: "popularity" },
      },
      {
        skip: !categoryId || subCategoryId,
      }
    );
  const { data: { data: subSuggestions = [] } = {} } =
    useGetAllSubCategoryProductsQuery(
      {
        subCategoryId,
        searchQuery: { sort: "popularity" },
      },
      {
        skip: !subCategoryId,
      }
    );

  const suggestions = subCategoryId
    ? subSuggestions
    : categoryId
    ? catSuggestions
    : allSuggestions;

  const {
    data: { data: currentPage = [], totalPages } = {},
    isLoading,
    isFetching,
  } = useGetProductsBySearchTermQuery(
    {
      categoryId,
      subCategoryId,
      searchTerm,
      page,
    },
    {
      skip: searchTerm === "",
    }
  );

  // useEffect(() => {
  // 	if (
  // 		!isLoading &&
  // 		!isFetching &&
  // 		productsResult.length === 0
  // 	) {
  // 		setProducts(productsResult);
  // 	}
  // }, [productsResult, isLoading, isFetching]);

  const suggestionsList = suggestions.map((suggestion) => suggestion.name);

  // useEffect(() => {
  // 	const fetchProductsByName = async () => {
  // 		try {
  // 			setisLoading(true);
  // 			// const response = await publicRequest.get(
  // 			//   `/products?name=${query}&page=${page}&page_size=4`
  // 			// );
  // 			// const data = response.data;
  // 			// const items = data.products;
  // 			// if (items.length === 0) {
  // 			//   setEnd(true);
  // 			// }
  // 			// if (getQuery) {
  // 			//   setProducts(items);
  // 			// } else {
  // 			//   setProducts((prev) => [...prev, ...items]);
  // 			// }
  // 			// setisLoading(false);
  // 			setProducts(BestSellerShop);
  // 		} catch (err) {
  // 			setisLoading(false);
  // 			setError(true);
  // 		}
  // 	};
  // 	if (searchInView) {
  // 		fetchProductsByName();
  // 	}
  // }, [query, searchInView, page]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPage(1);
          // setProducts([]);
          setSearchInView(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(serachResultRef);

  // console.log('full Query', { searchTerm, page });

  //   const handleSearch = () => {
  //     const isFound = categories.find((cate) => cate._id === parseInt(id));
  //     if (isFound) {
  //       navigate(`/shop/${id}/${isFound?.children_data[0]?._id}?search=${query}`);
  //     } else {
  //       const randomCat = categories[0];
  //       navigate(
  //         `/shop/${randomCat._id}/${randomCat?.children_data[0]?._id}?search=${query}`
  //       );
  //     }
  //   };

  //   const handleKeyPress = (event) => {
  //     if (event.key === 'Enter' && categories && categories?.length > 0) {
  //       handleSearch();
  //     }
  //   };

  const handleChange = (e) => {
    setQuery(e.target.value);
    debouncedStartSearch(e.target.value);
  };

  const startSearch = (term) => {
    setProducts([]);
    setPage(1);
    setIsMoreData(false);
    setEnd(false);
    setSearchTerm(term.trim());

    // setGetQuery(true);
  };

  useEffect(() => {
    if (currentPage && currentPage.length > 0) {
      if (isMoreData) {
        setProducts([...products, ...currentPage]);
      } else {
        setProducts(currentPage);
      }
    }
  }, [currentPage]);

  //TODO: Need more Graceful Way to handle scroll and loading

  const debouncedStartSearch = useCallback(debounce(startSearch, 500), []);

  const handleProductClick = (productId) => {
    setSearchInView(false);
    navigate(`/product/${productId}`);
  };

  const handleScroll = () => {
    const div = scrollableDivRef.current;
    if (div) {
      const { scrollTop, scrollHeight, clientHeight } = div;
      // console.log(
      // 	'scrolling',
      // 	scrollTop,
      // 	scrollHeight,
      // 	clientHeight
      // );
      const isAtEnd = scrollTop + clientHeight >= scrollHeight - 5;
      if (isAtEnd && !isLoading && !end && page < totalPages) {
        setIsMoreData(true);
        setPage((prev) => prev + 1);
        // setGetQuery(false);
        // console.log('fetching more', page, page + 1);
      }
    }
  };
  const handleSuggestionsClick = (suggestion) => {
    setQuery(suggestion);
    startSearch(suggestion);
  };
  return (
    <div className='searchbarwrap' ref={serachResultRef}>
      <InputBase
        className='mui-register-password'
        type='text'
        value={query}
        // onKeyDown={handleKeyPress}
        onFocus={() => setSearchInView(true)}
        onChange={handleChange}
        placeholder='Search among products'
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              //   disabled={categories && categories?.length === 0}
              edge='end'
              aria-label='search button'
              //   onClick={handleSearch}
            >
              <SearchIcon className='SearchIcon' />
            </IconButton>
          </InputAdornment>
        }
      />
      {searchInView && (
        <div className={`search-result-div ${short ? "short" : ""}`}>
          <div
            className='live-search-scroll-div'
            ref={scrollableDivRef}
            onScroll={handleScroll}>
            {query !== "" ? (
              !(isLoading || (isFetching && !isMoreData)) ? (
                products?.length > 0 ? (
                  products.map((product) => (
                    <div
                      className='search-product-box'
                      key={product?._id}
                      onClick={() => handleProductClick(product?._id)}>
                      <div className='img-wrapper'>
                        <img
                          src={
                            product?.productMedia?.at(0)?.file ?? fallbackImage
                          }
                          alt={product?.name}
                        />
                      </div>
                      <span className='searchproduct-name'>
                        {product?.name}
                      </span>
                      <div className='icon'>
                        <svg
                          style={{
                            position: "relative",
                          }}
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'>
                          <path
                            d='M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z'
                            stroke='#616161'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13.9996 13.9996L11.0996 11.0996'
                            stroke='#616161'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                  ))
                ) : (
                  <span>No items found</span>
                )
              ) : null
            ) : (
              <div className='suggestions'>
                {suggestionsList.map((suggestion, index) => (
                  <div
                    className='suggestion'
                    key={index}
                    onClick={() => handleSuggestionsClick(suggestion)}>
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
            {(isLoading || isFetching) &&
              [0, 1, 2].map((index) => (
                <Skeleton
                  key={index}
                  style={{
                    minHeight: "75px",
                    padding: "0px",
                    margin: "0px",
                  }}></Skeleton>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

SearchBarComponent.propTypes = {
  short: PropTypes.bool,
  categoryId: PropTypes.string,
  subCategoryId: PropTypes.string,
};

export default SearchBarComponent;
