import { FaHeart, FaStar } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { Button, IconButton } from "@mui/material";
import "swiper/css";
import { useNavigate } from "react-router-dom";
import "./NewProductHoverBox.scss";
import PropTypes from "prop-types";
import { fallbackImage } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addProductToCartAction,
  selectSelectedProducts,
  selectWishlistProducts,
  toggleProductInWishlistAction,
} from "../../utils/features/cart";

// const demovariant = [
// 	{
// 		id: 1,
// 		name: 'model',
// 		values: [
// 			{
// 				id: 11,
// 				name: 'Single hand',
// 			},
// 			{
// 				id: 12,
// 				name: 'Spray',
// 			},
// 			{
// 				id: 13,
// 				name: 'Hand',
// 			},
// 		],
// 	},
// 	{
// 		id: 2,
// 		name: 'color',
// 		values: [
// 			{
// 				id: 21,
// 				name: 'Red',
// 			},
// 			{
// 				id: 22,
// 				name: 'Blue',
// 			},
// 		],
// 	},
// ];

const NewProductHoverBox = ({ data, style }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const selectedProducts = useSelector(selectSelectedProducts);

  const wishlistProducts = useSelector(selectWishlistProducts);

  const isInWishlist = wishlistProducts.find((p) => p._id === data._id);
  const isInCart = selectedProducts.find(
    (p) =>
      p.product._id === data._id &&
      p.product.productVariantId === data.productVariantId
  );

  const addToCart = (product) => dispatch(addProductToCartAction({ product }));

  const addToWishList = (product) =>
    dispatch(toggleProductInWishlistAction({ product }));

  const handleAddToWishList = (e) => {
    // setIsAddedToWishlist(!isAddedToWishlist);
    addToWishList(data);
    handleClick(e);
  };

  const handleAddToCart = (e) => {
    if (data.productVariants.length > 1) {
      navigate(`/product/${data._id}`);
      return;
    }
    const newData = { ...data };
    addToCart(newData);
    handleClick(e);
  };
  // const [variantSelected, setVariantSelected] = useState(
  // 	{}
  // );

  const handleClick = (e) => {
    e.stopPropagation();
  };

  // const handleVariantClick = (e, name, value) => {
  // 	e.stopPropagation();
  // 	setVariantSelected({
  // 		...variantSelected,
  // 		[name]: value,
  // 	});
  // };

  const variants = data?.attributes ?? {};

  const isOutOfStock = data?.inventoryCount === 0 ?? false;

  const variantsArray = Object.entries(variants);
  const thumbnail = data.thumbnail ? data.thumbnail : data.productMedia?.at(0);
  return (
    <div
      className={`NewHoverinsideProductBoxWrapper ${style || ""}`}
      onClick={() => navigate(`/product/${data._id}`)}>
      {data && isOutOfStock && (
        <div className='out-of-stock'>
          <div>Out of Stock</div>
        </div>
      )}
      <div className='productImgWrapper'>
        {data.discountPercent > 0 && (
          <div className='saleBadge'>
            {data.discountPercent.toFixed(2)}% OFF
          </div>
        )}
        <img
          src={thumbnail.file ?? fallbackImage}
          alt='product1'
          id='newHoverProductImg'
        />
        <IconButton
          onMouseDown={(e) => e.stopPropagation()}
          className='WishlistMobileIconBttn'
          onClick={handleAddToWishList}>
          {isInWishlist ? (
            <FaHeart className='icon' />
          ) : (
            <FaRegHeart className='icon' />
          )}
        </IconButton>
        <div className='cartHoverDiv'>
          <IconButton className='IconBttn'>
            <IoEyeOutline className='icon' />
          </IconButton>
          {isInCart ? (
            <div className='added-to-cart'>Added to Cart</div>
          ) : (
            <Button
              onMouseDown={(e) => e.stopPropagation()}
              className='addToCart'
              onClick={handleAddToCart}>
              Add to cart
            </Button>
          )}
          <IconButton
            onMouseDown={(e) => e.stopPropagation()}
            className='IconBttn'
            onClick={handleAddToWishList}>
            {isInWishlist ? (
              <FaHeart className='icon' />
            ) : (
              <FaRegHeart className='icon' />
            )}
          </IconButton>
        </div>
      </div>
      <div className='productDetails'>
        <div className='rating'>
          <FaStar className='icon' />
          <span className='count'>{data.rating ?? 0}</span>
        </div>
        <p className='prodName'>{data.name}</p>
        <div className='divider'></div>
        <div className='priceCont'>
          <p className='new'>${data.maxPrice?.toFixed(2) ?? 0}</p>
          {data.isDiscounted &&
            data.finalPrice !== data.priceBeforeDiscount && (
              <p className='old'>
                ${data.priceBeforeDiscount?.toFixed(2) ?? 0}
              </p>
            )}
        </div>
      </div>
      {/*TODO: Handle Variants excess of 3 values */}
      <div className='productVariantHover'>
        {variantsArray.length > 0 ? (
          variantsArray.map(([name, values], index) => (
            <>
              {!name.toLowerCase().includes("color") ? (
                <div className='variantSelect'>
                  <span
                    className={`selection`}
                    key={index}
                    style={{
                      textTransform: "capitalize",
                    }}
                    // onClick={(e) =>
                    // 	handleVariantClick(
                    // 		e,
                    // 		demovariant[0],
                    // 		vari.id
                    // 	)
                    // }
                  >
                    {name}: {values.join(", ")}
                  </span>
                </div>
              ) : (
                <div className='colorSelection'>
                  <span
                    className='selection'
                    style={{
                      textTransform: "capitalize",
                    }}>
                    {name}:
                  </span>
                  {values.map((color, innerIndex) => (
                    <div
                      key={innerIndex}
                      className={`colorBox`}
                      style={{ backgroundColor: color }}
                      // onClick={(e) =>
                      // 	handleVariantClick(
                      // 		e,
                      // 		demovariant[1],
                      // 		vari.id
                      // 	)
                      // }
                    ></div>
                  ))}
                </div>
              )}
            </>
          ))
        ) : (
          <div className='productDetails'>
            <div className='rating'>
              <FaStar className='icon' />
              <span className='count'>{data.rating ?? 0}</span>
            </div>
            <p className='prodName'>{data.name}</p>
            <div className='divider'></div>
            <div className='priceCont'>
              <p className='new'>${data.maxPrice?.toFixed(2) ?? 0}</p>
              {data.isDiscounted &&
                data.finalPrice !== data.priceBeforeDiscount && (
                  <p className='old'>
                    ${data.priceBeforeDiscount?.toFixed(2) ?? 0}
                  </p>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NewProductHoverBox.propTypes = {
  data: PropTypes.object,
  style: PropTypes.string,
};

export default NewProductHoverBox;
