import React, { useState } from "react";
import { Container, useMediaQuery } from "@mui/material";
import Substract from "../../assets/Subtract.png";
import Substract2 from "../../assets/Subtract (12).png";
import Substract3 from "../../assets/Subtract3.svg";
import Subtract21 from "../../assets/Subtract21.svg";
import "./Banner.scss";
import { Link } from "react-router-dom";
import PaginationLines from "./PaginationLines";
import backgroundGuyMobile from "../../assets/background-guy.png";
import backgroundGuyMobile2 from "../../assets/background-guy-mobile2.png";
import backgroundGuyMobile3 from "../../assets/background-guy-mobile3.png";
import { motion } from "framer-motion";

const Banner = () => {
  const lines = [1, 2, 3];
  const [selectedLine, setSelectedLine] = useState(0);
  const matches = useMediaQuery("(max-width:1100px)");
  return (
    <Container
      sx={{
        maxWidth: "1464px !important",
        paddingX: { xs: "20px !important", lg: "0px !important" },
        display: "flex",
        alignItems: "flex-start",
      }}>
      {matches ? (
        <div className={`banner-wrapper-mobile index-${selectedLine}`}>
          <div className='top-part'>
            <h1>
              Discover Valley Plumbing, Heating & Water Treatment Premium Range
            </h1>
            <Link className='blue-button' to='/shop'>
              Shop
            </Link>
          </div>
          <div className='white-box'>
            <h1>Heater Malfunction? Plumbing Crisis?</h1>
            <p>Immediate Fixes with Our Same Day Service</p>
            <a className='light-blue-button' href='tel:+6132675206'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <g clipPath='url(#clip0_1901_5070)'>
                  <path
                    d='M14.6665 11.28V13.28C14.6672 13.4657 14.6292 13.6494 14.5548 13.8195C14.4804 13.9897 14.3713 14.1424 14.2345 14.2679C14.0977 14.3934 13.9362 14.489 13.7603 14.5485C13.5844 14.6079 13.398 14.63 13.2131 14.6133C11.1617 14.3904 9.19113 13.6894 7.45979 12.5667C5.84901 11.5431 4.48335 10.1774 3.45979 8.56665C2.33311 6.82745 1.63195 4.84731 1.41313 2.78665C1.39647 2.60229 1.41838 2.41649 1.47746 2.24107C1.53654 2.06564 1.63151 1.90444 1.7563 1.76773C1.8811 1.63102 2.033 1.52179 2.20232 1.447C2.37164 1.37221 2.55469 1.33349 2.73979 1.33332H4.73979C5.06333 1.33013 5.37699 1.4447 5.6223 1.65567C5.86761 1.86664 6.02784 2.15961 6.07313 2.47998C6.15754 3.12003 6.31409 3.74847 6.53979 4.35332C6.62949 4.59193 6.6489 4.85126 6.59573 5.10057C6.54256 5.34988 6.41903 5.57872 6.23979 5.75998L5.39313 6.60665C6.34216 8.27568 7.7241 9.65761 9.39313 10.6067L10.2398 9.75998C10.4211 9.58074 10.6499 9.45722 10.8992 9.40405C11.1485 9.35088 11.4078 9.37029 11.6465 9.45998C12.2513 9.68568 12.8797 9.84224 13.5198 9.92665C13.8436 9.97234 14.1394 10.1355 14.3508 10.385C14.5622 10.6345 14.6746 10.953 14.6665 11.28Z'
                    stroke='#008FD5'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1901_5070'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              613-267-5206
            </a>
          </div>
          <div className='navigation-buttons'>
            <button
              onClick={() => {
                if (selectedLine > 0)
                  setSelectedLine((prevState) => prevState - 1);
              }}
              className='light-blue-button'
              style={{ padding: "16px", backgroundColor: "#CCE9F7" }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='12'
                viewBox='0 0 20 12'
                fill='none'>
                <path
                  d='M6.9999 0.2625C7.1999 0.4375 7.2999 0.65 7.2999 0.9C7.2999 1.15 7.1999 1.35 6.9999 1.5L3.4749 5.1H18.6999C19.2499 5.15 19.5499 5.45 19.5999 6C19.5499 6.55 19.2499 6.85 18.6999 6.9H3.4749L7.0374 10.4625C7.3874 10.8875 7.3874 11.3125 7.0374 11.7375C6.6124 12.0875 6.1874 12.0875 5.7624 11.7375L0.662402 6.6375C0.312402 6.2125 0.312402 5.7875 0.662402 5.3625L5.7624 0.2625C6.1874 -0.0875006 6.5999 -0.0875006 6.9999 0.2625Z'
                  fill='#008FD5'
                />
              </svg>
            </button>
            <button
              onClick={() => {
                if (selectedLine < 2)
                  setSelectedLine((prevState) => prevState + 1);
              }}
              className='light-blue-button'
              style={{ padding: "16px" }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='12'
                viewBox='0 0 20 12'
                fill='none'>
                <path
                  d='M13.0001 11.7375C12.8001 11.5625 12.7001 11.35 12.7001 11.1C12.7001 10.85 12.8001 10.65 13.0001 10.5L16.5251 6.9L1.3001 6.9C0.750097 6.85 0.450096 6.55 0.400096 6C0.450096 5.45 0.750097 5.15 1.3001 5.1L16.5251 5.1L12.9626 1.5375C12.6126 1.1125 12.6126 0.687499 12.9626 0.262499C13.3876 -0.0875005 13.8126 -0.0875005 14.2376 0.2625L19.3376 5.3625C19.6876 5.7875 19.6876 6.2125 19.3376 6.6375L14.2376 11.7375C13.8126 12.0875 13.4001 12.0875 13.0001 11.7375Z'
                  fill='#008FD5'
                />
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <div className='banner-wrapper'>
          <img
            src={
              selectedLine == "0"
                ? Substract
                : selectedLine == "1"
                ? Subtract21
                : Substract2
            }
            alt={"substract-image"}
          />
          <motion.div
            initial={{ opacity: 0, x: -50, y: -30 }}
            whileInView={{ opacity: 1, x: 0, y: 0 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ ease: "easeOut", duration: 0.4 }}
            className='big-text'>
            {selectedLine == "1" && <p className='blue_sup'>Unlock Savings</p>}

            {selectedLine == "0" && (
              <h1>
                Discover Valley Plumbing, Heating & Water Treatment Premium
                Range{" "}
              </h1>
            )}
            {selectedLine == "1" && (
              <h1>
                {" "}
                Get Up to in <span>$5,000</span> Rebates When You Shop with
                Valley Today!{" "}
              </h1>
            )}
            {selectedLine == "2" && (
              <h1>Dedicated to Punctuality and Reliability </h1>
            )}

            {selectedLine == "2" && (
              <p className='desc'>
                You can trust us to be there when promised and to fulfill our
                commitments with excellence.
              </p>
            )}

            <Link
              className='blue-button'
              to={
                selectedLine == "0"
                  ? "/shop"
                  : selectedLine == "1"
                  ? "/RebateProgram"
                  : "/request-quote"
              }
              style={{ marginTop: "40px", width: "auto" }}>
              {selectedLine == "0"
                ? "Shop"
                : selectedLine == "1"
                ? "Get Rebate"
                : "Request a Quote Now"}
            </Link>
          </motion.div>
          <div className='lines'>
            {lines.map((line, index) =>
              index === selectedLine ? (
                <svg
                  key={index}
                  xmlns='http://www.w3.org/2000/svg'
                  width='55'
                  height='6'
                  viewBox='0 0 55 6'
                  fill='none'>
                  <path
                    d='M3 3H51.5'
                    stroke='#008FD5'
                    strokeWidth='6'
                    strokeLinecap='round'
                  />
                </svg>
              ) : (
                <svg
                  key={index}
                  xmlns='http://www.w3.org/2000/svg'
                  width='36'
                  height='6'
                  viewBox='0 0 36 6'
                  fill='none'>
                  <path
                    opacity='0.2'
                    d='M3.5 3H32.5'
                    stroke='#008FD5'
                    strokeWidth='6'
                    strokeLinecap='round'
                  />
                </svg>
              )
            )}
          </div>
          <div className='call-box'>
            <motion.h1
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ ease: "easeOut", duration: 0.4, delay: 0.2 }}>
              Heater Malfunction? Plumbing Crisis?
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ ease: "easeOut", duration: 0.4, delay: 0.4 }}>
              Immediate Fixes with Our Same Day Service
            </motion.p>
            <motion.a
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ ease: "easeOut", duration: 0.4, delay: 0.6 }}
              className='light-blue-button'
              href='tel:+6132675206'>
              613-267-5206
            </motion.a>
          </div>
          <div className='navigation-buttons'>
            <button
              onClick={() => {
                if (selectedLine > 0)
                  setSelectedLine((prevState) => prevState - 1);
              }}
              className='light-blue-button'
              style={{ padding: "16px", backgroundColor: "#CCE9F7" }}
              aria-label='Navigation button'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='12'
                viewBox='0 0 20 12'
                fill='none'>
                <path
                  d='M6.9999 0.2625C7.1999 0.4375 7.2999 0.65 7.2999 0.9C7.2999 1.15 7.1999 1.35 6.9999 1.5L3.4749 5.1H18.6999C19.2499 5.15 19.5499 5.45 19.5999 6C19.5499 6.55 19.2499 6.85 18.6999 6.9H3.4749L7.0374 10.4625C7.3874 10.8875 7.3874 11.3125 7.0374 11.7375C6.6124 12.0875 6.1874 12.0875 5.7624 11.7375L0.662402 6.6375C0.312402 6.2125 0.312402 5.7875 0.662402 5.3625L5.7624 0.2625C6.1874 -0.0875006 6.5999 -0.0875006 6.9999 0.2625Z'
                  fill='#008FD5'
                />
              </svg>
            </button>
            <button
              onClick={() => {
                if (selectedLine < 2)
                  setSelectedLine((prevState) => prevState + 1);
              }}
              className='light-blue-button'
              style={{ padding: "16px" }}
              aria-label='Navigation button'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='12'
                viewBox='0 0 20 12'
                fill='none'>
                <path
                  d='M13.0001 11.7375C12.8001 11.5625 12.7001 11.35 12.7001 11.1C12.7001 10.85 12.8001 10.65 13.0001 10.5L16.5251 6.9L1.3001 6.9C0.750097 6.85 0.450096 6.55 0.400096 6C0.450096 5.45 0.750097 5.15 1.3001 5.1L16.5251 5.1L12.9626 1.5375C12.6126 1.1125 12.6126 0.687499 12.9626 0.262499C13.3876 -0.0875005 13.8126 -0.0875005 14.2376 0.2625L19.3376 5.3625C19.6876 5.7875 19.6876 6.2125 19.3376 6.6375L14.2376 11.7375C13.8126 12.0875 13.4001 12.0875 13.0001 11.7375Z'
                  fill='#008FD5'
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Banner;
