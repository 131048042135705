import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	defaultAuthTab: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setDefaultAuthTab: (state, action) => {
			state.defaultAuthTab = action.payload;
		},
	},
	selectors: {
		selectDefaultAuthTabAction: (state) =>
			state.defaultAuthTab,
	},
});

export const { setDefaultAuthTab } = authSlice.actions;
export const { selectDefaultAuthTabAction } =
	authSlice.selectors;
export default authSlice.reducer;
