/**
 * Debounces a function, ensuring that it waits for a specified delay before executing.
 * This is useful for rate limiting or optimizing performance by preventing rapid,
 * consecutive function calls.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The delay in milliseconds to wait before executing the function,
 * defaults to 500ms.
 * @returns {Function} - The debounced function.
 */
export const debounce = (func, delay = 500) => {
	let timeoutId;
	return function (...args) {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
};
