import PropTypes from 'prop-types';

const PaginationLines = ({ lines, selectedLine }) => {
	return (
		<>
			{lines.map((line, index) =>
				index === selectedLine ? (
					<svg
						key={index}
						xmlns="http://www.w3.org/2000/svg"
						width="55"
						height="6"
						viewBox="0 0 55 6"
						fill="none"
					>
						<path
							d="M3 3H51.5"
							stroke="#008FD5"
							strokeWidth="6"
							strokeLinecap="round"
						/>
					</svg>
				) : (
					<svg
						key={index}
						xmlns="http://www.w3.org/2000/svg"
						width="36"
						height="6"
						viewBox="0 0 36 6"
						fill="none"
					>
						<path
							opacity="0.2"
							d="M3.5 3H32.5"
							stroke="#008FD5"
							strokeWidth="6"
							strokeLinecap="round"
						/>
					</svg>
				)
			)}
		</>
	);
};

PaginationLines.propTypes = {
	lines: PropTypes.array.isRequired,
	selectedLine: PropTypes.number.isRequired,
};

export default PaginationLines;
