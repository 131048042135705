import image56 from '../assets/image 56.png';
import image57 from '../assets/image 57.png';
import image58 from '../assets/image 58.png';
import image60 from '../assets/image 60.png';
import image601 from '../assets/image 60 (1).png';
import image602 from '../assets/image 60 (2).png';

import image20 from '../assets/image 20.png';
import image201 from '../assets/image 20 (1).png';

import image202 from '../assets/image 20 (2).png';

import image47 from '../assets/image 47.png';

import logoOnly from '../assets/Logo-icon-only.png';

export const fallbackImage = logoOnly;

export const categories = [
	{
		category: 'Plumbing',
		products: [
			{ name: 'Sump Pumps', img: image56 },
			{ name: 'Water Heaters', img: image57 },
			{ name: 'Submersible Pump', img: image58 },
			{ name: 'Jet Pump', img: image60 },
			{ name: 'Trim Out Parts', img: image601 },
			{ name: 'Sump Pumps', img: image56 },
			{ name: 'Submersible Pump', img: image58 },
			{ name: 'Trim Out Parts', img: image601 },
			{ name: 'Jet Pump', img: image60 },
		],
	},
	{
		category: 'Water Treatment',
		products: [
			{ name: 'Sump Pumps', img: image56 },
			{ name: 'Water Heaters', img: image57 },
			{ name: 'Submersible Pump', img: image58 },
			{
				name: 'Viqua / Trojan Lamps and Sleeves',
				img: image60,
			},
			{ name: 'Trim Out Parts', img: image602 },
			{
				name: 'Reverse Osmosis Filters and Membranes',
				img: image56,
			},
			{
				name: 'Iron Out, Resup and Resup Feeder',
				img: image58,
			},
			{ name: 'Trim Out Parts', img: image602 },
			{ name: 'Jet Pump', img: image60 },
		],
	},
	{
		category: 'Heating',
		products: [
			{ name: 'Thermostats', img: image56 },
			{ name: 'HRV Parts', img: image57 },
			{ name: 'Pipe Insulation', img: image58 },
			{ name: 'Expansion Tanks', img: image601 },
		],
	},
];

export const productCategoryCart = [
	{
		category: 'Plumbing',
		products: [
			{
				name: 'Installation & Maintenance',
				image: image60,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
			{
				name: 'Installation & Maintenance',
				image: image60,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
			{
				name: 'Installation & Maintenance',
				image: image60,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
			{
				name: 'Installation & Maintenance',
				image: image60,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
		],
	},
	{
		category: 'Heating',
		products: [
			{
				name: 'Installation & Maintenance',
				image: image20,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
			{
				name: 'Installation & Maintenance',
				image: image20,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
			{
				name: 'Installation & Maintenance',
				image: image20,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
			{
				name: 'Installation & Maintenance',
				image: image20,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
		],
	},
	{
		category: 'Water Treatment',
		products: [
			{
				name: 'Installation & Maintenance',
				image: image201,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
			{
				name: 'Installation & Maintenance',
				image: image201,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
			{
				name: 'Installation & Maintenance',
				image: image201,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
			{
				name: 'Installation & Maintenance',
				image: image201,
				originalPrice: 120.6,
				price: 120.6,
				discount: 12,
			},
		],
	},
];

export const BestDealsShop = [
	{
		column: [
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
		],
	},
	{
		column: [
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
		],
	},
	{
		column: [
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
		],
	},
	{
		column: [
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
		],
	},
	{
		column: [
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
			{
				image: image202,
				originalPrice: 120.6,
				price: 120.6,
				name: 'Installation & Maintenance',
			},
		],
	},
];

export const BestSellerShop = [
	{
		name: 'Installation & Maintenance',
		image: image202,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image201,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image20,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image60,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image58,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image601,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image202,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image202,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image201,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image60,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image58,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image601,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image202,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image202,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image201,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
	{
		name: 'Installation & Maintenance',
		image: image60,
		dropPercentage: '12',
		rating: 4.2,
		price: 120.6,
		originalPrice: 120.6,
	},
];

export const FeaturedProductsShop = [
	{
		column: [
			{
				name: 'Installation & Maintenance',
				image: image60,
				dropPercentage: '12',
				rating: 4.2,
				price: 120.6,
				originalPrice: 120.6,
			},
		],
	},
	{
		column: [
			{
				name: 'Installation & Maintenance',
				image: image60,
				dropPercentage: '12',
				rating: 4.2,
				price: 120.6,
				originalPrice: 120.6,
			},
		],
	},
	{
		column: [
			{
				name: 'Installation & Maintenance',
				image: image60,
				dropPercentage: '12',
				rating: 4.2,
				price: 120.6,
				originalPrice: 120.6,
			},
		],
	},
	{
		column: [
			{
				name: 'Installation & Maintenance',
				image: image60,
				dropPercentage: '12',
				rating: 4.2,
				price: 120.6,
				originalPrice: 120.6,
			},
		],
	},
	{
		column: [
			{
				name: 'Installation & Maintenance',
				image: image60,
				dropPercentage: '12',
				rating: 4.2,
				price: 120.6,
				originalPrice: 120.6,
			},
		],
	},
	{
		column: [
			{
				name: 'Installation & Maintenance',
				image: image60,
				dropPercentage: '12',
				rating: 4.2,
				price: 120.6,
				originalPrice: 120.6,
			},
		],
	},
];

export const HeaderProducts = {
	category: 'Sump Pumps',
	products: [
		{ name: 'Sewage Pumps', image: image60 },
		{ name: 'Sump Jet', image: image60 },
	],
};

export const filters = [
	{
		category: 'Plumbing',
		elements: [
			{
				name: 'Sump Pumps',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Water Heaters',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Jet Pumps',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Sewage Pump',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Trim Out Parts',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Yard Hydrants',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Heat Lines',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
		],
	},
	{
		category: 'Heating',
		elements: [
			{
				name: 'Thermostats',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'HRV Parts',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Expansion Tanks',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Pipe Insulation',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
		],
	},
	{
		category: 'Water Treatment',
		elements: [
			{
				name: 'UV Dynamic Lamps and Sleeves',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Viqua / Trojan Ballast Parts',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Iron Out, Resup and Resup Feeder',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Hallett UV Pure Lamps and Sleeves',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Viqua / Trojan Lamps and Sleeves',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Reverse Osmosis Filters and Membranes',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Filters and Filter Housings',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Sterilight UV Lamps and Sleeves',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
			{
				name: 'Ozonator Lamps',
				subElements: ['Sump Pumps', 'Sump Pump Parts'],
			},
		],
	},
];

export const cartProducts = [
	{
		name: 'Kohler Simplice 1.5 GPM Single Hole Pull Down Kitchen Faucet - Includes Escutcheon',
		image: image47,
		color: 'Gray',
		option: 'Single handle',
		originalPrice: 120.6,
		price: 120.6,
		quantity: 2,
		discount: 19.99,
		totalPrice: 241.2,
	},
	{
		name: 'Kohler Simplice 1.5 GPM Single Hole Pull Down Kitchen Faucet - Includes Escutcheon',
		image: image47,
		color: 'Gray',
		option: 'Single handle',
		originalPrice: 120.6,
		price: 120.6,
		quantity: 2,
		discount: 19.99,
		totalPrice: 241.2,
	},
	{
		name: 'Kohler Simplice 1.5 GPM Single Hole Pull Down Kitchen Faucet - Includes Escutcheon',
		image: image47,
		color: 'Gray',
		option: 'Single handle',
		originalPrice: 120.6,
		price: 120.6,
		quantity: 2,
		discount: 19.99,
		totalPrice: 241.2,
	},
	{
		name: 'Kohler Simplice 1.5 GPM Single Hole Pull Down Kitchen Faucet - Includes Escutcheon',
		image: image47,
		color: 'Gray',
		option: 'Single handle',
		originalPrice: 120.6,
		price: 120.6,
		quantity: 2,
		discount: 19.99,
		totalPrice: 241.2,
	},
];

export const ConditionEnum = {
	ALL: 'ALL',
	CATEGORY: 'CATEGORY',
	SUBCATEGORY: 'SUBCATEGORY',
};

export const CustomLoaderType = {
	SMALL: 'small',
	BIG: 'big',
	MEDIUM: 'medium',
};

export const isQueryEqual = (oldQuery, currentQuery) => {
	if (!oldQuery || !currentQuery) {
		return false;
	}
	const oldQueryKeys = Object.keys(oldQuery ?? {});
	for (const key in currentQuery) {
		//New Query Param
		if (!oldQueryKeys.includes(key)) {
			return false;
		}
		//Same Query Param, New Value
		if (
			Object.prototype.hasOwnProperty.call(
				currentQuery,
				key
			)
		) {
			// const element = currentQuery[key];
			const oldValue = oldQuery[key];
			const currentValue = currentQuery[key];
			if (oldValue != currentValue) {
				return false;
			}
		}
	}
	return oldQueryKeys.every((key) =>
		Object.prototype.hasOwnProperty.call(currentQuery, key)
	);
};

export const FilterTypes = {
	ALL: 'ALL',
	CATEGORY: 'CATEGORY',
	SUBCATEGORY: 'SUBCATEGORY',
};
