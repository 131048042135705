import React from 'react';
import './Map.scss';
import { Container } from '@mui/material';
import GoogleMapComponent from '../GoogleMap/GoogleMap';

const Map = ({ clickable, style, areaName }) => {
  return (
    <div className="map-wrapper" style={style || {}}>
      <Container>
        <div className="inside-map-wrapper">
          {clickable ? (
            <div className="top">
              <p className="title">Our Serving Areas</p>
              <p className="subTitle">
                {`We're not just in ${areaName ? areaName : "Perth"}; Valley Plumbing, Heating & Water
                Treatment extends its expert services across a wide area.`}
              </p>
            </div>
          ) : (
            <h1>Our Serving Areas</h1>
          )}
          <GoogleMapComponent clickable={clickable} />
        </div>
      </Container>
    </div>
  );
};

export default Map;
