import PropTypes from 'prop-types';
import './WeReceivedOrder.scss';
import { useNavigate } from 'react-router-dom';

const WeReceivedOrder = ({ currentOrderId }) => {
	const navigate = useNavigate();

	return (
		<div className="we-received-order-wrapper">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="65"
				height="64"
				viewBox="0 0 65 64"
				fill="none"
			>
				<path
					d="M32.5 6C18.1375 6 6.5 17.6375 6.5 32C6.5 46.3625 18.1375 58 32.5 58C46.8625 58 58.5 46.3625 58.5 32C58.5 17.6375 46.8625 6 32.5 6ZM28.4875 41.2125C28.1875 41.5125 27.7625 41.7625 27.3875 41.7625C27.0125 41.7625 26.5875 41.5 26.275 41.2L19.275 34.2L21.5 31.975L27.4 37.875L43 22.1625L45.1875 24.425L28.4875 41.2125Z"
					fill="#008FD5"
				/>
			</svg>
			<div className="text">
				<h1>We received your order!</h1>
				<p>
					Your order #{currentOrderId} is complete and ready
					to ship
				</p>
			</div>
			<button
				onClick={() => navigate('/')}
				className="blue-button"
			>
				Go to home
			</button>
		</div>
	);
};

WeReceivedOrder.propTypes = {
	currentOrderId: PropTypes.string,
};

export default WeReceivedOrder;
