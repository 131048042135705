import React from "react";
import { Container } from "@mui/material";
import "./Pumps.scss";
import background2 from "../../assets/background.jpg";
import background1 from "../../assets/background1.jpg";
import background3 from "../../assets/background3.jpg";
import background4 from "../../assets/background4.jpg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import image56 from "../../assets/image 56.png";
import image25 from "../../assets/image 25.png";
import image60 from "../../assets/image 60.png";
import image58 from "../../assets/image 58.png";

import subtract2 from "../../assets/home-pumps.png";

const Pumps = () => {
  const pumps = [
    {
      name: "Sump Pumps",
      img: image56,
      background: background1,
    },
    {
      name: "Heat Lines",
      img: image25,
      background: background2,
    },
    {
      name: "Jet Pumps",
      img: image60,
      background: background3,
    },
    {
      name: "Submersible Pump",
      img: image58,
      background: background4,
    },
  ];
  return (
    <Container
      sx={{ maxWidth: "1352px !important", padding: "0px !important" }}>
      <div className="pumps-wrapper">
        <div className="left-side-image">
          <div className="text-box mobile">
            <div>
              <p className="blue">Pumps Categories</p>
              <h1>Pumps</h1>
            </div>
            <p>
              A wide range of premium products for every plumbing & heating
              need!
            </p>
          </div>
          <img src={subtract2} alt="pumps" />
          <div className="text-box">
            <div>
              <p className="blue">Pumps Categories</p>
              <h1>Pumps</h1>
            </div>
            <p>
              A wide range of premium products for every plumbing & heating
              need!
            </p>
            <Link className="light-blue-button" to="/shop">
              Shop Now
            </Link>
          </div>
          <Link className="light-blue-button button-mobile" to="/shop">
            Shop Now
          </Link>
        </div>
        <div className="pumps-products">
          {pumps.map((product, index) => (
            <div
              key={index}
              className="products"
              style={{ backgroundImage: `url(${product.background})` }}>
              <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: "easeOut", duration: 0.4, delay: 0.2 }}
                src={product.img}
                alt={product.name}
              />
              <p
                style={{
                  marginRight: index === 0 && "60px",
                  marginLeft: index === 1 && "50px",
                }}>
                {product.name}
              </p>
            </div>
          ))}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none">
            <g clipPath="url(#clip0_28_511)">
              <path
                d="M30.5647 29.8014C30.5647 28.4853 28.1636 24.8071 28.1636 24.8071C28.1636 24.8071 25.7262 28.4853 25.7262 29.8014C25.7262 31.1148 26.8099 32.18 28.1458 32.18C29.4809 32.18 30.5647 31.1148 30.5647 29.8014ZM13.2034 9.19287H8.36489V4.42305H9.68423V1.82001H0.883789V4.42305H2.20379V11.7952C2.20379 13.7119 3.77987 15.2649 5.72357 15.2649H13.2034V16.5664H15.844V7.89267H13.2034V9.19287ZM31.2425 20.0353V12.6631C31.2425 10.7472 29.667 9.19353 27.7253 9.19353H20.2442V7.89333H17.6036V16.5677H20.2442V15.2655H25.0834V20.0353H23.7653V22.6384H32.5638V20.0353H31.2425Z"
                fill="#008FD5"
              />
            </g>
            <defs>
              <clipPath id="clip0_28_511">
                <rect
                  width="33"
                  height="33"
                  fill="white"
                  transform="translate(0.223633 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </Container>
  );
};

export default Pumps;
