import { apiSlice } from '../api';
import { productTag } from './searchApi';

export const apiSliceWithOrdersEndpoints =
	apiSlice.injectEndpoints({
		endpoints: (builder) => ({
			getPaymentIntent: builder.mutation({
				query: ({ createOrderDto, paymentIntent }) => ({
					url: '/payments/create-stripe-intent',
					method: 'POST',
					body: {
						paymentIntent,
						createOrderDto,
						currency:
							import.meta.env.VITE_CURRENCY ?? 'cad',
					},
				}),
			}),

			getShippingRates: builder.mutation({
				query: (body) => ({
					url: '/shipping/get-shipping-rates',
					method: 'POST',
					body,
				}),
			}),

			createNewOrder: builder.mutation({
				query: (data) => ({
					url: '/orders/create-guest-order',
					method: 'POST',
					body: data,
				}),
				invalidatesTags: (result, error, data) => [
					...data.selectedProducts.map((i) => ({
						type: productTag,
						id: i.product._id,
					})),
				],
			}),
		}),
	});

export const {
	useGetPaymentIntentMutation,
	useCreateNewOrderMutation,
	useGetShippingRatesMutation,
} = apiSliceWithOrdersEndpoints;
