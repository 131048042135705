import { useEffect } from 'react';
import './ShopMegaMenu.scss';
// import { publicRequest } from "../../utils/ApiRequests";
import { Link, useLocation } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAllCategories } from '../../redux/CategoriesSlice';
import MainCategoryBox from './MainCategoryBox';
import { useGetCategoriesQuery } from '../../utils/API/features/categoryApi';
import PropTypes from 'prop-types';
import CustomLoader from '../Shared/CustomLoader/CustomLoader';
const ShopMegaMenu = ({ shown, setMegaShown }) => {
	const location = useLocation();
	// const [isLoading, setIsLoading] = useState(false);
	// const dispatch = useDispatch();
	// const { categories, isLoading, error } = useSelector(
	//   (state) => state.category
	// );

	const {
		data: categories = [],
		isLoading,
		isFetching,
	} = useGetCategoriesQuery();

	// const categories = [
	// 	{
	// 		id: 1,
	// 		name: 'Sewage Pump',
	// 		children_data: [
	// 			{
	// 				id: 1,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 2,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 3,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 4,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 5,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 6,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 7,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 2,
	// 		name: 'Heating',
	// 		children_data: [
	// 			{
	// 				id: 1,
	// 				name: 'Sewage Pumps',
	// 			},
	// 			{
	// 				id: 2,
	// 				name: 'Sump Jet',
	// 			},
	// 			{
	// 				id: 3,
	// 				name: 'Sump Jet',
	// 			},
	// 			{
	// 				id: 4,
	// 				name: 'Sump Jet',
	// 			},
	// 			{
	// 				id: 5,
	// 				name: 'Sump Jet',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		id: 3,
	// 		name: 'Sewage Pump',
	// 		children_data: [
	// 			{
	// 				id: 1,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 2,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 3,
	// 				name: 'Sewage Pump',
	// 				children_data: [],
	// 			},
	// 			{
	// 				id: 4,
	// 				name: 'Sewage Pump',
	// 				children_data: [],
	// 			},
	// 			{
	// 				id: 5,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 6,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 			{
	// 				id: 7,
	// 				name: 'Sewage Pump',
	// 				children_data: [
	// 					{
	// 						id: 1,
	// 						name: 'Sewage Pumps',
	// 					},
	// 					{
	// 						id: 2,
	// 						name: 'Sump Jet',
	// 					},
	// 				],
	// 			},
	// 		],
	// 	},
	// ];

	useEffect(() => {
		setMegaShown(false);
	}, [location.pathname]);

	// useEffect(() => {
	//   if (categories.length === 0 && !error.status) {
	//     dispatch(getAllCategories());
	//   }
	// }, []);
	return (
		<div
			className={`shop-mega-menu ${shown ? 'shown' : ''}`}
			onMouseLeave={() => setMegaShown(false)}
		>
			<div className="top-link">
				{!(isLoading || isFetching) ? (
					categories.map((cat, i) => (
						<MainCategoryBox categoryData={cat} key={i} />
					))
				) : (
					<CustomLoader />
				)}
			</div>
			<div className="bottom-section">
				<div className="wrapping">
					<div className="text-wrappers-shop-mega">
						<span className="text">Expert Staff</span>
						<span className="text">Same-Day Services</span>
						<span className="text">
							35+ Years Expertise
						</span>
					</div>
					<Link to="shop" className="button">
						{' '}
						Shop All
					</Link>
				</div>
			</div>
		</div>
	);
};

ShopMegaMenu.propTypes = {
	shown: PropTypes.bool,
	setMegaShown: PropTypes.func,
};

export default ShopMegaMenu;
