import "./OrderTotalPaid.scss";
import PropTypes from "prop-types";

const OrderTotalPaid = ({
  userData,
  totalPrice,
  totalShippingDiscount,
  totalCartDiscount,
}) => {
  //TODO: Get the user data from the store,
  // Then Clear it from the store

  const maxPrice =
    totalPrice +
    (userData.DeliveryCarrier
      ? parseFloat(userData.DeliveryCarrier.price ?? 0) - totalShippingDiscount
      : 0) +
    (totalPrice * userData.tax ?? 0) -
    totalCartDiscount;

  return (
    <div className='order-total-paid-wrapper'>
      <div className='top-part'>
        {userData.deliveryOption === "shipping" ? (
          <>
            <div className='column-info'>
              <p>Shipping Address</p>
              <p className='desc'>{`${userData.address}, ${userData.city}, ${userData.province} ${userData.zipCode}, ${userData.country}`}</p>
            </div>
            {userData.isSameAsShipping ? (
              <div className='column-info'>
                <p>Billing Address</p>
                <p className='desc'>{`${userData.address}, ${userData.city}, ${userData.province} ${userData.zipCode}, ${userData.country}`}</p>
              </div>
            ) : (
              <div className='column-info'>
                <p>Billing Address</p>
                <p className='desc'>{`${userData.billingAddress}, ${userData.billingCity}, ${userData.billingProvince} ${userData.billingZipCode}, ${userData.billingCountry}`}</p>
              </div>
            )}
          </>
        ) : (
          <div className='column-info'>
            <p>Order will be Picked Up directly from store</p>
          </div>
        )}
      </div>
      <div className='bottom-part'>
        <div className='row'>
          <p>Subtotal</p>
          <p>${totalPrice?.toFixed(2) ?? 0}</p>
        </div>
        {userData.deliveryOption === "shipping" && (
          <>
            <div className='row'>
              <p>Shipping cost</p>
              <p>
                +$
                {Number(userData.DeliveryCarrier.price ?? 0).toFixed(2) ?? 0}
              </p>
            </div>
          </>
        )}
        <div className='row'>
          <p>Taxes</p>
          <p>
            +$
            {((userData.tax ?? 0) * totalPrice).toFixed(2) ?? 0}
          </p>
        </div>
        <div className='row'>
          <p>Discount</p>
          <p>
            -$
            {(totalShippingDiscount + totalCartDiscount)?.toFixed(2) ?? 0}
          </p>
        </div>
        <div className='total-price'>
          <p>Total Price</p>
          <p>${maxPrice?.toFixed(2) ?? 0}</p>
        </div>
      </div>
    </div>
  );
};

OrderTotalPaid.propTypes = {
  userData: PropTypes.object,
  totalPrice: PropTypes.number,
  totalShippingDiscount: PropTypes.number,
  totalCartDiscount: PropTypes.number,
};

export default OrderTotalPaid;
