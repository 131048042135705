import {
	FreeMode,
	Navigation,
	Pagination,
} from 'swiper/modules';
import SwiperNavButton from '../SwiperNavButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Container, useMediaQuery } from '@mui/material';
// import Product from '../Product';
import PropTypes from 'prop-types';
import NewProductHoverBox from '@/components/NewProductSwipper/NewProductHoverBox';

const FeaturedProducts = ({ products, heading }) => {
	const matches = useMediaQuery('(max-width:1200px)');
	const matches2 = useMediaQuery('(max-width:800px)');

	// TODO: Show Best Sellers

	return (
		<Container
			sx={{
				p: {
					lg: '50px 20px 80px 20px !important',
					xs: '40px 0 40px 0 !important',
				},
			}}
		>
			<div className="products-slider">
				<Swiper
					slidesPerView={'auto'}
					// {matches2 ? 1.5 : matches ? 3.5 : 4.5}
					spaceBetween={10}
					modules={[Navigation, FreeMode, Pagination]}
					navigation={true}
					className={`mySwiper`}
					freeMode={true}
					speed={800}
				>
					<div className="top-section">
						<div className="top-buttons">
							<div className="header">
								<p>Shop</p>
								<h1>{heading}</h1>
							</div>
							<div className="nav-buttons">
								<SwiperNavButton />
							</div>
						</div>
					</div>
					{products.map((product, index) => (
						<SwiperSlide key={index}>
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column',
									gap: '24px',
									width: '100%',
									marginTop: '20px',
								}}
							>
								<NewProductHoverBox
									data={product}
									key={index}
								/>
							</div>
						</SwiperSlide>
					))}
					{matches && (
						<div className="nav-buttons">
							<SwiperNavButton />
						</div>
					)}
				</Swiper>
			</div>
		</Container>
	);
};

FeaturedProducts.propTypes = {
	products: PropTypes.array,
	heading: PropTypes.string,
};

export default FeaturedProducts;
