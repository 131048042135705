import { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Layout.scss';
import { Drawer } from '@mui/material';
import CartDrawer from '../CartDrawer';
// import { cartProducts } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
	selectIsPopperVisible,
	setPopperVisibleAction,
} from '../../utils/features/cart';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
ReactGA.initialize('G-WJEWFJ08FS');

const Layout = ({
	children,
	// open,
	// handleClose,
	// handleOpen,
}) => {
	const { pathname, search } = useLocation();
	const dispatch = useDispatch();
	useEffect(() => {
		ReactGA.pageview(pathname + search);
	}, [pathname, search]);
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [pathname]);

	const open = useSelector(selectIsPopperVisible);
	const handleClose = () =>
		dispatch(setPopperVisibleAction(false));
	const handleOpen = () =>
		dispatch(setPopperVisibleAction(true));

	return (
		<div className="layout-css">
			<Header handleOpen={handleOpen} />
			<Drawer
				anchor="right"
				open={open}
				onClose={handleClose}
				sx={{ width: { xs: '80%', lg: '40%' } }}
			>
				<CartDrawer handleClose={handleClose} />
			</Drawer>
			{children}
			<Footer />
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	handleOpen: PropTypes.func.isRequired,
};

export default Layout;
