import React, { useEffect, useState } from 'react';
import './ServiceMenu.scss';
import { Link, useNavigate } from 'react-router-dom';
import { ServiceMenuData } from './servicesNew';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';

const ServiceMenu = ({
	serviceName,
	setServiceName,
	handleMobileLinkClick,
}) => {
	const [selectedService, setSeletedService] =
		useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (serviceName) {
			const foundService = ServiceMenuData.find(
				(serv) => serv.name === serviceName
			);
			if (foundService) {
				setSeletedService(foundService);
			} else {
				setSeletedService(ServiceMenuData[0]);
			}
		}
	}, [serviceName]);

	const handleClick = (link) => {
		setServiceName(null);
		navigate(link);
	};

	return (
		<>
			<div
				className={`serviceMenu ${
					serviceName ? 'shown' : ''
				}`}
				// onMouseLeave={() => setServiceName(null)}
			>
				<div className="serviceInnerwrapper">
					<div
						className={`leftBoxes ${
							selectedService?.CTA ? '' : 'full'
						}`}
					>
						{selectedService?.innerServices?.map((box) => (
							<div
								className="Servicebox"
								key={box?.id}
								onClick={() => handleClick(box?.link)}
							>
								<div className="serviceImgWrapper">
									<img src={box?.img} alt={box?.name} />
								</div>
								<p className="serviceName">{box?.name}</p>
							</div>
						))}
					</div>
					{selectedService?.CTA && (
						<div className="rightBanner">
							<img
								src={selectedService?.CTA?.img}
								alt="bannerImg"
							/>
							<div className="absoluteWrapper">
								<div className="top">
									<p className="title">
										{selectedService?.CTA?.title}
									</p>
									<p className="body">
										{selectedService?.CTA?.body}
									</p>
								</div>
								<Link
									to={selectedService?.CTA?.link}
									className="CTA-bttn"
								>
									{selectedService?.CTA?.bttnTxt}
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="service_wrapper-Mobile">
				<span
					className="serviceName"
					onClick={() => setServiceName(null)}
				>
					<FaAngleLeft className="icon" />{' '}
					{selectedService?.name}
				</span>
				<div className="serviceWrap">
					{selectedService?.innerServices.map((box) => (
						<div
							className="serviceBox"
							key={box?.id}
							onClick={() =>
								handleMobileLinkClick(box?.link)
							}
						>
							<div className="imgWrapper">
								<img src={box?.img} alt="box" />
							</div>
							<div className="infoBox">
								<p className="name">{box?.name}</p>
								{/* <span
                  className="linkBttn"
                >
                  View Services <FaAngleRight />
                </span> */}
							</div>
						</div>
					))}
					{selectedService?.CTA && (
						<div className="rightBanner">
							<img
								src={selectedService?.CTA?.imgMob}
								alt="bannerImg"
							/>
							<div className="absoluteWrapper">
								<div className="top">
									<p className="title">
										{selectedService?.CTA?.title}
									</p>
									<p className="body">
										{selectedService?.CTA?.body}
									</p>
								</div>
								<Link
									to={selectedService?.CTA?.link}
									className="CTA-bttn"
								>
									{selectedService?.CTA?.bttnTxt}
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default ServiceMenu;
