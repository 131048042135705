// For contactMarker
import LocationImage from '../../assets/Location.png';

export const contactMarker = [
  {
    id: 1,
    src: LocationImage,
    location: {
      lat: 44.96871,
      lng: -76.22606,
    },
    mWidth: 267,
    width: 413,
    mHeight: 52,
    height: 88,
  },
];

// For HomeMarker
import Location6Image from '../../assets/servinglocations/Location6.png';
import Location1Image from '../../assets/servinglocations/Location1.png';
import Location2Image from '../../assets/servinglocations/Location2.png';
import Location12Image from '../../assets/servinglocations/Location12.png';
import Location3Image from '../../assets/servinglocations/Location3.png';
import Location4Image from '../../assets/servinglocations/Location4.png';
import Location7Image from '../../assets/servinglocations/Location7.png';
import Location8Image from '../../assets/servinglocations/Location8.png';
import Location9Image from '../../assets/servinglocations/Location9.png';
import Location10Image from '../../assets/servinglocations/Location10.png';
import Location11Image from '../../assets/servinglocations/Location11.png';
import Location13Image from '../../assets/servinglocations/location13.png';
import ottowa from '../../assets/servinglocations/ottowa.png';
import perth from '../../assets/servinglocations/perth.png';
import kingstone from '../../assets/servinglocations/Kingstone.png';
import smith from '../../assets/SmithFallsClick.svg';
export const HomeMarker = [
  {
    id: 1,
    src: Location6Image,
    location: {
      lat: 45.13341,
      lng: -76.14938,
    },
    mWidth: 73,
    width: 141,
    mHeight: 34,
    height: 61,
  },
  {
    id: 2,
    src: Location1Image,
    location: {
      lat: 45.5333312,
      lng: -77.0999996,
    },
    mWidth: 73,
    width: 94,
    mHeight: 34,
    height: 65,
  },
  {
    id: 3,
    src: Location2Image,
    clickableImg: perth,
    link: '/perth',
    location: {
      lat: 44.898811,
      lng: -76.248558,
    },
    mWidth: 34,
    width: 65,
    mHeight: 34,
    height: 65,
  },
  {
    id: 4,
    src: Location12Image,
    location: {
      lat: 45.226314,
      lng: -76.194523,
    },
    mWidth: 46,
    width: 89,
    mHeight: 34,
    height: 65,
  },
  {
    id: 5,
    src: Location3Image,
    location: {
      lat: 45.093032,
      lng: -75.353841,
    },
    mWidth: 60,
    width: 116,
    mHeight: 32,
    height: 65,
  },
  {
    id: 6,
    src: Location4Image,
    clickableImg: smith,
    link: '/smithFalls',
    location: {
      lat: 44.903748,
      lng: -76.0216189,
    },
    mWidth: 62,
    width: 120,
    mHeight: 34,
    height: 60,
  },
  {
    id: 7,
    src: Location7Image,
    location: {
      lat: 45.275646,
      lng: -75.275414,
    },
    mWidth: 50,
    width: 115,
    mHeight: 34,
    height: 65,
  },
  {
    id: 8,
    src: Location8Image,
    location: {
      lat: 45.234,
      lng: -75.4718,
    },
    mWidth: 48,
    width: 92,
    mHeight: 34,
    height: 65,
  },
  {
    id: 9,
    src: Location9Image,
    clickableImg: ottowa,
    link: '/ottowa',
    location: {
      lat: 45.424721,
      lng: -75.695,
    },
    mWidth: 53,
    width: 103,
    mHeight: 34,
    height: 65,
  },
  {
    id: 10,
    src: Location10Image,
    location: {
      lat: 45.46681,
      lng: -76.68272,
    },
    mWidth: 46,
    width: 88,
    mHeight: 34,
    height: 65,
  },
  {
    id: 11,
    src: Location11Image,
    location: {
      lat: 45.1333328,
      lng: -75.7166638,
    },
    mWidth: 65,
    width: 125,
    mHeight: 34,
    height: 65,
  },
  {
    id: 12,
    src: Location13Image,
    clickableImg: kingstone,
    link: '/kingston',
    location: {
      lat: 44.233334,
      lng: -76.5,
    },
    mWidth: 62,
    width: 92,
    mHeight: 34,
    height: 60,
  },
];
