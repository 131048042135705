import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useMediaQuery } from '@mui/material';
// import location6 from '';
import './GoogleMap.scss';
import { HomeMarker, contactMarker } from './loactions';
import { useNavigate } from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '32px',
};

const mainCenter = {
  lat: 44.96871,
  lng: -76.22606,
};
const servingCenter = {
  lat: 45.13341,
  lng: -76.14938,
};

const GoogleMapComponent = ({ type, clickable }) => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState(servingCenter);
  const [initialZoom, setInitialZoom] = useState(9);
  const navigate = useNavigate();
  const mobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (type === 'contact') {
      setMarkers(contactMarker);
      setDefaultCenter(mainCenter);
      setInitialZoom(15);
    } else {
      setMarkers(HomeMarker);
      setDefaultCenter(servingCenter);
      setInitialZoom(9);
    }
  }, [type]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  });

  const onLoad = useCallback((map) => {
    setMap(map);
    map.setCenter(defaultCenter); // Set initial center directly
    map.setZoom(initialZoom); // Set initial zoom directly
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  const handleMarkerClick = (pin) => {
    if (clickable && pin?.link) {
      navigate(pin.link);
    }
  };

  return (
    <div className="map-container-google">
      <div
        className="wrapper"
        style={{
          height: type === 'contact' ? '407px' : clickable ? '1270px' : '600px',
          maxHeight: clickable ? '80vh' : 'auto',
        }}
      >
        {type !== 'contact' && (
          <div className="tooltip-map">
            <div className="rectangle"></div>
            <p>Serving Areas</p>
          </div>
        )}
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={defaultCenter}
            zoom={initialZoom}
            options={{
              fullscreenControl: false, // remove fullscreen control
              mapTypeId: 'satellite', // Set default map type to satellite
              mapTypeControl: false, // remove map type control
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {map &&
              markers?.map((marker) => (
                <Marker
                  key={marker.id}
                  position={marker.location}
                  aria-label="Location Marker"
                  icon={{
                    url:
                      clickable && marker?.clickableImg
                        ? marker.clickableImg
                        : marker.src,
                    scaledSize: new window.google.maps.Size(
                      mobile
                        ? clickable && marker?.clickableImg
                          ? 90
                          : marker.mWidth
                        : clickable && marker?.clickableImg
                        ? 147
                        : marker.width,
                      mobile
                        ? clickable && marker?.clickableImg
                          ? 63
                          : marker.mHeight
                        : clickable && marker?.clickableImg
                        ? 103
                        : marker.height
                    ),
                  }}
                  onClick={() => handleMarkerClick(marker)}
                />
              ))}
          </GoogleMap>
        )}
      </div>
    </div>
  );
};

export default GoogleMapComponent;
