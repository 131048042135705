import PropTypes from "prop-types";
import SubCategoryBox from "./SubCategoryBox";
// import categoryImg from '../../assets/categoryImg.png';
import { Link } from "react-router-dom";
import { fallbackImage } from "../../utils/constants";

const MainCategoryBox = ({ categoryData, index }) => {
  return (
    <div className='box'>
      <span className='cat-title'>{categoryData?.name}</span>
      <div className={`subcat-wrapper ${index === 1 ? "column" : ""}`}>
        {categoryData?.categorySubCategories?.length > 0 ? (
          categoryData?.categorySubCategories?.map((subCat) => (
            <SubCategoryBox
              subCat={subCat}
              key={subCat._id}
              mainCatId={categoryData._id}
            />
          ))
        ) : (
          <Link to={`/shop/${categoryData._id}`} className='subcat-box'>
            <div className='top-div'>
              <img
                src={categoryData.image ?? fallbackImage}
                alt='category-img'
              />
              <span className='subCat-name'>
                {/* <Link to={`/shop/${categoryData._id}`}> */}
                {categoryData.name}
                {/* </Link> */}
              </span>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

MainCategoryBox.propTypes = {
  categoryData: PropTypes.object,
  index: PropTypes.number,
};

export default MainCategoryBox;
