import PropTypes from 'prop-types';
import './ProductCartWithBackground.scss';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import {
	addProductToCartAction,
	selectSelectedProducts,
} from '../utils/features/cart';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ProductCartWithBackground = ({ data }) => {
	const backgroundImageStyle = {
		background: `linear-gradient(0deg, rgba(0, 60, 89, 0.40) 0%, rgba(0, 60, 89, 0.40) 100%), url(${data.backgroundImage}), lightgray 50%`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		flex: 1,
		// maxWidth: '50%',
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const addToCart = (product) =>
		dispatch(addProductToCartAction({ product }));

	const selectedProducts = useSelector(
		selectSelectedProducts
	);

	const isAddedToCart = selectedProducts.find(
		(p) =>
			p.product._id === data._id &&
			p.product.productVariantId === data.productVariantId
	);

	const isOutOfStock = data.inventoryCount === 0;

	return (
		<div
			className="product-cart"
			style={backgroundImageStyle}
		>
			<motion.div
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				viewport={{ once: true, amount: 0.8 }}
				transition={{
					ease: 'easeOut',
					duration: 0.4,
					delay: 0.4,
				}}
				className="left-side-text"
			>
				<h1>The best {data.category} product</h1>
				<p>Best Seller</p>
			</motion.div>
			<motion.div
				initial={{ opacity: 0, x: -100 }}
				whileInView={{ opacity: 1, x: 0 }}
				viewport={{ once: true, amount: 0.8 }}
				transition={{ ease: 'easeOut', duration: 0.4 }}
				className="product-cart-with-background"
			>
				{isOutOfStock && (
					<div className="out-of-stock">
						<div>Out of Stock</div>
					</div>
				)}
				<img
					src={data.productImage}
					alt="product"
					onClick={() => {
						navigate(`/product/${data?._id}`);
					}}
					style={{ cursor: 'pointer' }}
				/>
				{isAddedToCart ? (
					<div className="blue-button">Added to Cart</div>
				) : (
					<button
						className="blue-button"
						onClick={() =>
							data.variantsLength > 1
								? navigate(`/product/${data?._id}`)
								: addToCart(data)
						}
					>
						Add to Cart
					</button>
				)}
				{data.productDiscount > 0 && (
					<div className="orange-button">
						{data.productDiscount?.toFixed(2)}% OFF
					</div>
				)}
			</motion.div>
		</div>
	);
};

ProductCartWithBackground.propTypes = {
	data: PropTypes.shape({
		_id: PropTypes.string,
		backgroundImage: PropTypes.string,
		category: PropTypes.string,
		productImage: PropTypes.string,
		productDiscount: PropTypes.number,
		productVariantId: PropTypes.string,
		variantsLength: PropTypes.number,
		inventoryCount: PropTypes.number,
	}),
};

export default ProductCartWithBackground;
